import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx';
import { Theme } from '@mui/material/styles';

type BaseSxItem = boolean | SystemStyleObject<Theme> | ((theme: Theme) => SystemStyleObject<Theme>);

type SxItem = BaseSxItem | ReadonlyArray<BaseSxItem>;

export const mergeSx = (...sxList: ReadonlyArray<SxItem>): ReadonlyArray<BaseSxItem> => {
  return sxList.flatMap((sxItem) => sxItem);
};
