import { PageItemApi } from 'api/PageItemApi';
import { HeaderWidget } from 'model/HeaderWidget';
import { PageItemKind } from 'model/PageItemKind';

import { PageItemAbstractMapper } from './PageItemAbstractMapper';
import { PageItemKindMapper } from './PageItemMapper';

export class HeaderWidgetMapper
  implements PageItemKindMapper<HeaderWidget, PageItemApi.HeaderWidgetEntry> {
  deserialize(entry: PageItemApi.HeaderWidgetEntry): HeaderWidget {
    return new HeaderWidget({
      ...PageItemAbstractMapper.deserializeShape(entry),
    });
  }

  supportsDeserialize(entry: PageItemApi.ItemEntry): boolean {
    return entry.kind === PageItemKind.HeaderWidget;
  }
}
