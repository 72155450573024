import { connectRouter } from 'connected-react-router';

import { History } from 'history';
import { combineReducers } from 'reducer/combineReducers';
import { reduceReducers } from 'reducer/reduceReducers';
import { surveyReducer } from 'reducer/surveyReducer';
import { Reducer } from 'redux';
import { SurveyState } from 'store/SurveyState';

export const createRootReducer = (history: History): Reducer<SurveyState> => {
  return reduceReducers<SurveyState>(
    combineReducers<SurveyState>({ router: connectRouter(history) }),
    surveyReducer
  );
};
