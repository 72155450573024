import { HttpException } from './HttpException';

export class UnauthorizedException extends HttpException {
  static readonly API_CODE = 'UNAUTHORIZED';
  constructor(message: string, httpCode?: number) {
    super(message, httpCode);

    Object.setPrototypeOf(this, UnauthorizedException.prototype);
  }
}
