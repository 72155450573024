export namespace ModalState {
  export type Domain = {
    name: string;
    params: unknown;
  };
  export const INITIAL_DOMAIN: Domain = {
    name: undefined,
    params: undefined,
  };
}

export type ModalState = {
  modal?: ModalState.Domain;
};

export type ModalAwareState = ModalState & {
  [key: string]: unknown;
};
