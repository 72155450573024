import {
  IconButton as MuiIconButton,
  IconButtonTypeMap,
  styled,
  SxProps,
  Theme,
} from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { colors } from 'app/theme/config';
import { ThemeModeEnum } from 'shared/enums/ThemeModeEnum';

export const IconButton = styled<OverridableComponent<IconButtonTypeMap>>(MuiIconButton)(() => ({
  position: 'absolute',
  right: 8,
  top: 8,
}));

export const dialogHighContrastStyles: SxProps<Theme> = (theme) => {
  if (theme.themeName !== ThemeModeEnum.HIGH_CONTRAST_ACCESSIBLE) {
    return null;
  }

  return {
    '.MuiDialog-container > .MuiPaper-root': {
      background: colors.black,
      border: `3px solid ${colors.white}`,
    },

    'span.MuiIcon-root': {
      color: colors.candyCorn,
    },

    '.MuiDialogTitle-root': {
      borderWidth: '3px',
    },
  };
};
