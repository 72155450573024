import { SimpleModel } from 'model/SimpleModel';

export namespace StaticResource {
  export interface Shape {
    id?: number;
    file: string;
    fileOriginalName?: string;
    fileSize?: number;
    fileMimeType?: string;
  }
}

export class StaticResource extends SimpleModel<StaticResource.Shape> {
  getId(): number {
    return this.get('id');
  }

  getFile(): string {
    return this.get('file');
  }

  getFileOriginalName(): string {
    return this.get('fileOriginalName');
  }

  getFileSize(): number {
    return this.get('fileSize');
  }

  getFileMimeType(): string {
    return this.get('fileMimeType');
  }
}
