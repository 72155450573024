import { PageItemApi } from 'api/PageItemApi';
import { List } from 'immutable';
import { PageItemKind } from 'model/PageItemKind';
import { QuestionDemographic } from 'model/QuestionDemographic';

import { CustomQuestionOptionMapper } from './CustomQuestionOptionMapper';
import { PageItemKindMapper } from './PageItemMapper';
import { QuestionAbstractMapper } from './QuestionAbstractMapper';

export class QuestionDemographicMapper
  implements PageItemKindMapper<QuestionDemographic, PageItemApi.QuestionDemographicEntry> {
  private customQuestionOptionMapper: CustomQuestionOptionMapper;

  constructor(customQuestionOptionMapper: CustomQuestionOptionMapper) {
    this.customQuestionOptionMapper = customQuestionOptionMapper;
  }

  deserialize(entry: PageItemApi.QuestionDemographicEntry): QuestionDemographic {
    return new QuestionDemographic({
      ...QuestionAbstractMapper.deserializeShape(entry),
      options: List(
        (entry.options || []).map((option) => this.customQuestionOptionMapper.deserialize(option))
      ),
      isGhost: Boolean(entry.isGhost),
      isRequired: Boolean(entry.required),
      numberingSkipped: Boolean(entry.numberingSkipped),
    });
  }

  supportsDeserialize(entry: PageItemApi.ItemEntry): boolean {
    return entry.kind === PageItemKind.QuestionDemographic;
  }
}
