import { push } from 'connected-react-router';

import { ThunkAction } from 'redux-thunk';
import { compilePath, CompilePathParams } from 'router/compilePath';
import { RouteQueryResult } from 'shared/models/RouteQueryResult';

export const pushRoute = (
  pattern: string,
  params?: CompilePathParams,
  query?: RouteQueryResult
): ThunkAction<void, unknown, unknown> => (dispatch) =>
  dispatch(push(compilePath(pattern, params, query)));
