import { DistributionChannel } from 'model/DistributionChannel';
import { createSelector } from 'reselect';

import { selectDistributionSlug } from './navigationSelector';
import { selectSurvey } from './surveySelector';

export const selectDistribution = createSelector(
  selectDistributionSlug,
  selectSurvey,
  (distributionSlug, survey) =>
    survey &&
    survey
      .getDistributions()
      .find(
        (distribution) =>
          distribution.getSlug() === distributionSlug || distribution.getId() === distributionSlug
      )
);

export const selectIsEmailDistribution = createSelector(
  selectDistribution,
  (distribution) => distribution && distribution.getChannel() === DistributionChannel.Email
);

export const selectIsWebLinkDistribution = createSelector(
  selectDistribution,
  (distribution) => distribution && distribution.getChannel() === DistributionChannel.WebLink
);

export const selectIsKioskDistribution = createSelector(
  selectDistribution,
  (distribution) => distribution && distribution.getChannel() === DistributionChannel.Kiosk
);

export const selectIsPaperDistribution = createSelector(
  selectDistribution,
  (distribution) => distribution && distribution.getChannel() === DistributionChannel.Paper
);
