import { Iterable, Map, is } from 'immutable';

/* eslint-disable @typescript-eslint/no-explicit-any */
export namespace SimpleModel {
  export type Data<T> = T | Map<keyof T, any>;
  export type Mutator<T> = (data: Map<keyof T, any>) => any;
  export type Updater<T> = (data: T) => T;
}

export class SimpleModel<T extends { [key: string]: any }> {
  protected static readonly JSON_MEMOIZED = '@@__JSON_MEMOIZED__@@';

  private memoized: { [key: string]: unknown } = {};
  private data: Map<keyof T, unknown>;

  constructor(initialData: SimpleModel.Data<T>) {
    this.data = Map(initialData);
  }

  clone(): this {
    return new (Object.getPrototypeOf(this).constructor)(this.data);
  }

  update<K extends keyof T>(key: K, updater: (v: T[K]) => unknown): this {
    this.data = this.data.update(key, updater);
    return this.clone();
  }

  get<K extends keyof T>(key: K, notSetValue?: T[K]): T[K] {
    return this.data.get(key, notSetValue) as T[K];
  }

  set<K extends keyof T>(key: K, value: T[K]): this {
    this.data = this.data.set(key, value);
    return this.clone();
  }

  has<K extends keyof T>(key: K): boolean {
    return this.data.has(key);
  }

  remove<K extends keyof T>(key: K): this {
    this.data = this.data.remove(key);
    return this.clone();
  }

  withMutations(mutator: SimpleModel.Mutator<T>): this {
    this.data = this.data.withMutations(mutator);

    return this.clone();
  }

  equals(model: unknown): boolean {
    return model instanceof SimpleModel && is(this.data, model.data);
  }

  toJSON(): unknown {
    return this.memoize(SimpleModel.JSON_MEMOIZED, (data) =>
      Iterable.isKeyed(data) ? data.toObject() : data.toArray()
    );
  }

  protected memoize<M>(key: string, getter: (data: Map<keyof T, unknown>) => M): M {
    if (!this.memoized.hasOwnProperty(key)) {
      this.memoized[key] = getter(this.data);
    }

    return this.memoized[key] as M;
  }
}
