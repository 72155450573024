import React from 'react';
import { useTranslation } from 'react-i18next';

import { Hidden, Typography } from '@mui/material';
import { Container } from 'component/Container/Container';
import { HeaderPure } from 'component/Header/HeaderPure';
import { LangNamespaceEnum } from 'shared/enums/LangNamespaceEnum';
import { PageContainer } from 'component/PageContainer/PageContainer';

import { Root } from './NotFoundPage.s';

export const NotFoundPage: React.VFC = () => {
  const { t } = useTranslation(LangNamespaceEnum.SURVEY_UI_PAGES);

  return (
    <PageContainer
      headerElement={
        <>
          <Hidden lgDown>
            <HeaderPure shouldBePlacedAbsolutely />
          </Hidden>
          <Hidden lgUp>
            <Container>
              <HeaderPure shouldBePlacedAbsolutely />
            </Container>
          </Hidden>
        </>
      }
    >
      <Root data-testid="notFoundPage">
        <Typography variant="h3">{t('NotFoundPage.title')}</Typography>
        <Typography>{t('NotFoundPage.description')}</Typography>
      </Root>
    </PageContainer>
  );
};
