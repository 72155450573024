import { DistributionApi } from 'api/DistributionApi';
import { Distribution } from 'model/Distribution';

export interface DistributionMapperByChannel<
  M extends Distribution = Distribution,
  E extends DistributionApi.Entry = DistributionApi.Entry
> {
  deserialize(entry: E): M;
  supportsDeserialize(entry: E): boolean;
}

export class DistributionMapper
  implements DistributionMapperByChannel<Distribution, DistributionApi.EntryAny> {
  private mappers: DistributionMapperByChannel[];

  constructor(mappers: DistributionMapperByChannel[]) {
    this.mappers = mappers;
  }

  deserialize<T extends Distribution>(entry: DistributionApi.EntryAny): T {
    return this.findDeserializer(entry).deserialize(entry) as T;
  }

  supportsDeserialize(entry: DistributionApi.EntryAny): boolean {
    return this.mappers.some((mapper) => mapper.supportsDeserialize(entry));
  }

  private findDeserializer(entry: DistributionApi.EntryAny): DistributionMapperByChannel {
    const deserializer = this.mappers.find((mapper) => mapper.supportsDeserialize(entry));

    if (!deserializer) {
      throw new Error('Cannot find deserializer for distribution entry: ' + JSON.stringify(entry));
    }

    return deserializer;
  }
}
