import { FETCH_SUBMIT } from 'action/submitAction';
import { handleAction } from 'redux-actions';
import { SubmitState } from 'store/SubmitState';

import { combineReducers } from './combineReducers';
import { createResourceFetchReducer } from './resourceReducer';

export const fetchSubmitReducer = handleAction(
  FETCH_SUBMIT,
  createResourceFetchReducer(),
  SubmitState.INITIAL_SUBMIT_RESOURCE
);

export const submitReducer = combineReducers(
  {
    submitResource: fetchSubmitReducer,
  },
  SubmitState.INITIAL
);
