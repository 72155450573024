import { SimpleModel } from 'model/SimpleModel';

import { PageItem } from './PageItem';
import { PageItemKind } from './PageItemKind';

export namespace HeaderWidget {
  export type Shape = PageItem.Shape;
}

export class HeaderWidget extends PageItem<HeaderWidget.Shape> {
  constructor(initialData: SimpleModel.Data<HeaderWidget.Shape>) {
    super(initialData, PageItemKind.HeaderWidget);
  }
}
