import { Components, CSSInterpolation } from '@mui/material';
import { Property } from 'csstype';
import { Theme } from '@mui/material/styles';
import { answerToggleButtonClasses } from 'component/AnswerToggleButton/classes';
import { colors, focusBorderColor, inputBorderWidth } from 'app/theme/config';

export const answerToggleButtonBorderWidth = {
  light: inputBorderWidth,
  highContrastAccessible: inputBorderWidth,
};

const answerVariantStyle = ({
  hoverBgColor,
  borderLineColor,
}: {
  hoverBgColor: Property.BackgroundColor;
  borderLineColor: Property.BorderColor;
}): CSSInterpolation => ({
  backgroundColor: 'white',
  position: 'relative',
  [`& .${answerToggleButtonClasses.borderLine}`]: {
    backgroundColor: borderLineColor,
  },
  '&:hover': {
    backgroundColor: hoverBgColor,
  },
  [`&.${answerToggleButtonClasses.selected}`]: {
    backgroundColor: hoverBgColor,
    '&:hover': {
      backgroundColor: hoverBgColor,
    },
  },
});

const light: Components<Theme>['WBAnswerToggleButton'] = {
  styleOverrides: {
    option1: answerVariantStyle({
      hoverBgColor: '#EEF6E6',
      borderLineColor: '#64B209',
    }),
    option2: answerVariantStyle({
      hoverBgColor: '#F3F7E6',
      borderLineColor: '#8BB209',
    }),
    option3: answerVariantStyle({
      hoverBgColor: '#FFF8E5',
      borderLineColor: '#FFB100',
    }),
    option4: answerVariantStyle({
      hoverBgColor: '#FDEFE5',
      borderLineColor: '#E76202',
    }),
    option5: answerVariantStyle({
      hoverBgColor: '#FDE6E6',
      borderLineColor: '#DD0606',
    }),
    notApplicable: answerVariantStyle({
      hoverBgColor: '#ECECEC',
      borderLineColor: '#414141',
    }),
    containerBox: {
      borderColor: '#DBDBDB',
      borderWidth: answerToggleButtonBorderWidth.light,
    },
    focusVisibleLine: {
      borderColor: focusBorderColor.light,
    },
  },
};

const highContrastAccessible: Components<Theme>['WBAnswerToggleButton'] = {
  styleOverrides: {
    root: {
      color: colors.candyCorn,
      boxSizing: 'border-box',

      [`&.${answerToggleButtonClasses.selected}`]: {
        color: colors.malibu,

        '.WBAnswerToggleButton-borderLine': {
          background: colors.malibu,
        },
      },

      '&:hover': {
        '.WBAnswerToggleButton-borderLine': {
          background: colors.white,
        },
      },

      [`&.${answerToggleButtonClasses.selected} > .${answerToggleButtonClasses.focusVisibleLine}`]: {
        border: 'none',
      },
    },

    containerBox: {
      borderColor: colors.white,
      borderWidth: answerToggleButtonBorderWidth.highContrastAccessible,
    },

    focusVisibleLine: {
      borderColor: focusBorderColor.highContrastAccessible,
    },
  },
};

export const WBAnswerToggleButtonComp = {
  light,
  highContrastAccessible,
};
