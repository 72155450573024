import { AnswerApi } from 'api/AnswerApi';
import { AxiosInstance } from 'axios';
import { ProjectSubmitMapper } from 'mapper/ProjectSubmitMapper';
import { ProjectSubmit } from 'model/ProjectSubmit';
import { selectResponseData } from 'selector/axiosSelector';

export namespace SubmitApi {
  export type Entry = {
    id: number;
    status: number;
    submitTime: number;
    employee: EmployeeEntry;
    answers?: AnswerApi.Entry[];
  };

  export type EmployeeEntry = {
    email: string;
    firstName: string;
    lastName: string;
  };
}

export class SubmitApi {
  private client: AxiosInstance;
  private projectSubmitMapper: ProjectSubmitMapper;

  constructor(client: AxiosInstance, projectSubmitMapper: ProjectSubmitMapper) {
    this.client = client;
    this.projectSubmitMapper = projectSubmitMapper;
  }

  get(distributionSlug: string, accessCodeOrSubmitId: string): Promise<ProjectSubmit> {
    return this.client
      .get(`/api/v1/submit/${distributionSlug}/${accessCodeOrSubmitId}`)
      .then((response) => selectResponseData(response, 'data'))
      .then((entry) => this.projectSubmitMapper.deserialize(entry));
  }
}
