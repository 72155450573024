import { List } from 'immutable';
import { SimpleModel } from 'model/SimpleModel';

import { Category } from './Category';
import { DiscreteQuestion } from './DiscreteQuestion';
import { PageItemKind } from './PageItemKind';
import { Question } from './Question';
import { StaticQuestionOption } from './StaticQuestionOption';

export namespace QuestionOpinion {
  export interface Shape extends Question.Shape {
    category: Category;
    options: List<string>;
    isRequired?: boolean;
    reportedAs?: string;
    notApplicable?: string;
    hasNotApplicableOption: boolean;
  }
}

export namespace QuestionOpinionDefaults {
  export const strongAgree = 'Strongly Agree';
  export const agree = 'Agree';
  export const neutral = 'Neutral';
  export const disagree = 'Disagree';
  export const strongDisagree = 'Strongly Disagree';
  export const notApplicable = 'Not Applicable';
}

export class QuestionOpinion extends Question<QuestionOpinion.Shape> implements DiscreteQuestion {
  static indexToValue(index: number): number {
    return index + 1;
  }

  constructor(initialData: SimpleModel.Data<QuestionOpinion.Shape>) {
    super(initialData, PageItemKind.QuestionOpinion);
  }

  getCategory(): Category {
    return this.get('category');
  }

  getReportedAs(): string {
    return this.get('reportedAs');
  }

  getNotApplicable(): string {
    return this.get('notApplicable');
  }

  getHasNotApplicableOption(): boolean {
    return this.get('hasNotApplicableOption');
  }

  getOptions(): List<string> {
    return this.get('options');
  }

  isRequired(): boolean {
    return this.get('isRequired');
  }

  getAvailableOptions(): List<StaticQuestionOption> {
    return this.getOptions()
      .map(
        (option, index) =>
          new StaticQuestionOption({ option, value: QuestionOpinion.indexToValue(index) })
      )
      .toList();
  }
}
