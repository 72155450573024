import { styled } from '@mui/material';

export const whiteEmptyCheckboxSvgUrl =
  "url(\"data:image/svg+xml,%3Csvg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_427_377)'%3E%3Cpath d='M26.111,5.889V26.111H5.889V5.889H26.111m0-2.889H5.889A2.9,2.9,0,0,0,3,5.889V26.111A2.9,2.9,0,0,0,5.889,29H26.111A2.9,2.9,0,0,0,29,26.111V5.889A2.9,2.9,0,0,0,26.111,3Z' transform='translate(-3 -3)' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E%0A\")";
export const malibuCheckedCheckboxSvgUrl =
  "url(\"data:image/svg+xml,%3Csvg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_427_377)'%3E%3Cpath d='M26.111,3H5.889A2.888,2.888,0,0,0,3,5.889V26.111A2.888,2.888,0,0,0,5.889,29H26.111A2.888,2.888,0,0,0,29,26.111V5.889A2.888,2.888,0,0,0,26.111,3Zm-13,20.222L5.889,16l2.037-2.037,5.186,5.171L24.074,8.171l2.037,2.051Z' transform='translate(-3 -3)' fill='%236ee8fc'/%3E%3C/g%3E%3C/svg%3E%0A\")";
export const candyCornEmptyCheckboxSvgUrl =
  "url(\"data:image/svg+xml,%3Csvg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_427_377)'%3E%3Cpath d='M26.111,5.889V26.111H5.889V5.889H26.111m0-2.889H5.889A2.9,2.9,0,0,0,3,5.889V26.111A2.9,2.9,0,0,0,5.889,29H26.111A2.9,2.9,0,0,0,29,26.111V5.889A2.9,2.9,0,0,0,26.111,3Z' transform='translate(-3 -3)' fill='%23f7f751'/%3E%3C/g%3E%3C/svg%3E%0A\")";

export const CheckboxIcon = styled('span')(({ theme }) => ({
  backgroundImage:
    "url(\"data:image/svg+xml;charset=utf8,%3Csvg id='check_box_outline_blank_black_24dp' xmlns='http://www.w3.org/2000/svg' width='26' height='26' viewBox='0 0 26 26'%3E%3Cpath id='Path_15' data-name='Path 15' d='M26.111,5.889V26.111H5.889V5.889H26.111m0-2.889H5.889A2.9,2.9,0,0,0,3,5.889V26.111A2.9,2.9,0,0,0,5.889,29H26.111A2.9,2.9,0,0,0,29,26.111V5.889A2.9,2.9,0,0,0,26.111,3Z' transform='translate(-3 -3)' fill='%23414141'/%3E%3C/svg%3E\")",
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100%',
  height: 20,
  width: 20,
  [theme.breakpoints.up('sm')]: {
    height: 26,
    width: 26,
  },
}));

export const CheckboxCheckedIcon = styled(CheckboxIcon)({
  backgroundImage:
    "url(\"data:image/svg+xml;charset=utf8,%3Csvg id='check_box_black_24dp' xmlns='http://www.w3.org/2000/svg' width='26' height='26' viewBox='0 0 26 26'%3E%3Cpath id='Path_46' data-name='Path 46' d='M26.111,3H5.889A2.888,2.888,0,0,0,3,5.889V26.111A2.888,2.888,0,0,0,5.889,29H26.111A2.888,2.888,0,0,0,29,26.111V5.889A2.888,2.888,0,0,0,26.111,3Zm-13,20.222L5.889,16l2.037-2.037,5.186,5.171L24.074,8.171l2.037,2.051Z' transform='translate(-3 -3)' fill='%23fff'/%3E%3C/svg%3E\")",
});
