import React from 'react';

import { Container as MuiContainer, ContainerProps, ContainerTypeMap } from '@mui/material';

export const Container = <
  D extends React.ElementType = ContainerTypeMap['defaultComponent'],
  P = {}
>({
  children,
  ...props
}: ContainerProps<D, P>) => {
  return (
    <MuiContainer fixed maxWidth="md" {...props}>
      {children}
    </MuiContainer>
  );
};
