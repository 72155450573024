import { Resource } from 'model/Resource';
import { Selector, createSelector } from 'reselect';

export function createResourceContentSelector<S, C>(
  resourceSelector: Selector<S, Resource<C>>,
  notSetContent?: C
): Selector<S, C> {
  return createSelector(resourceSelector, (resource) =>
    resource ? resource.getContent(notSetContent) : notSetContent
  );
}
