import { HttpException } from './HttpException';

export class SurveyInDevelopmentException extends HttpException {
  static readonly API_CODE = 'PROJECT_IN_DEVELOPMENT';

  constructor(message: string, httpCode?: number) {
    super(message, httpCode);

    Object.setPrototypeOf(this, SurveyInDevelopmentException.prototype);
  }

  getApiCode(): string {
    return SurveyInDevelopmentException.API_CODE;
  }
}
