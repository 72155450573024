import { useHistory } from 'react-router';

import { useIsPreview } from 'shared/hooks/useIsPreview';
import { useDistributionSlug } from 'shared/hooks/useDistributionSlug';
import { compilePath } from 'router/compilePath';
import { SurveyRoute } from 'app/route';
import { useRouteQuery } from 'shared/hooks/useRouteQuery';

export const useSurveyNavigation = () => {
  const history = useHistory();

  const isPreview = useIsPreview();
  const distributionSlug = useDistributionSlug();
  const { accessCode, submitId, classifications, department } = useRouteQuery();
  const query = isPreview
    ? {
        classifications,
        department,
      }
    : { accessCode, submitId };

  const goToWelcomePage = () => {
    const welcomePageUrl = compilePath(
      isPreview ? SurveyRoute.PREVIEW_PATTERN : SurveyRoute.SURVEY_PATTERN,
      {
        distributionSlug,
      },
      query
    );
    history.push(welcomePageUrl);
  };

  const goToPageNumber = (page: number) => {
    const pageUrl = compilePath(
      isPreview ? SurveyRoute.Preview.PAGE_PATTERN : SurveyRoute.Survey.PAGE_PATTERN,
      {
        distributionSlug,
        page: page,
      },
      query
    );
    history.push(pageUrl);
  };

  const goToSubmitPage = () => {
    const submitPageUrl = compilePath(
      isPreview ? SurveyRoute.Preview.SUBMIT_PATTERN : SurveyRoute.Survey.SUBMIT_PATTERN,
      {
        distributionSlug,
      },
      query
    );
    history.push(submitPageUrl);
  };

  const goToSuccessPage = () => {
    const submitPageUrl = compilePath(
      isPreview ? SurveyRoute.Preview.SUCCESS_PATTERN : SurveyRoute.Survey.SUCCESS_PATTERN,
      {
        distributionSlug,
      },
      query
    );
    history.push(submitPageUrl);
  };

  const goToSaveSuccessPage = () => {
    const submitPageUrl = compilePath(
      isPreview
        ? SurveyRoute.Preview.SAVE_SUCCESS_PATTERN
        : SurveyRoute.Survey.SAVE_SUCCESS_PATTERN,
      {
        distributionSlug,
      },
      query
    );
    history.push(submitPageUrl);
  };

  return {
    goToWelcomePage,
    goToPageNumber,
    goToSubmitPage,
    goToSuccessPage,
    goToSaveSuccessPage,
  };
};
