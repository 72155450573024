import React from 'react';
import { useSelector } from 'react-redux';

import { selectError } from 'selector/surveySelector';
import { AccessDeniedException } from 'exception/AccessDeniedException';
import { SurveyClosedException } from 'exception/SurveyClosedException';
import { SurveyInDevelopmentException } from 'exception/SurveyInDevelopmentException';
import { SurveyAlreadyCompletedException } from 'exception/SurveyAlreadyCompletedException';
import { NotFoundPage } from 'view/NotFoundPage/NotFoundPage';
import { UnauthorizedException } from 'exception/UnauthorizedException';
import { UnauthorizedPage } from 'view/UnauthorizedPage/UnauthorizedPage';
import { CannotLoadSurveyPage } from 'view/CannotLoadSurveyPage/CannotLoadSurveyPage';
import { selectMeta } from 'selector/metaSelector';

type ApiErrorFallbackPageProps = {
  children?: React.ReactNode;
  /**
   * React.ReactNode - override existed default component with returned component,
   * false - override existed default component with children,
   * undefined - render default component
   */
  renderOwnErrorComponent?: (apiErrorCode: string) => React.ReactNode | false | undefined;
};

export const ApiErrorFallbackPage: React.VFC<ApiErrorFallbackPageProps> = ({
  children,
  renderOwnErrorComponent,
}) => {
  const error = useSelector(selectError) as { code: string; message: string } | undefined;
  const meta = useSelector(selectMeta);
  const companyLogo = meta?.getCompanyLogo();
  const companyName = meta?.getCompanyName();

  if (!error?.code) {
    return children ? <>{children}</> : <NotFoundPage />;
  }

  if (renderOwnErrorComponent) {
    const ExpectedErrorComponent = renderOwnErrorComponent(error.code);
    if (ExpectedErrorComponent !== false && ExpectedErrorComponent !== undefined) {
      return <>{ExpectedErrorComponent}</>;
    } else if (ExpectedErrorComponent === false) {
      return children ? <>{children}</> : <NotFoundPage />;
    }
  }

  switch (error.code) {
    case UnauthorizedException.API_CODE:
      return <UnauthorizedPage companyLogoUrl={companyLogo} companyName={companyName} />;
    // TODO [START]?? Remove CLOSED_PATTERN routing and redirection in surveyAction and allow this component to handle this exception
    case SurveyClosedException.API_CODE:
    case SurveyInDevelopmentException.API_CODE:
    case SurveyAlreadyCompletedException.API_CODE:
    // TODO [END]
    //  return <SurveyClosedPage />
    case AccessDeniedException.API_CODE:
    default:
      return <CannotLoadSurveyPage />;
  }
};
