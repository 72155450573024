import React, { ErrorInfo } from 'react';

import { i18n } from 'translations/i18n';
import { Link, Typography } from '@mui/material';
import { LangNamespaceEnum } from 'shared/enums/LangNamespaceEnum';
import { LangCodeEnum } from 'shared/enums/LangCodeEnum';

type ErrorBoundaryPageState =
  | { hasError: true; error: Error; info: ErrorInfo }
  | {
      hasError: false;
      error?: unknown;
      info?: unknown;
    };

export class ErrorBoundary extends React.Component<{}, ErrorBoundaryPageState> {
  constructor(props: {}) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    console.error(this.state.error, this.state.info);

    this.setState({
      hasError: true,
      error: error,
      info: info,
    });
  }

  render() {
    if (this.state.hasError) {
      let lng = LangCodeEnum.ENGLISH_BRITISH;
      const ns = LangNamespaceEnum.SURVEY_UI_COMPONENTS;
      const preferredLanguage = sessionStorage.getItem('preferredLanguage');

      if (preferredLanguage) {
        try {
          lng = JSON.parse(preferredLanguage);
        } catch (error) {
          console.warn('Failed to parse preferred language from sessionStorage:', error);
        }
      }

      return (
        <>
          <Typography variant="h5">{i18n.t('ErrorBoundary.title', { lng, ns })}</Typography>
          <Typography variant="h5" mb={3}>
            {i18n.t('ErrorBoundary.description', { lng, ns })}
            <Link href="mailto:support@workbuzz.com" variant="h5" target="_blank">
              support@workbuzz.com
            </Link>
          </Typography>
          <Typography variant="body1" mb={3}>
            <b>The error:</b> {this.state.error.toString()}
          </Typography>
          <Typography variant="body1">
            <b>Where it occurred:</b> {this.state.info.componentStack}
          </Typography>
        </>
      );
    }

    return this.props.children;
  }
}
