import {
  CLOSE_MODAL,
  OPEN_MODAL,
  OpenModalAction,
  UPDATE_MODAL,
  UpdateModalAction,
} from 'action/modalAction';
import { combineReducers } from 'reducer/combineReducers';
import { reduceReducers } from 'reducer/reduceReducers';
import { Reducer } from 'redux';
import { handleAction } from 'redux-actions';
import { ModalAwareState, ModalState } from 'store/ModalState';

export const openModalReducer = handleAction(
  OPEN_MODAL,
  (modal: ModalState.Domain, action: OpenModalAction) => ({
    name: action.payload.name,
    params: action.payload.params,
  }),
  ModalState.INITIAL_DOMAIN
);

export const updateModalReducer = handleAction(
  UPDATE_MODAL,
  (modal: ModalState.Domain, action: UpdateModalAction) => ({
    name: modal.name,
    params: action.payload,
  }),
  ModalState.INITIAL_DOMAIN
);

export const closeModalReducer = handleAction(
  CLOSE_MODAL,
  () => ({
    name: null,
    params: null,
  }),
  ModalState.INITIAL_DOMAIN
);

export const modalReducer: Reducer<ModalAwareState> = combineReducers<ModalAwareState>({
  modal: reduceReducers(openModalReducer, updateModalReducer, closeModalReducer),
});
