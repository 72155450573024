import { List } from 'immutable';

import { CustomQuestionOption } from './CustomQuestionOption';
import { NumberingSkippable } from './NumberingSkippable';
import { PageItemKind } from './PageItemKind';
import { Question } from './Question';

export namespace QuestionForm {
  export interface Shape extends Question.Shape {
    options: List<CustomQuestionOption>;
    optionWidth: number;
    optionHeight: number;
    numberingSkipped: boolean;
  }
}

export class QuestionForm extends Question<QuestionForm.Shape> implements NumberingSkippable {
  constructor(initialData: QuestionForm.Shape) {
    super(initialData, PageItemKind.QuestionForm);
  }

  getOptions(): List<CustomQuestionOption> {
    return this.get('options');
  }

  getOptionWidth(): number {
    return this.get('optionWidth');
  }

  getOptionHeight(): number {
    return this.get('optionHeight');
  }

  isNumberingSkipped(): boolean {
    return this.get('numberingSkipped');
  }
}
