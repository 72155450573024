import { getLanguageDirection } from 'shared/utils/getLanguageDirection';
import { useLanguage } from 'translations/LanguageProvider';

export type ArrowDirection = 'next' | 'previous';

export const useArrowIcon = (arrowDirection: ArrowDirection) => {
  const { language } = useLanguage();
  const languageDirection = getLanguageDirection(language);

  if (arrowDirection === 'next') {
    return languageDirection === 'ltr' ? 'east' : 'west';
  }
  return languageDirection === 'ltr' ? 'west' : 'east';
};
