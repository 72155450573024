import { Meta } from 'model/Meta';
import { MetaApi } from 'api/MetaApi';

export class MetaMapper {
  deserialize(entry: MetaApi.Entry): Meta {
    return new Meta({
      almostFinishedContent: entry.almostFinishedContent,
      automaticNumbering: !!entry.automaticQuestionNumbering,
      closedPageContent: entry.closedPageContent,
      companyBrandColor: entry.companyBrandColor,
      companyLogo: entry.companyLogo,
      companyName: entry.companyName,
      enabledLocales: entry.enabledLocales,
      isNewSurveyUIEnabled: entry.isNewSurveyUIEnabled,
      multiLanguageEnabled: entry.multiLanguageEnabled,
      preventIndexing: !!entry.preventIndexing,
      progressBar: !!entry.progressBar,
      projectId: entry.projectId,
      projectKind: entry.projectKind,
      projectName: entry.projectName,
      saveAndContinue: !!entry.saveAndContinue,
      saveAndContinueContent: entry.saveAndContinueContent,
      surveyCodeQuestionContent: entry.surveyCodeQuestionContent,
      thankYouPageContent: entry.thankYouPageContent,
      surveyLogoUrl: entry.surveyLogoUrl,
    });
  }
}
