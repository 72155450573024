// members fetch
import { Action, ActionMeta } from 'redux-actions';
import { ThunkAction } from 'redux-thunk';

export interface AsyncAction<T> extends Action<T> {
  pending?: boolean;
  resolved?: boolean;
}

export interface AsyncActionMeta<T, M> extends AsyncAction<T>, ActionMeta<T, M> {}

export interface AsyncActionCreator<T> {
  pending: (optimisticPayload?: T) => AsyncAction<T>;
  resolved: (payload?: T) => AsyncAction<T>;
  rejected: (payload?: unknown) => AsyncAction<unknown>;
}

export interface AsyncActionCreatorMeta<T, M> extends AsyncActionCreator<T> {
  pending: (optimisticPayload?: T, meta?: M) => AsyncActionMeta<T, M>;
  resolved: (payload?: T, meta?: M) => AsyncActionMeta<T, M>;
  rejected: (payload?: unknown, meta?: M) => AsyncActionMeta<unknown, M>;
}

export function createAsyncActionCreator<P = unknown>(
  type: string
): AsyncActionCreatorMeta<P, unknown> {
  return {
    pending: (optimisticPayload?, meta = {}) => ({
      type,
      payload: optimisticPayload,
      meta,
      pending: true,
    }),
    resolved: (payload?, meta = {}) => ({ type, payload, meta, resolved: true }),
    // error: true instead of rejected: true because of compatibility with FSA
    rejected: (payload?, meta = {}) => ({ type, payload, meta, error: true }),
  };
}

export function isActionPending(action: AsyncAction<unknown>): boolean {
  return action.pending;
}

export function isActionRejected(action: AsyncAction<unknown>): boolean {
  return action.error;
}

export function isActionResolved(action: AsyncAction<unknown>): boolean {
  return action.resolved;
}

export function isActionFulfilled(action: AsyncAction<unknown>): boolean {
  return isActionResolved(action) && action.payload !== undefined && action.payload !== null;
}

export function async<T>(
  type: string,
  promise: Promise<T>,
  meta?: unknown,
  optimistic?: T
): ThunkAction<Promise<T>, unknown, unknown> {
  const actions = createAsyncActionCreator(type);

  return (dispatch) => {
    dispatch(actions.pending(optimistic, meta));

    return promise
      .then((resolved) => {
        dispatch(actions.resolved(resolved, meta));
        return resolved;
      })
      .catch((error) => {
        dispatch(actions.rejected(error, meta));
        throw error;
      });
  };
}
