import { SubmitApi } from 'api/SubmitApi';
import { List } from 'immutable';
import { AnswerMapper } from 'mapper/AnswerMapper';
import { ProjectSubmit } from 'model/ProjectSubmit';

export class ProjectSubmitMapper {
  private answerMapper: AnswerMapper;

  constructor(answerMapper: AnswerMapper) {
    this.answerMapper = answerMapper;
  }

  deserialize(entry: SubmitApi.Entry): ProjectSubmit {
    return new ProjectSubmit({
      id: entry.id,
      status: entry.status,
      answers: List(
        (entry.answers || []).map((answerEntry) => this.answerMapper.deserialize(answerEntry))
      ),
    });
  }
}
