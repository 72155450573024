import { AxiosError, AxiosResponse } from 'axios';
import { get } from 'lodash-es';
import { Selector } from 'reselect';

/**
 * Select response code, for example 201
 */
export const selectResponseCode: Selector<AxiosResponse, number> = (response) =>
  response && response.status;

/**
 * Select response code group like 200, 300, 400 or 500
 */
export const selectResponseCodeGroup = (response: AxiosResponse) =>
  Math.round(selectResponseCode(response) / 100) * 100;

export const selectResponseData = (
  response: AxiosResponse,
  field?: string,
  notSetValue?: unknown
) => {
  const data = response && response.data;

  if (field) {
    return get(data || {}, field, notSetValue);
  } else {
    return data;
  }
};

export const selectErrorResponse: Selector<AxiosError, AxiosResponse> = (error) =>
  error && error.response;

/**
 * Select error code, for example 403
 */
export const selectErrorCode = (error: AxiosError) =>
  selectResponseCode(selectErrorResponse(error));

/**
 * Select error code group like 400 or 500
 */

export const selectErrorData = (error: AxiosError, field?: string, notSetValue?: unknown) =>
  selectResponseData(selectErrorResponse(error), field, notSetValue);

export const throwErrorData = (error: AxiosError, field?: string, notSetValue?: unknown): never => {
  throw selectErrorData(error, field, notSetValue);
};
