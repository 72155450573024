import {
  selectAccessCode,
  selectDistributionSlug,
  selectSubmitId,
} from 'selector/navigationSelector';
import { SurveyThunk } from 'store/SurveyThunk';

import { createAsyncActionCreator } from './asyncAction';

export const FETCH_SUBMIT = 'Survey/FETCH_SUBMIT';
export const fetchSubmitAsync = createAsyncActionCreator(FETCH_SUBMIT);

export const fetchSubmit = (): SurveyThunk<Promise<unknown>> => (dispatch, getState, container) => {
  const submitId = selectSubmitId(getState());
  const accessCode = selectAccessCode(getState());
  const distributionSlug = selectDistributionSlug(getState());

  dispatch(fetchSubmitAsync.pending());
  return container.submitApi
    .get(distributionSlug, submitId || accessCode)
    .then((result) => dispatch(fetchSubmitAsync.resolved(result)))
    .catch((error) => dispatch(fetchSubmitAsync.rejected(error)));
};
