import React, { useRef } from 'react';

import { Icon, InputAdornment } from '@mui/material';

type ValidationAdornmentIconProps = {
  error?: boolean;
  disabled?: boolean;
  isDirty?: boolean;
};

export const ValidationAdornmentIcon: React.VFC<ValidationAdornmentIconProps> = ({
  error,
  isDirty,
  disabled,
}) => {
  const hasPreviouslyErrorRef = useRef(false);

  if (error) {
    hasPreviouslyErrorRef.current = true;
  } else if (!isDirty) {
    hasPreviouslyErrorRef.current = false;
  }

  let IconElement = <></>;
  if (hasPreviouslyErrorRef.current && !error && isDirty) {
    IconElement = (
      <Icon
        color="success"
        data-testid="validationSuccessIcon"
        sx={{ opacity: disabled ? 0.5 : undefined }}
      >
        check_circle
      </Icon>
    );
  } else if (error) {
    IconElement = (
      <Icon
        color="error"
        data-testid="validationErrorIcon"
        sx={{ opacity: disabled ? 0.5 : undefined }}
      >
        error
      </Icon>
    );
  }

  return <InputAdornment position="end">{IconElement}</InputAdornment>;
};
