import { match } from 'react-router';

import { SurveyRoute } from 'app/route';
import { createSelector } from 'reselect';

import { selectHasMatch, selectMatch, selectQueryParamForMatch } from './routerSelector';

export const selectDistributionSlug = createSelector(
  selectMatch(SurveyRoute.SURVEY_PATTERN),
  (match: match<{ distributionSlug: string }>) => match && match.params.distributionSlug
);

export const selectAccessCode = selectQueryParamForMatch<string>(
  'accessCode',
  SurveyRoute.SURVEY_PATTERN
);

export const selectKioskCode = selectQueryParamForMatch<string>(
  'kioskCode',
  SurveyRoute.Survey.KIOSK_PATTERN
);

export const selectSubmitId = selectQueryParamForMatch<string>(
  'submitId',
  SurveyRoute.SURVEY_PATTERN
);

export const selectIsPreview = selectHasMatch(SurveyRoute.PREVIEW_PATTERN);

export const selectIsSurveyFormSubmitPage = selectHasMatch(
  SurveyRoute.Preview.SUBMIT_PATTERN,
  SurveyRoute.Survey.SUBMIT_PATTERN
);
