import { PageItemApi } from 'api/PageItemApi';
import { Question } from 'model/Question';

import { PageItemAbstractMapper } from './PageItemAbstractMapper';

export abstract class QuestionAbstractMapper {
  static deserializeShape(entry: PageItemApi.QuestionEntry): Question.Shape {
    // we can't deserialize question model, because it's an abstract model
    return {
      ...PageItemAbstractMapper.deserializeShape(entry),
      content: entry.content,
    };
  }
}
