import React from 'react';

import { Box, styled } from '@mui/material';
import { colors } from 'app/theme/config';
import { ThemeModeEnum } from 'shared/enums/ThemeModeEnum';

const IconWrapper = styled(Box)(({ theme }) => ({
  width: '60px',
  height: '60px',
  borderRadius: '50%',
  backgroundColor:
    theme.themeName === ThemeModeEnum.HIGH_CONTRAST_ACCESSIBLE ? colors.white : colors.boulder,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: '20px',
  '& > svg': {
    height: '27.5px',
    width: '22.53px',
    fill: theme.themeName === ThemeModeEnum.HIGH_CONTRAST_ACCESSIBLE ? colors.black : colors.white,
  },
  [theme.breakpoints.up('sm')]: {
    width: '70px',
    height: '70px',
    '& > svg': {
      height: '31.5px',
      width: '24px',
    },
  },
}));

export const UnauthorizedIcon = () => {
  return (
    <IconWrapper>
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="42" viewBox="8 2 32 42">
        <path d="M11 44q-1.25 0-2.125-.875T8 41V19.3q0-1.25.875-2.125T11 16.3h3.5v-4.8q0-3.95 2.775-6.725Q20.05 2 24 2q3.95 0 6.725 2.775Q33.5 7.55 33.5 11.5v4.8H37q1.25 0 2.125.875T40 19.3V41q0 1.25-.875 2.125T37 44zm13-10q1.6 0 2.725-1.1t1.125-2.65q0-1.5-1.125-2.725T24 26.3q-1.6 0-2.725 1.225T20.15 30.25q0 1.55 1.125 2.65Q22.4 34 24 34zm-6.5-17.7h13v-4.8q0-2.7-1.9-4.6Q26.7 5 24 5q-2.7 0-4.6 1.9-1.9 1.9-1.9 4.6z"></path>
      </svg>
    </IconWrapper>
  );
};
