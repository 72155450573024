import { List } from 'immutable';
import { ProjectKind } from 'model/ProjectKind';
import { SimpleModel } from 'model/SimpleModel';
import { Moment } from 'moment';
import { LangCodeEnum } from 'shared/enums/LangCodeEnum';

import { Category } from './Category';
import { Company } from './Company';
import { Distribution } from './Distribution';
import { Page } from './Page';
import { ProjectLayout } from './ProjectLayout';
import { ProjectSortBy } from './ProjectSortBy';
import { ProjectStatus } from './ProjectStatus';

export namespace Project {
  export interface Shape {
    id?: number;
    name?: string;
    categories?: List<Category>;
    status?: ProjectStatus;
    kind?: ProjectKind;
    company?: Company;
    liveStart?: Moment;
    liveStop?: Moment;
    pages?: List<Page>;
    layout?: ProjectLayout;
    distributions?: List<Distribution>;
    preferredLanguage?: LangCodeEnum | null;
  }
}

export class Project extends SimpleModel<Project.Shape> {
  static readonly STATUSES: ProjectStatus[] = [
    ProjectStatus.InDevelopment,
    ProjectStatus.Live,
    ProjectStatus.Closed,
    ProjectStatus.Deleted,
  ];

  static readonly SORT_BYS: ProjectSortBy[] = [ProjectSortBy.LastModified, ProjectSortBy.LiveDates];

  getId(): number {
    return this.get('id');
  }

  getName(): string {
    return this.get('name');
  }

  getCategories(): List<Category> {
    return this.get('categories', List<Category>());
  }

  getStatus(): ProjectStatus {
    return this.get('status', ProjectStatus.InDevelopment);
  }

  getCompany(): Company {
    return this.get('company');
  }

  hasCompany(): boolean {
    return this.has('company');
  }

  setStatus(status: ProjectStatus): this {
    return this.set('status', status);
  }

  getKind(): ProjectKind {
    return this.get('kind');
  }

  isInDevelopment(): boolean {
    return this.getStatus() === ProjectStatus.InDevelopment;
  }

  isLive(): boolean {
    return this.getStatus() === ProjectStatus.Live;
  }

  isClosed(): boolean {
    return this.getStatus() === ProjectStatus.Closed;
  }

  isDeleted(): boolean {
    return this.getStatus() === ProjectStatus.Deleted;
  }

  isEditable(): boolean {
    return this.isInDevelopment();
  }

  /**
   * We can only fix some properties, not fully edit.
   * @returns {boolean}
   */
  isFixableOnly(): boolean {
    return this.isLive() || this.isClosed();
  }

  isCloseable(): boolean {
    return this.isInDevelopment() || this.isLive();
  }

  isDeletable(): boolean {
    return this.isInDevelopment() || this.isClosed();
  }

  getLiveStart(): Moment {
    return this.get('liveStart');
  }

  getLiveStop(): Moment {
    return this.get('liveStop');
  }

  getPages(): List<Page> {
    return this.get('pages') || List<Page>();
  }

  getLayout(): ProjectLayout {
    return this.get('layout');
  }

  getDistributions(): List<Distribution> {
    return this.get('distributions');
  }

  getPreferredLanguage() {
    return this.get('preferredLanguage');
  }
}
