import { SwipeableDrawer, styled, SxProps, Theme } from '@mui/material';
import { colors } from 'app/theme/config';
import { ThemeModeEnum } from 'shared/enums/ThemeModeEnum';

const highContrastDrawerStyles = {
  background: colors.black,
  borderLeft: `3px solid ${colors.white}`,
};

const highContrastDrawerHeaderStyles = {
  borderColor: colors.white,

  '.MuiIconButton-root': {
    color: colors.candyCorn,
  },
};

export const LanguageDrawStyled = styled(SwipeableDrawer)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: 250,
    ...(theme.themeName === ThemeModeEnum.HIGH_CONTRAST_ACCESSIBLE ? highContrastDrawerStyles : {}),
  },
}));

export const drawerHeaderSx: SxProps<Theme> = (theme) => ({
  borderBottom: `1px solid ${colors.alto}`,
  padding: theme.spacing(1, 3),
  marginBottom: theme.spacing(1),
  display: 'flex',
  justifyContent: 'space-between',

  ...(theme.themeName === ThemeModeEnum.HIGH_CONTRAST_ACCESSIBLE
    ? highContrastDrawerHeaderStyles
    : {}),
});
