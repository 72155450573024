export interface AppConfig {
  NODE_ENV: string;
  ADMIN_API_CLIENT_URL: string;
  RECAPTCHA_SITE_KEY: string;
  SURVEY_UI_GTM_KEY: string;
  GROWTHBOOK_API_KEY: string;
  STORYBOOK?: string;
  DATADOG_SURVEY_UI_CLIENT_TOKEN: string;
  DATADOG_SURVEY_UI_RUM_APPLICATION_ID: string;
  DATADOG_SURVEY_UI_RUM_DEFAULT_PRIVACY_LEVEL: string;
  DATADOG_SURVEY_UI_RUM_SESSION_REPLAY_SAMPLE_RATE: string;
  DATADOG_SURVEY_UI_RUM_SESSION_SAMPLE_RATE: string;
}

/**
 * Get app config. During development is uses values from env variables.
 * On prod it return global config object
 *
 * This enables single Docker image build to be run with different configs.
 */
export const getAppConfig = (): AppConfig => {
  /**
   * In development use env variables
   */
  if (process.env.NODE_ENV === 'development') {
    return {
      NODE_ENV: process.env.NODE_ENV,
      ADMIN_API_CLIENT_URL: process.env.ADMIN_API_CLIENT_URL,
      RECAPTCHA_SITE_KEY: process.env.RECAPTCHA_SITE_KEY,
      SURVEY_UI_GTM_KEY: process.env.SURVEY_UI_GTM_KEY,
      GROWTHBOOK_API_KEY: process.env.GROWTHBOOK_API_KEY,
      STORYBOOK: process.env.STORYBOOK,
      DATADOG_SURVEY_UI_CLIENT_TOKEN: process.env.DATADOG_SURVEY_UI_CLIENT_TOKEN,
      DATADOG_SURVEY_UI_RUM_APPLICATION_ID: process.env.DATADOG_SURVEY_UI_RUM_APPLICATION_ID,
      DATADOG_SURVEY_UI_RUM_DEFAULT_PRIVACY_LEVEL:
        process.env.DATADOG_SURVEY_UI_RUM_DEFAULT_PRIVACY_LEVEL,
      DATADOG_SURVEY_UI_RUM_SESSION_REPLAY_SAMPLE_RATE:
        process.env.DATADOG_SURVEY_UI_RUM_SESSION_REPLAY_SAMPLE_RATE,
      DATADOG_SURVEY_UI_RUM_SESSION_SAMPLE_RATE:
        process.env.DATADOG_SURVEY_UI_RUM_SESSION_SAMPLE_RATE,
    };
  }

  if (!window || !window.AppConfig) {
    throw new Error('APP CONFIG MISSING!');
  }

  const appConfigShallowCopy = {
    ...window.AppConfig,
  };

  return (appConfigShallowCopy as unknown) as AppConfig;
};
