import axios, { AxiosInstance } from 'axios';
import { UNAUTHORIZED } from 'http-status-codes';
import { selectApiError, selectAdminApiUrl } from 'selector/apiSelector';
import { selectErrorCode } from 'selector/axiosSelector';
import { i18n } from 'translations/i18n';
import { logInterceptedError } from 'shared/hooks/useInitializeDataDogLogs';

const backendApiClient: AxiosInstance = axios.create({
  baseURL: selectAdminApiUrl(),
  withCredentials: true,
  // `headers` are custom headers to be sent
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

backendApiClient.interceptors.request.use(
  (config) => {
    // ! TODO: WB3-695 - We need to send en-GB for old design
    config.headers['Accept-Language'] = i18n.language?.replace(/_/g, '-') ?? 'en-GB';
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// redirect to login page on JWT_INVALIDATED code
backendApiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    logInterceptedError(error);

    if (selectErrorCode(error) !== UNAUTHORIZED) {
      throw error;
    }

    const apiError = selectApiError(error);

    if (apiError && apiError.code === 'JWT_INVALIDATED') {
      window.location.href = '/login';
    } else {
      throw error;
    }
  }
);

export { backendApiClient };
