import React from 'react';
import { useTranslation } from 'react-i18next';

import { List, Typography } from '@mui/material';
import { LangCodeEnum } from 'shared/enums/LangCodeEnum';
import { ListItemButtonStyled, ListItemStyled } from 'component/LanguageList/LanguageList.s';
import { Flag } from 'component/Flag/Flag';
import { LangNamespaceEnum } from 'shared/enums/LangNamespaceEnum';

export type LanguageListProps = {
  currentLanguage: LangCodeEnum;
  languages: Array<LangCodeEnum>;
  onSelection: (language: LangCodeEnum) => void;
};

export const LanguageList: React.VFC<LanguageListProps> = ({
  currentLanguage,
  languages,
  onSelection,
}) => {
  const { t } = useTranslation(LangNamespaceEnum.SHARED);

  const handleSelection = (language: LangCodeEnum) => {
    onSelection(language);
  };

  return (
    <List data-testid="languageList">
      {languages.map((language) => {
        const isCurrentLanguage = language === currentLanguage;

        return (
          <ListItemStyled
            key={language}
            disablePadding
            onClick={() => !isCurrentLanguage && handleSelection(language)}
          >
            <ListItemButtonStyled selected={isCurrentLanguage}>
              <Flag language={language} sx={{ marginRight: 1 }} width={30} />
              <Typography variant="body2" fontWeight={isCurrentLanguage ? 700 : 500}>
                {t('language.name', { lng: language })}
              </Typography>
            </ListItemButtonStyled>
          </ListItemStyled>
        );
      })}
    </List>
  );
};
