import { PREFERRED_LANGUAGE_STORAGE_KEY } from 'view/WithPreferredLanguage/WithPreferredLanguage';
import { LangCodeEnum } from 'shared/enums/LangCodeEnum';

const getPredefinedLanguage = (): LangCodeEnum => {
  const storageValue = sessionStorage.getItem(PREFERRED_LANGUAGE_STORAGE_KEY) ?? null;
  return JSON.parse(storageValue);
};

export const getInitialLanguage = () => {
  return getPredefinedLanguage() || LangCodeEnum.ENGLISH_BRITISH;
};
