import { Set } from 'immutable';
import { SimpleModel } from 'model/SimpleModel';

import { Logic } from './Logic';
import { LogicType } from './LogicType';

export namespace LogicClassification {
  export interface Shape extends Logic.Shape {
    departmentId?: number;
    classificationsIds?: Set<number>;
  }
}

export class LogicClassification extends Logic<LogicClassification.Shape> {
  constructor(initialData: SimpleModel.Data<LogicClassification.Shape>) {
    super(initialData, LogicType.Classification);
  }

  getDepartmentId(): number {
    return this.get('departmentId');
  }

  setDepartmentId(departmentId: number): this {
    return this.set('departmentId', departmentId);
  }

  getClassificationsIds(): Set<number> {
    return this.get('classificationsIds');
  }

  setClassificationsIds(classificationsIds: Set<number>): this {
    return this.set('classificationsIds', classificationsIds);
  }
}
