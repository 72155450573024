import React from 'react';

import {
  Icon as MuiIcon,
  IconButton,
  styled,
  Tooltip as MuiToggle,
  TooltipProps,
} from '@mui/material';
import { pxToRem } from 'app/theme/config';

export const Tooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <MuiToggle {...props} arrow classes={{ popper: className }} />
  ),
  {
    name: 'WBTooltip',
    overridesResolver: (props, styles) => [styles.root],
  }
)({});

export const WBToggleIconButton = styled(IconButton, {
  name: 'WBToggleIconButton',
  overridesResolver: (props, styles) => [styles.root, { width: 44, height: 44 }],
})({});

export const Icon = styled(MuiIcon)(() => ({
  fontSize: pxToRem(20),
}));
