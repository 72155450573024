import { PageItem } from './PageItem';

export namespace Question {
  export interface Shape extends PageItem.Shape {
    content: string;
  }
}

export abstract class Question<S extends Question.Shape = Question.Shape> extends PageItem<S> {
  getContent(): string {
    return this.get('content');
  }
}
