import { openModal } from 'action/modalAction';
import { fetchSubmit } from 'action/submitAction';
import { fetchAccessCodeInformation, fetchMeta, redirectToSurvey } from 'action/surveyAction';
import { SurveyRoute } from 'app/route';
import { mountDetector, reduceDetectors } from 'redux-detector';
import { SurveyModals } from 'register/SurveyModals';
import {
  selectAccessCode,
  selectDistributionSlug,
  selectSubmitId,
} from 'selector/navigationSelector';
import { selectHasMatch } from 'selector/routerSelector';
import { selectAvailableSurveys } from 'selector/surveySelector';

import { changedPositive } from './changedDetector';

const onAvailableSurveysFetch = mountDetector(
  selectAvailableSurveys,
  changedPositive((_, nextState) => {
    if (!nextState) {
      return;
    }

    // If only one survey is available, redirect immediately
    if (nextState.length === 1) {
      return redirectToSurvey({ slug: nextState[0].distributionId });
    }

    return openModal(SurveyModals.SelectSurvey);
  })
);

export const surveyDetector = reduceDetectors(
  mountDetector(
    selectDistributionSlug,
    changedPositive(() => fetchMeta())
  ),
  mountDetector(
    selectSubmitId,
    changedPositive(() => fetchSubmit())
  ),
  mountDetector(
    selectAccessCode,
    changedPositive(() => fetchSubmit())
  ),
  mountDetector(
    selectHasMatch(SurveyRoute.Survey.UNSUBSCRIBE_PATTERN),
    changedPositive(() => fetchAccessCodeInformation())
  ),
  onAvailableSurveysFetch
);
