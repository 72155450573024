import { HttpException } from './HttpException';

export class AccessDeniedException extends HttpException {
  static readonly API_CODE = 'ACCESS_DENIED';
  constructor(message: string, httpCode?: number) {
    super(message, httpCode);

    Object.setPrototypeOf(this, AccessDeniedException.prototype);
  }

  getApiCode(): string {
    return AccessDeniedException.API_CODE;
  }
}
