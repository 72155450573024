import { SurveyApi } from 'api/SurveyApi';
import { availableSurveysMapper } from 'container/availableSurveysMapper';

import { accessCodeMapper } from './accessCodeMapper';
import { answerMapper } from './answerMapper';
import { backendApiBeaconClient } from './backendApiBeaconClient';
import { backendApiClient } from './backendApiClient';
import { projectMapper } from './projectMapper';
import { metaMapper } from './metaMapper';

export const surveyApi = new SurveyApi(
  backendApiClient,
  backendApiBeaconClient,
  answerMapper,
  projectMapper,
  accessCodeMapper,
  availableSurveysMapper,
  metaMapper
);
