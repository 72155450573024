import React from 'react';

type SaveAndContinueContextValue = {
  value: boolean;
  toggle: () => void;
};

export const SaveAndContinueContext = React.createContext<SaveAndContinueContextValue>({
  value: false,
  toggle: () => {},
});

type SaveAndContinueProviderProps = {
  children?: React.ReactNode;
  initialValue?: boolean;
};

export const SaveAndContinueProvider: React.VFC<SaveAndContinueProviderProps> = ({
  children,
  initialValue = false,
}) => {
  const [value, setValue] = React.useState(initialValue);

  const toggle = () => setValue(!value);

  return (
    <SaveAndContinueContext.Provider value={{ value, toggle }}>
      {children}
    </SaveAndContinueContext.Provider>
  );
};
