import React from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { Page } from 'model/Page';
import { SurveyFormData } from 'shared/models/SurveyFormData';
import { useSurveyNavigation } from 'shared/hooks/useSurveyNavigation';
import { useSurveyFormSubmit } from 'shared/hooks/useSurveyFormSubmit';
import { SubmitErrorHandler, SubmitHandler } from 'react-hook-form/dist/types/form';
import { SaveAndContinueDialog } from 'component/SaveAndContinueDialog/SaveAndContinueDialog';
import { selectSurveyInitialAnswersFromSubmit } from 'selector/submitSelector';
import { findFirstErrorPageNumber } from 'shared/utils/form/surveyFormUtils';
import { Box } from '@mui/material';
import { useRecaptchaTokenAtom } from 'shared/hooks/useAtoms';
import { removeSubmittedSurveyAsync } from 'action/surveyAction';
import { useAppDispatch } from 'store/appStore';
import { selectDistributionSlug } from 'selector/navigationSelector';

type SurveyFormProviderProps = {
  children: React.ReactNode;
  pages: ReadonlyArray<Page>;
};

export const useSurveyForm = () => useFormContext<SurveyFormData>();

export const SurveyFormProvider: React.VFC<SurveyFormProviderProps> = ({ children, pages }) => {
  const dispatch = useAppDispatch();
  const distributionSlug = useSelector(selectDistributionSlug);
  const surveyInitialAnswersFromSubmit = useSelector(selectSurveyInitialAnswersFromSubmit);
  const formResult = useForm<SurveyFormData>({
    mode: 'all',
    shouldFocusError: true,
    defaultValues: { question: surveyInitialAnswersFromSubmit },
  });

  const { goToPageNumber } = useSurveyNavigation();

  const { submitSurvey } = useSurveyFormSubmit();

  const [token] = useRecaptchaTokenAtom();

  const onValidSubmit: SubmitHandler<SurveyFormData> = async (
    formData: SurveyFormData
  ): Promise<unknown> => {
    return submitSurvey({ formData, token }).then(() => {
      formResult.reset();
      dispatch(removeSubmittedSurveyAsync.resolved(distributionSlug));
    });
  };

  const onInvalidSubmit: SubmitErrorHandler<SurveyFormData> = (errors) => {
    const firstPageError = findFirstErrorPageNumber({ pages, errors });
    if (firstPageError) {
      goToPageNumber(firstPageError);
    }
  };

  return (
    <FormProvider {...formResult}>
      <Box
        component={'form'}
        onSubmit={formResult.handleSubmit(onValidSubmit, onInvalidSubmit)}
        sx={{ position: 'relative' }}
        noValidate
      >
        {children}
        <SaveAndContinueDialog />
      </Box>
    </FormProvider>
  );
};
