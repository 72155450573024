import React from 'react';
import { useTranslation } from 'react-i18next';

import { useLanguageDrawContext } from 'context/LanguageDrawContext';
import { useLanguage } from 'translations/LanguageProvider';
import { Flag } from 'component/Flag/Flag';
import { LangNamespaceEnum } from 'shared/enums/LangNamespaceEnum';

import { Toggle } from '../Toggle';

export const LanguageToggle: React.VFC = () => {
  const { toggle } = useLanguageDrawContext();
  const { language } = useLanguage();
  const { t } = useTranslation(LangNamespaceEnum.SURVEY_UI_COMPONENTS);

  return (
    <Toggle
      data-testid="language-toggle"
      title={t('LanguageToggle.title')}
      icon={<Flag language={language} width={24} />}
      onClick={toggle}
    />
  );
};
