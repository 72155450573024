import { Distribution } from './Distribution';
import { DistributionChannel } from './DistributionChannel';

export namespace WebLinkDistribution {
  export interface Shape extends Distribution.Shape {
    link: string;
  }
}

export class WebLinkDistribution extends Distribution<WebLinkDistribution.Shape> {
  getChannel(): DistributionChannel {
    return DistributionChannel.WebLink;
  }

  getLink(): string {
    return this.get('link');
  }
}
