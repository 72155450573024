import { PathFunction, compile } from 'path-to-regexp';
import { stringify as stringifyQuery } from 'query-string';
import { RouteQueryResult } from 'shared/models/RouteQueryResult';

export type CompilePathParams = {
  [key: string]: string | number | string[] | number[];
};

type CompilePathCache = {
  [key: string]: PathFunction;
};

const compilePathCache: CompilePathCache = {};

export function compilePath(
  pattern: string,
  params?: CompilePathParams,
  query?: RouteQueryResult
): string {
  if (!compilePathCache[pattern]) {
    compilePathCache[pattern] = compile(pattern);
  }

  const compiledPath = compilePathCache[pattern](params);
  const stringifiedQuery = stringifyQuery(query);

  return stringifiedQuery ? `${compiledPath}?${stringifiedQuery}` : compiledPath;
}
