export namespace SnackbarState {
  export type Entry = {
    id: number;
    message: string;
    isOpen?: boolean;
    timeoutId?: ReturnType<typeof setTimeout>;
  };
  export type Domain = Entry[];

  export const INITIAL_DOMAIN: Domain = [];
}

export type SnackbarState = {
  snackbars?: SnackbarState.Domain;
};

export type SnackbarAwareState = SnackbarState & {
  [key: string]: unknown;
};
