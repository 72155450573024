import { RGBColor } from 'react-color';

import { SimpleModel } from 'model/SimpleModel';

export namespace ProjectLayout {
  export interface Shape {
    questionTextColor: RGBColor;
    backgroundColor: RGBColor;
    navigationAndProgressColor: RGBColor;
    saveContinueColor: RGBColor;
  }
}

export class ProjectLayout extends SimpleModel<ProjectLayout.Shape> {
  getQuestionTextColor(): RGBColor {
    return this.get('questionTextColor');
  }

  getBackgroundColor(): RGBColor {
    return this.get('backgroundColor');
  }

  getNavigationAndProgressColor(): RGBColor {
    return this.get('navigationAndProgressColor');
  }

  getSaveContinueColor(): RGBColor {
    return this.get('saveContinueColor');
  }
}
