import React from 'react';

import { AppRoutes } from 'router/AppRoutes';
import { CssBaseline } from '@mui/material';
import { WorkbuzzTheme } from 'component/WorkbuzzTheme/WorkbuzzTheme';
import { SnackbarProvider } from 'component/SnackbarProvider/SnackbarProvider';
import { CookieConsent } from 'component/CookieConsent/CookieConsent';
import { ErrorBoundary } from 'component/ErrorBoundary/ErrorBoundary';
import { SaveAndContinueProvider } from 'context/SaveAndContinueContext';
import { LanguageProvider } from 'translations/LanguageProvider';
import { LanguageDrawProvider } from 'context/LanguageDrawContext';
import { BidirectionalStatus } from 'component/BidirectionalStatus/BidirectionalStatus';
import { WBGrowthBookProvider } from 'component/WBGrowthBookProvider/WBGrowthBookProvider';
import { LanguageDrawerContainer } from 'component/LanguageDrawer/LanguageDrawerContainer';
import { WithSurveyResourceLoader } from 'component/WithSurveyResourceLoader/WithSurveyResourceLoader';
import { WithRecaptcha } from 'component/WithRecaptcha/WithRecaptcha';

export const App: React.VFC = () => (
  <ErrorBoundary>
    <WithSurveyResourceLoader>
      <WBGrowthBookProvider>
        <LanguageProvider>
          <BidirectionalStatus>
            <WorkbuzzTheme>
              <CssBaseline />
              <SnackbarProvider>
                <CookieConsent />
                <SaveAndContinueProvider>
                  <LanguageDrawProvider>
                    <LanguageDrawerContainer />
                    <WithRecaptcha>
                      <AppRoutes />
                    </WithRecaptcha>
                  </LanguageDrawProvider>
                </SaveAndContinueProvider>
              </SnackbarProvider>
            </WorkbuzzTheme>
          </BidirectionalStatus>
        </LanguageProvider>
      </WBGrowthBookProvider>
    </WithSurveyResourceLoader>
  </ErrorBoundary>
);
