import { PageItemApi } from 'api/PageItemApi';
import { RawDraftContentState } from 'draft-js';
import { PageItemKind } from 'model/PageItemKind';
import { TextWidget } from 'model/TextWidget';

import { PageItemAbstractMapper } from './PageItemAbstractMapper';
import { PageItemKindMapper } from './PageItemMapper';

export class TextWidgetMapper
  implements PageItemKindMapper<TextWidget, PageItemApi.TextWidgetEntry> {
  deserialize(entry: PageItemApi.TextWidgetEntry): TextWidget {
    let content: RawDraftContentState;

    try {
      content = JSON.parse(entry.content);
    } catch (e) {
      console.error(e);
    }

    return new TextWidget({
      ...PageItemAbstractMapper.deserializeShape(entry),
      content: content,
      htmlContent: entry.htmlContent,
    });
  }

  supportsDeserialize(entry: PageItemApi.ItemEntry): boolean {
    return entry.kind === PageItemKind.TextWidget;
  }
}
