import { LogicApi } from 'api/LogicApi';
import { Set } from 'immutable';
import { LogicClassification } from 'model/LogicClassification';
import { LogicType } from 'model/LogicType';

import { LogicTypeMapper } from './LogicMapper';

export class LogicClassificationMapper
  implements LogicTypeMapper<LogicClassification, LogicApi.ClassificationEntry> {
  deserialize(entry: LogicApi.ClassificationEntry): LogicClassification {
    return new LogicClassification({
      type: entry.type,
      departmentId: entry.department,
      classificationsIds: Set<number>(entry.classifications),
    });
  }

  supportsDeserialize(entry: LogicApi.Entry): boolean {
    return entry.type === LogicType.Classification;
  }
}
