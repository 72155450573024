import { Action } from 'redux-actions';
import { ThunkAction } from 'redux-thunk';
import { selectNextSnackbarId, selectSnackbar } from 'selector/snackbarSelector';
import { SnackbarState } from 'store/SnackbarState';

export const SNACKBAR_REGISTERED = 'SNACKBAR_REGISTERED';
export type SnackbarRegisteredAction = Action<SnackbarState.Entry>;
export const snackbarRegistered = (entry: SnackbarState.Entry): SnackbarRegisteredAction => ({
  type: SNACKBAR_REGISTERED,
  payload: entry,
});

export const SNACKBAR_OPENED = 'SNACKBAR_OPENED';
export type SnackbarOpenedAction = Action<number>;
export const snackbarOpened = (id: number): SnackbarOpenedAction => ({
  type: SNACKBAR_OPENED,
  payload: id,
});

export const SNACKBAR_CLOSED = 'SNACKBAR_CLOSED';
export type SnackbarClosedAction = Action<number>;
export const snackbarClosed = (id: number): SnackbarClosedAction => ({
  type: SNACKBAR_CLOSED,
  payload: id,
});

export const SNACKBAR_UNREGISTERED = 'SNACKBAR_UNREGISTERED';
export type SnackbarUnregisteredAction = Action<number>;
export const snackbarUnregistered = (id: number): SnackbarUnregisteredAction => ({
  type: SNACKBAR_UNREGISTERED,
  payload: id,
});

export const openSnackbar = (
  message: string,
  timeout = 5000
): ThunkAction<number, SnackbarState, unknown> => (dispatch, getState) => {
  const id = selectNextSnackbarId(getState());
  let timeoutId: ReturnType<typeof setTimeout> | undefined;

  if (timeout) {
    timeoutId = setTimeout(() => dispatch(snackbarClosed(id)), timeout);
  }

  dispatch(snackbarRegistered({ id, message, timeoutId }));

  // to animate snackbar enter
  setTimeout(() => dispatch(snackbarOpened(id)), 0);

  return id;
};

export type ErrorMessageMapping = {
  type: unknown;
  message: string;
};

export const throwSnackbar = (
  mappings: ErrorMessageMapping[],
  defaultMessage: string,
  error: unknown,
  timeout = 5000
): ThunkAction<number, SnackbarState, unknown> => (dispatch) => {
  // find mapping
  const mapping = mappings.find(
    (aMapping) => aMapping.type && error instanceof (aMapping.type as never)
  );

  return dispatch(openSnackbar(mapping ? mapping.message : defaultMessage, timeout));
};

export const closeSnackbar = (id: number): ThunkAction<void, SnackbarState, unknown> => (
  dispatch,
  getState
) => {
  const snackbar = selectSnackbar(id)(getState());

  if (snackbar && snackbar.timeoutId) {
    clearTimeout(snackbar.timeoutId);
  }

  dispatch(snackbarClosed(id));

  // to animate snackbar leave
  setTimeout(() => dispatch(snackbarUnregistered(id)), 1000);
};
