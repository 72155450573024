import React from 'react';
import { Route, Switch } from 'react-router';

import { SurveyRoute } from 'app/route';
import { NotFoundPage } from 'view/NotFoundPage/NotFoundPage';
import { useInitializeRUM } from 'shared/hooks/useInitializeRUM';
import { useInitializeDataDogLogs } from 'shared/hooks/useInitializeDataDogLogs';

import { SurveyPreviewRoutes } from './SurveyPreviewRoutes';
import { SurveyRoutes } from './SurveyRoutes';

export const AppRoutes = () => {
  useInitializeRUM();
  useInitializeDataDogLogs();

  return (
    <Switch>
      <Route path={SurveyRoute.PREVIEW_PATTERN} component={SurveyPreviewRoutes} />
      <Route path={SurveyRoute.SURVEY_PATTERN} component={SurveyRoutes} />
      <Route component={NotFoundPage} />
    </Switch>
  );
};
