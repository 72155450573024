export enum LangCodeEnum {
  AFRIKAANS = 'af_ZA',
  ARABIC_UAE = 'ar_AE',
  BELGIAN = 'nl_BE',
  BENGALI = 'bn_IN',
  BOSNIAN = 'bs_BA',
  BULGARIAN = 'bg_BG',
  BURMESE = 'my_MY',
  CHINESE_DEFAULT = 'zh',
  CHINESE_SIMPLIFIED = 'zh_CN',
  CHINESE_TRADITIONAL = 'zh_TW',
  CROATIAN = 'hr_HR',
  CZECH = 'cs_CZ',
  DANISH = 'da_DK',
  DUTCH = 'nl_NL',
  ENGLISH_AMERICAN = 'en_US',
  ENGLISH_BRITISH = 'en_GB',
  ENGLISH_DEFAULT = 'en',
  FILIPINO = 'fil_PH',
  FINNISH = 'fi_FI',
  FRENCH = 'fr_FR',
  GERMAN = 'de_DE',
  GUJARATI = 'gu_IN',
  HINDI = 'hi_IN',
  HUNGARIAN = 'hu_HU',
  INDONESIAN = 'id_ID',
  IRISH = 'ga_IE',
  ITALIAN = 'it_IT',
  JAPANESE = 'ja_JP',
  KOREAN = 'ko_KR',
  LATVIAN = 'lv_LV',
  LITHUANIAN = 'lt_LT',
  MALAGASY = 'mg_MG',
  NORWEGIAN = 'no_NO',
  PERSIAN = 'fa_FA',
  POLISH = 'pl_PL',
  PORTUGUESE = 'pt_PT',
  PORTUGUESE_BRAZILIAN = 'pt_BR',
  PORTUGUESE_DEFAULT = 'pt',
  PUNJABI_INDIAN = 'pa_IN',
  ROMANIAN = 'ro_RO',
  RUSSIAN = 'ru_RU',
  SERBIAN = 'sr_RS',
  SLOVAKIAN = 'sk_SK',
  SLOVENIAN = 'sl_SI',
  SPANISH = 'es_ES',
  SPANISH_DEFAULT = 'es',
  SPANISH_MEXICAN = 'es_MX',
  SWAHILI = 'sw_KE',
  SWEDISH = 'sv_SE',
  TAMIL = 'ta_IN',
  TETUN = 'tet_TET',
  TSONGA = 'ts_TS',
  TURKISH = 'tr_TR',
  UKRAINIAN = 'uk_UA',
  URDU_PAKISTAN = 'ur_PK',
  VIETNAMESE = 'vi_VN',
  WELSH = 'cy_GB',
  XHOSA = 'xh_XH',
}
