import React from 'react';
import { useTranslation } from 'react-i18next';

import { SxProps } from '@mui/system';
import { LangCodeEnum } from 'shared/enums/LangCodeEnum';
import { FlagStyled } from 'component/Flag/Flag.s';
import { LangNamespaceEnum } from 'shared/enums/LangNamespaceEnum';
import { useGetFlagByLanguageMap } from 'shared/hooks/useGetFlagByLanguageMap';

type FlagProps = {
  language: LangCodeEnum;
  sx?: SxProps;
  width?: number;
  onClick?: () => void;
};

export const Flag: React.VFC<FlagProps> = ({ language, sx, width, onClick }) => {
  const { t: tShared } = useTranslation(LangNamespaceEnum.SHARED);
  const { t: tComponents } = useTranslation(LangNamespaceEnum.SURVEY_UI_COMPONENTS);

  const flagByLanguageMap = useGetFlagByLanguageMap();

  const languageName = tShared('language.name', { lng: language });
  const alt = tComponents('Flag.alt', { language: languageName });

  return (
    <FlagStyled
      data-cy={`languageFlag[${language}]`}
      data-testid="languageFlag"
      className="language-flag"
      onClick={onClick}
      alt={alt}
      src={flagByLanguageMap[language]}
      sx={sx}
      width={width}
    />
  );
};
