import { generateUtilityClasses } from '@mui/material';

export const answerToggleButtonClasses = generateUtilityClasses('WBAnswerToggleButton', [
  'root',
  'selected',
  'option1',
  'option2',
  'option3',
  'option4',
  'option5',
  'notApplicable',
  'containerBox',
  'selectedIconBox',
  'contentBox',
  'borderLine',
  'focusVisibleLine',
]);

export type AnswerToggleButtonClasses = typeof answerToggleButtonClasses;

export type AnswerToggleButtonKey = keyof AnswerToggleButtonClasses;
