import { PageItemApi } from 'api/PageItemApi';
import { List } from 'immutable';
import { PageItemKind } from 'model/PageItemKind';
import { QuestionRadio } from 'model/QuestionRadio';

import { CustomQuestionOptionMapper } from './CustomQuestionOptionMapper';
import { PageItemKindMapper } from './PageItemMapper';
import { QuestionAbstractMapper } from './QuestionAbstractMapper';

export class QuestionRadioMapper
  implements PageItemKindMapper<QuestionRadio, PageItemApi.QuestionRadioEntry> {
  private customQuestionOptionMapper: CustomQuestionOptionMapper;

  constructor(customQuestionOptionMapper: CustomQuestionOptionMapper) {
    this.customQuestionOptionMapper = customQuestionOptionMapper;
  }

  deserialize(entry: PageItemApi.QuestionRadioEntry): QuestionRadio {
    return new QuestionRadio({
      ...QuestionAbstractMapper.deserializeShape(entry),
      options: List(
        (entry.options || []).map((option) => this.customQuestionOptionMapper.deserialize(option))
      ),
      isRequired: Boolean(entry.required),
      hasOther: Boolean(entry.hasOther),
    });
  }

  supportsDeserialize(entry: PageItemApi.ImageWidgetEntry): boolean {
    return entry.kind === PageItemKind.QuestionRadioButton;
  }
}
