import { List } from 'immutable';
import { Page } from 'model/Page';
import { Project } from 'model/Project';
import { ProjectKind } from 'model/ProjectKind';
import { Resource } from 'model/Resource';
import { createSelector, Selector } from 'reselect';
import { SurveyState } from 'store/SurveyState';

import { createResourceContentSelector } from './resourceSelector';

export const selectSurveyResource: Selector<SurveyState, Resource<Project>> = (state) =>
  state?.surveyResource || Resource.void<Project>();

export const selectSurvey = createResourceContentSelector(selectSurveyResource);

export const selectSurveyPages = createSelector(
  selectSurvey,
  (survey) => survey?.getPages() || List<Page>()
);

export const selectError = (state: SurveyState) => state?.error;

export const selectProjectKind = createSelector(selectSurvey, (survey) => survey?.getKind());

export const selectPreferredLanguage = createSelector(selectSurvey, (survey) =>
  survey?.getPreferredLanguage()
);

export const selectIsPulseProject = createSelector(selectProjectKind, (kind) => {
  switch (kind) {
    case ProjectKind.PULSE_LIFECYCLE_PROJECT:
    case ProjectKind.PULSE_PROJECT:
    case ProjectKind.PULSE_ON_DEMAND_PROJECT:
    case ProjectKind.PULSE_TEMPLATE:
      return true;
    default:
      return false;
  }
});

export const selectIsLifecycleProject = createSelector(
  selectProjectKind,
  (kind) => kind === ProjectKind.PULSE_LIFECYCLE_PROJECT
);

export const isSaveAndContinueAvailableForProjectKind = createSelector(
  selectProjectKind,
  (kind) => {
    switch (kind) {
      case ProjectKind.PROJECT_CLIENT_ADVOCACY_SCORE:
      case ProjectKind.PROJECT_OVERALL_SCORE:
      case ProjectKind.PROJECT_ENGAGEMENT_SCORE:
      case ProjectKind.PROJECT_NET_PROMOTER_SCORE:
      case ProjectKind.PULSE_ON_DEMAND_PROJECT:
      case ProjectKind.PULSE_PROJECT:
        return true;
      default:
        return false;
    }
  }
);

export const selectAvailableSurveysResource = (
  state: SurveyState
): SurveyState['availableSurveys'] => {
  return state?.availableSurveys;
};

export const selectAvailableSurveys = createSelector(
  selectAvailableSurveysResource,
  (surveysResource) => (surveysResource?.isResolved() ? surveysResource.getContent() : null)
);
