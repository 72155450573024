import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { SaveAndContinueContext } from 'context/SaveAndContinueContext';
import { Typography } from '@mui/material';
import { Dialog } from 'component/Dialog/Dialog';
import { SaveAndContinueForm } from 'component/SaveAndContinueForm/SaveAndContinueForm';
import { useSurveyFormSave } from 'shared/hooks/useSurveyFormSubmit';
import { useSnackbar } from 'notistack';
import { LangNamespaceEnum } from 'shared/enums/LangNamespaceEnum';
import { selectIsPulseProject } from 'selector/surveySelector';
import { selectSaveAndContinueMessage } from 'selector/metaSelector';
import { useRecaptchaTokenAtom } from 'shared/hooks/useAtoms';
import { useSurveyForm } from 'component/ProtectedSurveyFormProvider/SurveyFormProvider';

export const SaveAndContinueDialog: React.VFC = () => {
  const { t } = useTranslation(LangNamespaceEnum.SURVEY_UI_COMPONENTS);
  const { getValues } = useSurveyForm();
  const formData = getValues();
  const isPulseProject = useSelector(selectIsPulseProject);
  const saveAndContinueMessage = useSelector(selectSaveAndContinueMessage);

  const { value, toggle } = useContext(SaveAndContinueContext);
  const { enqueueSnackbar } = useSnackbar();
  const { saveSurvey } = useSurveyFormSave();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [token] = useRecaptchaTokenAtom();

  const handleSave = (continueEmail: string) => {
    if (Object.keys(formData).length === 0) {
      enqueueSnackbar(t('SaveAndContinueDialog.error'), { variant: 'error' });
      return;
    }

    setIsSubmitting(true);

    saveSurvey({ continueEmail, formData, token })
      .then(() => toggle())
      .finally(() => setIsSubmitting(false));
  };

  return (
    <Dialog open={value} onClose={toggle} title={t('SaveAndContinueDialog.title')}>
      {isPulseProject ? (
        <>
          <Typography sx={{ mb: 2 }}>{t('SaveAndContinueDialog.description[1]')}</Typography>
          <Typography sx={{ mb: 2 }}>{t('SaveAndContinueDialog.description[2]')}</Typography>
          <Typography sx={{ mb: 2 }}>{t('SaveAndContinueDialog.description[3]')}</Typography>
        </>
      ) : (
        <>
          {saveAndContinueMessage.split('\n').map((paragraph, index) => (
            <Typography key={index} sx={{ mb: 2, minHeight: 27 }}>
              {paragraph}
            </Typography>
          ))}
        </>
      )}
      <SaveAndContinueForm isSubmitting={isSubmitting} onSave={handleSave} />
    </Dialog>
  );
};
