import { AccessCodeApi } from 'api/AccessCodeApi';
import { AccessCode } from 'model/AccessCode';

export class AccessCodeMapper {
  deserialize(entry: AccessCodeApi.Entry): AccessCode {
    return new AccessCode({
      employeeId: entry.employeeId,
      employeeEmail: entry.employeeEmail,
      distribution: entry.projectDistribution,
      surveyLink: entry.surveyLink,
      code: entry.code,
      subscribed: entry.subscribed,
    });
  }
}
