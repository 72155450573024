import React from 'react';

import { lightTheme } from 'app/theme/light';
import { Theme } from '@mui/material/styles';
import { ThemeModeEnum } from 'shared/enums/ThemeModeEnum';

export type ThemeContext = {
  themeMode: ThemeModeEnum;
  theme: Theme;
  setThemeByMode: (themeMode: string) => void;
  toggleTheme: () => void;
};

export const ThemeContext = React.createContext<ThemeContext>({
  themeMode: ThemeModeEnum.LIGHT,
  theme: lightTheme,
  setThemeByMode: () => {},
  toggleTheme: () => {},
});
