import { createTheme } from '@mui/material/styles';
import { colors } from 'app/theme/config';

export const lightPalette = createTheme({
  palette: {
    error: {
      main: colors.monza,
    },
    primary: {
      main: colors.tundora,
    },
    secondary: {
      main: colors.tundora,
    },
    success: {
      main: colors.christi,
    },
    text: {
      primary: colors.tundora,
    },
  },
}).palette;
