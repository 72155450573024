import { Box, ButtonBase, styled } from '@mui/material';
import { colors } from 'app/theme/config';
import { Container } from 'component/Container/Container';
import { ThemeModeEnum } from 'shared/enums/ThemeModeEnum';

export const PreferredLanguagePickerContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',

  [theme.breakpoints.up('sm')]: {
    flexGrow: 1,
  },
}));

export const PreferredLanguagePickerAnimationContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',

  height: '56px',
  marginTop: '37px',
  marginBottom: '10px',
  [theme.breakpoints.up('sm')]: {
    height: '104px',
    marginBottom: '25px',
  },
}));

export const PreferredLanguagePickerItemButton = styled(ButtonBase)(({ theme }) => ({
  padding: 8,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: '24px',
  width: '100%',
  '&:focus-visible': {
    borderRadius: '6px',
    outlineColor:
      theme.themeName === ThemeModeEnum.HIGH_CONTRAST_ACCESSIBLE
        ? colors.candyCorn
        : colors.cornflowerBlue,
    outlineStyle: 'solid',
    outlineWidth: '2px',
  },
  '.MuiTypography-root': {
    color: theme.themeName === ThemeModeEnum.HIGH_CONTRAST_ACCESSIBLE ? colors.white : 'inherit',
    lineHeight: '1.8',
    position: 'relative',
    top: 0,
    transition: 'top 0.3s ease-in-out',
  },
  '&:hover .MuiTypography-root': {
    top: -10,
  },
  '&:hover .MuiTypography-root, &:active .MuiTypography-root': {
    textUnderlineOffset: 5,
    textDecoration: 'underline',
    textDecorationThickness: '2px',
  },
  '&:active .MuiTypography-root': {
    textDecorationColor:
      theme.themeName === ThemeModeEnum.HIGH_CONTRAST_ACCESSIBLE && colors.malibu,
  },
  '&:focus .MuiTypography-root': {
    color: theme.themeName === ThemeModeEnum.HIGH_CONTRAST_ACCESSIBLE && colors.candyCorn,
  },
  img: {
    position: 'relative',
    top: 0,
    transition: 'top 0.2s ease-in-out',
  },
  '&:hover img': {
    borderColor: theme.themeName === ThemeModeEnum.HIGH_CONTRAST_ACCESSIBLE && colors.white,
    top: -10,
  },
  '&:active img': {
    borderColor:
      theme.themeName === ThemeModeEnum.HIGH_CONTRAST_ACCESSIBLE ? colors.malibu : colors.black,
  },
}));
