import { RegisterOptions } from 'react-hook-form';

import { createRequiredRules } from 'shared/utils/rules/createRequiredRules';

export const createEmailRules = ({
  incorrectMessage,
  requiredMessage,
}: {
  incorrectMessage: string;
  requiredMessage: string;
}): Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'> => ({
  pattern: {
    message: incorrectMessage,
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  },
  ...createRequiredRules(requiredMessage),
});
