import * as React from 'react';
import { useSelector } from 'react-redux';

import Button from '@material-ui/core/Button';
import { unsubscribe } from 'action/surveyAction';
import { ResourceLoader } from 'component/WithSurveyResourceLoader/ResourceLoader';
import { selectAccessCodeResource } from 'selector/accessCodeSelector';
import { Loader } from 'component/Loader/Loader';
import { Typography } from '@mui/material';
import { useAppDispatch } from 'store/appStore';
import { AccessCode } from 'model/AccessCode';

import { Wrapper } from './SurveyUnsubscribedPage.s';

export const SurveyUnsubscribedPage: React.VFC = () => {
  const dispatch = useAppDispatch();
  const accessCodeResource = useSelector(selectAccessCodeResource);
  const onUnsubscribe = () => dispatch(unsubscribe());

  return (
    <ResourceLoader
      resource={accessCodeResource}
      loader={() => <Loader />}
      content={(accessCode: AccessCode) =>
        accessCode.isSubscribed() ? (
          <Wrapper>
            <Typography variant="h4">Unsubscribe</Typography>
            <Typography variant="body1" mb={2}>
              Are you sure you want to unsubscribe?
            </Typography>
            <Button
              data-testid="unsubscribeButton"
              variant="contained"
              onClick={onUnsubscribe}
              color="primary"
            >
              Unsubscribe
            </Button>
          </Wrapper>
        ) : (
          <Wrapper>
            <Typography variant="h4">You have been unsubscribed</Typography>
            <Typography variant="body1">
              You will not receive unknown emails about this survey.
            </Typography>
          </Wrapper>
        )
      }
    />
  );
};
