import { SimpleModel } from 'model/SimpleModel';

import { DistributionChannel } from './DistributionChannel';

export namespace Distribution {
  export interface Shape {
    id: string;
    slug: string;
    [key: string]: string;
  }
}

export abstract class Distribution<
  S extends Distribution.Shape = Distribution.Shape
> extends SimpleModel<S> {
  getId(): string {
    return this.get('id');
  }

  getSlug(): string {
    return this.get('slug') || this.get('id');
  }

  abstract getChannel(): DistributionChannel;
}
