import { ProjectKind } from 'model/ProjectKind';

export const isAnonymous = (projectKind: string) => {
  switch (projectKind) {
    case ProjectKind.PULSE_ON_DEMAND_PROJECT:
    case ProjectKind.PULSE_PROJECT:
      return true;
    default:
      return false;
  }
};
