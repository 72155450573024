import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from 'store/appStore';
import { fetchSurvey } from 'action/surveyAction';
import { selectDistributionSlug } from 'selector/navigationSelector';

export const useFetchSurvey = () => {
  const dispatch = useAppDispatch();
  const distributionSlug = useSelector(selectDistributionSlug);

  useEffect(() => {
    if (distributionSlug) {
      dispatch(fetchSurvey());
    }
  }, [distributionSlug, dispatch]);
};
