import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import TagManager from 'react-gtm-module';

import moment from 'moment';
import { CookieConsentPure } from 'component/CookieConsent/CookieConsentPure';
import { getGTMKey } from 'app/ga/gaKeys';
import { TRACKING_CONSENT_COOKIE } from 'shared/constants/cookies';

export type CookieConsentProps = {};

export const CookieConsent: React.VFC<CookieConsentProps> = () => {
  const [cookies, setCookie] = useCookies([TRACKING_CONSENT_COOKIE]);
  const expires = moment().add(1, 'year').toDate();
  const answeredCookieQuestion = cookies[TRACKING_CONSENT_COOKIE] === undefined;
  const isCookieConsentAccepted = cookies[TRACKING_CONSENT_COOKIE] === '1';

  useEffect(() => {
    if (isCookieConsentAccepted && getGTMKey()) {
      TagManager.initialize({
        gtmId: getGTMKey(),
      });
    }
  }, [isCookieConsentAccepted]);

  return (
    <>
      {answeredCookieQuestion && getGTMKey() && (
        <CookieConsentPure
          onAccept={() => {
            setCookie(TRACKING_CONSENT_COOKIE, '1', { expires, sameSite: 'none', secure: true });
          }}
          onReject={() => {
            setCookie(TRACKING_CONSENT_COOKIE, '0', { expires, sameSite: 'none', secure: true });
          }}
        />
      )}
    </>
  );
};
