import React, { HTMLInputTypeAttribute } from 'react';
import { Control, FieldValues, RegisterOptions, useController } from 'react-hook-form';

import { TextField } from 'component/Form/TextField/TextField';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { InputProps } from '@mui/material';

type TextFieldControllerProps = {
  control: Control<FieldValues, object>;
  rules: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
  label?: string;
  name: string;
  type?: HTMLInputTypeAttribute;
  disabled?: boolean;
  sx?: SxProps<Theme>;
  InputProps?: InputProps;
  shouldHideErrorMessage?: boolean;
  shouldHideValidationAdornmentIcon?: boolean;
};

export const TextFieldController: React.VFC<TextFieldControllerProps> = ({
  label,
  name,
  control,
  rules,
  type = 'text',
  disabled,
  sx,
  InputProps,
  shouldHideErrorMessage,
  shouldHideValidationAdornmentIcon,
}) => {
  const {
    field: { onChange, onBlur, value },
    fieldState: { error, isDirty },
  } = useController({
    name,
    control,
    rules,
  });

  return (
    <TextField
      data-testid={name}
      error={!!error}
      errorMessage={error?.message}
      fullWidth
      isDirty={isDirty}
      label={label}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      type={type}
      value={value}
      disabled={disabled}
      sx={sx}
      InputProps={InputProps}
      shouldHideErrorMessage={shouldHideErrorMessage}
      shouldHideValidationAdornmentIcon={shouldHideValidationAdornmentIcon}
    />
  );
};
