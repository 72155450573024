import { CategoryApi } from 'api/CategoryApi';
import { PageItemApi } from 'api/PageItemApi';
import { List } from 'immutable';
import { PageItemKind } from 'model/PageItemKind';
import { QuestionOpinion } from 'model/QuestionOpinion';

import { CategoryMapper } from './CategoryMapper';
import { PageItemKindMapper } from './PageItemMapper';
import { QuestionAbstractMapper } from './QuestionAbstractMapper';

export class QuestionOpinionMapper
  implements PageItemKindMapper<QuestionOpinion, PageItemApi.QuestionOpinionEntry> {
  private categoryMapper: CategoryMapper;

  constructor(categoryMapper: CategoryMapper) {
    this.categoryMapper = categoryMapper;
  }

  deserialize(entry: PageItemApi.QuestionOpinionEntry): QuestionOpinion {
    return new QuestionOpinion({
      ...QuestionAbstractMapper.deserializeShape(entry),
      category: entry.category
        ? this.categoryMapper.deserialize(entry.category as CategoryApi.Entry)
        : undefined,
      options: List.of(entry.option1, entry.option2, entry.option3, entry.option4, entry.option5),
      reportedAs: entry.reportedAs,
      notApplicable: entry.notApplicable,
      isRequired: Boolean(entry.required),
      hasNotApplicableOption: Boolean(entry.hasNotApplicableOption),
    });
  }

  supportsDeserialize(entry: PageItemApi.ImageWidgetEntry): boolean {
    return entry.kind === PageItemKind.QuestionOpinion;
  }
}
