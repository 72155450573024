import { getAppConfig } from 'app/getConfig';
import { AxiosError, AxiosResponse } from 'axios';
import { HttpException } from 'exception/HttpException';

import { selectErrorCode, selectErrorData, selectResponseData } from './axiosSelector';

export const selectAdminApiUrl = (): string => getAppConfig().ADMIN_API_CLIENT_URL;
export const selectRecaptchaKey = (): string => getAppConfig().RECAPTCHA_SITE_KEY;

export const selectApiData = <T extends { data: unknown }>(response: AxiosResponse<T>): T['data'] =>
  selectResponseData(response, 'data');

type ApiError = {
  code: string;
  message: string;
  [key: string]: unknown;
};

export const selectApiError = (error: AxiosError): ApiError => selectErrorData(error, 'errors[0]');

interface ApiErrorMappedCandidate {
  readonly API_CODE: string;
  new (message: string, httpCode?: number, args?: unknown): Error;
}

export const selectMappedApiError = (candidates: ApiErrorMappedCandidate[]) => (
  error: AxiosError
): Error | AxiosError => {
  const apiError = selectApiError(error);

  if (!apiError) {
    return error;
  }

  const { code, message, ...args } = apiError;

  const candidate = candidates.find((aCandidate) => aCandidate.API_CODE === code);

  if (candidate) {
    return new candidate(message, selectErrorCode(error), args);
  }

  return new HttpException(message, selectErrorCode(error));
};

export const throwMappedApiError = (candidates: ApiErrorMappedCandidate[]) => (
  error: AxiosError
): never => {
  throw selectMappedApiError(candidates)(error);
};
