import { DistributionMapper } from 'mapper/DistributionMapper';
import { EmailDistributionMapper } from 'mapper/EmailDistributionMapper';
import { KioskDistributionMapper } from 'mapper/KioskDistributionMapper';
import { PaperDistributionMapper } from 'mapper/PaperDistributionMapper';
import { WebLinkDistributionMapper } from 'mapper/WebLinkDistributionMapper';

export const distributionMapper = new DistributionMapper([
  new EmailDistributionMapper(),
  new WebLinkDistributionMapper(),
  new PaperDistributionMapper(),
  new KioskDistributionMapper(),
]);
