import { PageItemApi } from 'api/PageItemApi';
import { List } from 'immutable';
import { PageItemKind } from 'model/PageItemKind';
import { QuestionCheckbox } from 'model/QuestionCheckbox';

import { CustomQuestionOptionMapper } from './CustomQuestionOptionMapper';
import { PageItemKindMapper } from './PageItemMapper';
import { QuestionAbstractMapper } from './QuestionAbstractMapper';

export class QuestionCheckboxMapper
  implements PageItemKindMapper<QuestionCheckbox, PageItemApi.QuestionCheckboxEntry> {
  private customQuestionOptionMapper: CustomQuestionOptionMapper;

  constructor(customQuestionOptionMapper: CustomQuestionOptionMapper) {
    this.customQuestionOptionMapper = customQuestionOptionMapper;
  }

  deserialize(entry: PageItemApi.QuestionCheckboxEntry): QuestionCheckbox {
    return new QuestionCheckbox({
      ...QuestionAbstractMapper.deserializeShape(entry),
      options: List(
        (entry.options || []).map((option) => this.customQuestionOptionMapper.deserialize(option))
      ),
      limitAnswerCount: entry.limitAnswerCount,
      isRequired: Boolean(entry.required),
      hasOther: Boolean(entry.hasOther),
      numberingSkipped: Boolean(entry.numberingSkipped),
    });
  }

  supportsDeserialize(entry: PageItemApi.ImageWidgetEntry): boolean {
    return entry.kind === PageItemKind.QuestionCheckbox;
  }
}
