import { PageItemApi } from 'api/PageItemApi';
import { List } from 'immutable';
import { PageItemKind } from 'model/PageItemKind';
import { QuestionForm } from 'model/QuestionForm';

import { CustomQuestionOptionMapper } from './CustomQuestionOptionMapper';
import { PageItemKindMapper } from './PageItemMapper';
import { QuestionAbstractMapper } from './QuestionAbstractMapper';

export class QuestionFormMapper
  implements PageItemKindMapper<QuestionForm, PageItemApi.QuestionFormEntry> {
  private customQuestionOptionMapper: CustomQuestionOptionMapper;

  constructor(customQuestionOptionMapper: CustomQuestionOptionMapper) {
    this.customQuestionOptionMapper = customQuestionOptionMapper;
  }

  deserialize(entry: PageItemApi.QuestionFormEntry): QuestionForm {
    return new QuestionForm({
      ...QuestionAbstractMapper.deserializeShape(entry),
      options: List(
        (entry.options || []).map((option) => this.customQuestionOptionMapper.deserialize(option))
      ),
      optionHeight: entry.optionHeight,
      optionWidth: entry.optionWidth,
      numberingSkipped: Boolean(entry.numberingSkipped),
    });
  }

  supportsDeserialize(entry: PageItemApi.ImageWidgetEntry): boolean {
    return entry.kind === PageItemKind.QuestionForm;
  }
}
