import { PageItemApi } from 'api/PageItemApi';
import { PageItemKind } from 'model/PageItemKind';
import { QuestionCommentBox } from 'model/QuestionCommentBox';

import { PageItemKindMapper } from './PageItemMapper';
import { QuestionAbstractMapper } from './QuestionAbstractMapper';

export class QuestionCommentBoxMapper
  implements PageItemKindMapper<QuestionCommentBox, PageItemApi.QuestionCommentBox> {
  deserialize(entry: PageItemApi.QuestionCommentBox): QuestionCommentBox {
    return new QuestionCommentBox({
      ...QuestionAbstractMapper.deserializeShape(entry),
      isRequired: Boolean(entry.required),
      textBoxWidth: entry.width,
      textBoxHeight: entry.height,
      reportedAs: entry.reportedAs,
      numberingSkipped: Boolean(entry.numberingSkipped),
    });
  }

  supportsDeserialize(entry: PageItemApi.ImageWidgetEntry): boolean {
    return entry.kind === PageItemKind.QuestionCommentsBox;
  }
}
