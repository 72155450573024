import React, { useContext, useState } from 'react';

type LanguageDrawContextValue = {
  value: boolean;
  toggle: () => void;
};

export const LanguageDrawContext = React.createContext<LanguageDrawContextValue>({
  value: false,
  toggle: () => {},
});

export const useLanguageDrawContext = () => {
  return useContext(LanguageDrawContext);
};

type LanguageDrawProviderProps = {
  children?: React.ReactNode;
  initialValue?: boolean;
};

export const LanguageDrawProvider: React.VFC<LanguageDrawProviderProps> = ({
  children,
  initialValue = false,
}) => {
  const [value, setValue] = useState(initialValue);

  const toggle = () => {
    setValue(!value);
  };

  return (
    <LanguageDrawContext.Provider value={{ value, toggle }}>
      {children}
    </LanguageDrawContext.Provider>
  );
};
