import { stringify as stringifyQuery } from 'querystring';

import { AnswerApi } from 'api/AnswerApi';
import { AxiosInstance } from 'axios';
import { AccessDeniedException } from 'exception/AccessDeniedException';
import { NoAvailableSurveysException } from 'exception/NoAvailableSurveysException';
import { SurveyAlreadyCompletedException } from 'exception/SurveyAlreadyCompletedException';
import { SurveyClosedException } from 'exception/SurveyClosedException';
import { SurveyInDevelopmentException } from 'exception/SurveyInDevelopmentException';
import { UnauthorizedException } from 'exception/UnauthorizedException';
import { List } from 'immutable';
import { AccessCodeMapper } from 'mapper/AccessCodeMapper';
import { AnswerMapper } from 'mapper/AnswerMapper';
import { AvailableSurveysMapper } from 'mapper/AvailableSurveyMapper';
import { ProjectMapper } from 'mapper/ProjectMapper';
import { AccessCode } from 'model/AccessCode';
import { Answer } from 'model/Answer';
import { AvailableSurvey } from 'model/AvailableSurvey';
import { Project } from 'model/Project';
import { selectApiData, throwMappedApiError } from 'selector/apiSelector';
import { selectResponseData, throwErrorData } from 'selector/axiosSelector';
import { Meta } from 'model/Meta';
import { MetaMapper } from 'mapper/MetaMapper';
import { mapRespondentMetaDtoToModel, RespondentMetaModel } from 'api/dto/RespondentMeta.dto';

export namespace SurveyApi {
  export type SavePost = {
    answers: AnswerApi.Entry[];
    token?: string;
    submitId?: string;
  };
  export type SubmitPost = SavePost;
  export type SaveQuery = {
    save: boolean;
    continueEmail?: string;
  };

  export type AvailableSurvey = {
    projectName: string;
    startDate: string | null;
    distributionId: string;
  };
}

export class SurveyApi {
  private client: AxiosInstance;
  private beaconClient: (path: string, data: unknown) => boolean;
  private answerMapper: AnswerMapper;
  private projectMapper: ProjectMapper;
  private accessCodeMapper: AccessCodeMapper;
  private availableSurveysMapper: AvailableSurveysMapper;
  private metaMapper: MetaMapper;

  constructor(
    client: AxiosInstance,
    beaconClient: (path: string, data: unknown) => boolean,
    answerMapper: AnswerMapper,
    projectMapper: ProjectMapper,
    accessCodeMapper: AccessCodeMapper,
    availableSurveysMapper: AvailableSurveysMapper,
    metaMapper: MetaMapper
  ) {
    this.availableSurveysMapper = availableSurveysMapper;
    this.accessCodeMapper = accessCodeMapper;
    this.projectMapper = projectMapper;
    this.answerMapper = answerMapper;
    this.metaMapper = metaMapper;
    this.beaconClient = beaconClient;
    this.client = client;
  }

  get(distributionSlug: string, accessCode?: string, submitId?: string): Promise<Project> {
    return this.client
      .get(
        `/api/v1/survey/${distributionSlug}` +
          (accessCode ? `/${accessCode}` : '') +
          (submitId ? `?submitId=${submitId}` : '')
      )
      .then(selectApiData)
      .then((entry) => this.projectMapper.deserialize(entry))
      .catch(
        throwMappedApiError([
          SurveyInDevelopmentException,
          SurveyClosedException,
          SurveyAlreadyCompletedException,
          AccessDeniedException,
          UnauthorizedException,
        ])
      );
  }

  preview(distributionSlug: string, query: string): Promise<Project> {
    return this.client
      .get(`/api/v1/survey/${distributionSlug}/preview?${query}`)
      .then(selectApiData)
      .then((entry) => this.projectMapper.deserialize(entry))
      .catch(
        throwMappedApiError([
          SurveyInDevelopmentException,
          SurveyClosedException,
          SurveyAlreadyCompletedException,
          AccessDeniedException,
          UnauthorizedException,
        ])
      );
  }

  getAvailableSurveys(
    distributionSlug: string,
    accessCode: string
  ): Promise<AvailableSurvey.Instance[]> {
    return this.client
      .get<{ data: SurveyApi.AvailableSurvey[] }>(
        `/api/v1/survey/${distributionSlug}/available-surveys/${accessCode}`
      )
      .then(selectApiData)
      .then((surveys) => {
        return surveys.map(this.availableSurveysMapper.deserialize);
      })
      .catch(
        throwMappedApiError([
          SurveyInDevelopmentException,
          SurveyClosedException,
          SurveyAlreadyCompletedException,
          AccessDeniedException,
          UnauthorizedException,
          NoAvailableSurveysException,
        ])
      );
  }

  save(
    distributionSlug: string,
    answers: List<Answer>,
    accessCode?: string,
    continueEmail?: string,
    useBeacon = false,
    submitId?: string,
    token?: string
  ): Promise<void> {
    const data: SurveyApi.SavePost = {
      answers: answers.map((answer) => this.answerMapper.serialize(answer)).toArray(),
      submitId: submitId,
      token: token,
    };
    const query: SurveyApi.SaveQuery = {
      save: true,
      continueEmail,
    };

    const path =
      `/api/v1/survey/${distributionSlug}` +
      (accessCode ? `/${accessCode}` : '') +
      `?${stringifyQuery(query)}`;

    if (useBeacon) {
      // in order to send request to the server after page unload, we need to use beacon client
      this.beaconClient(path, data);

      return Promise.resolve();
    } else {
      return this.client
        .post(path, data)
        .then(() => undefined)
        .catch((error) => throwErrorData(error, 'errors'));
    }
  }

  submit(
    distributionSlug: string,
    answers: List<Answer>,
    accessCode?: string,
    submitId?: string,
    token?: string
  ): Promise<string> {
    const data: SurveyApi.SubmitPost = {
      answers: answers.map((answer) => this.answerMapper.serialize(answer)).toArray(),
      token: token,
      submitId,
    };

    return this.client
      .post(`/api/v1/survey/${distributionSlug}` + (accessCode ? `/${accessCode}` : ''), data)
      .then(() => distributionSlug)
      .catch((error) => throwErrorData(error, 'errors'));
  }

  unsubscribe(distributionSlug: string, accessCode: string): Promise<AccessCode> {
    return this.client
      .post(`/api/v1/survey/${distributionSlug}/${accessCode}/unsubscribe`)
      .then((response) => selectResponseData(response, 'data'))
      .then((entry) => this.accessCodeMapper.deserialize(entry))
      .catch((error) => throwErrorData(error, 'errors'));
  }

  fetchAccessCodeInformation(distributionId: string, accessCode: string): Promise<AccessCode> {
    return this.client
      .get(`/api/v1/survey/${distributionId}/${accessCode}/access-code`)
      .then((response) => selectResponseData(response, 'data'))
      .then((entry) => this.accessCodeMapper.deserialize(entry))
      .catch((error) => throwErrorData(error, 'errors'));
  }

  getMeta(distributionSlug: string): Promise<Meta> {
    return this.client
      .get(`/api/v1/survey/${distributionSlug}/meta`)
      .then(selectApiData)
      .then((entry) => this.metaMapper.deserialize(entry))
      .catch((error) => throwErrorData(error, 'errors'));
  }

  getRespondentMeta(distributionSlug: string, kioskCode: string): Promise<RespondentMetaModel> {
    return this.client
      .get(`/api/v1/survey/${distributionSlug}/respondent/${kioskCode}/meta`)
      .then(selectApiData)
      .then(mapRespondentMetaDtoToModel)
      .catch((error) => throwErrorData(error, 'errors'));
  }
}
