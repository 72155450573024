import { ControllerFieldState, FieldErrors } from 'react-hook-form';

import { PageItem } from 'model/PageItem';
import {
  FormQuestionFieldName,
  FormQuestionKey,
  SurveyFormData,
} from 'shared/models/SurveyFormData';
import { Page as PageModel } from 'model/Page';
import { intersection } from 'lodash-es';

export const QUESTION_ID_PREFIX = 'id:';

const mapQuestionIdToFieldKey = (questionId: number): FormQuestionKey => {
  return `${QUESTION_ID_PREFIX}${questionId}`;
};

export const mapQuestionIdToFieldName = (questionId: number): FormQuestionFieldName => {
  return `question.${mapQuestionIdToFieldKey(questionId)}`;
};

export const mapItemToFieldKey = (item: PageItem): FormQuestionKey => {
  return mapQuestionIdToFieldKey(item.getId());
};

export const mapItemToFieldName = (item: PageItem): FormQuestionFieldName => {
  return mapQuestionIdToFieldName(item.getId());
};

export const mapToFieldError = (fieldState: ControllerFieldState) => {
  const errorMsg = fieldState.error?.message;
  return {
    error: !!errorMsg,
    helperText: errorMsg,
  };
};

export const findFirstErrorPageNumber = ({
  pages,
  errors,
}: {
  pages: readonly PageModel[];
  errors: FieldErrors<SurveyFormData>;
}): number | null => {
  if (!errors.question) {
    return null;
  }

  const errorQuestionKeys = Object.keys(errors.question) as FormQuestionKey[];

  const firstErrorPageIndex = pages.findIndex((page) => {
    const currentPageQuestionKeys = page
      .getItems()
      .toArray()
      .map((pageItem) => mapItemToFieldKey(pageItem));
    return intersection(currentPageQuestionKeys, errorQuestionKeys).length > 0;
  });

  return firstErrorPageIndex >= 0 ? firstErrorPageIndex + 1 : null;
};
