import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, CircularProgress } from '@mui/material';
import { colors } from 'app/theme/config';
import { LangNamespaceEnum } from 'shared/enums/LangNamespaceEnum';

export const AppLoader: React.VFC = () => {
  const { t } = useTranslation(LangNamespaceEnum.SURVEY_UI_COMPONENTS);

  return (
    <Box
      data-testid="appLoader"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        paddingTop: 6,
      }}
    >
      <CircularProgress
        aria-label={t('AppLoader.ariaLabel')}
        sx={{
          color: colors.tundora,
        }}
      />
    </Box>
  );
};
