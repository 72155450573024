export namespace SurveyRoute {
  export const SURVEY_PATTERN = `/:distributionSlug` as const;
  export const PREVIEW_PATTERN = `${SURVEY_PATTERN}/preview` as const;

  export namespace Survey {
    export const PAGE_PATTERN = `${SURVEY_PATTERN}/page/:page` as const;
    export const SUBMIT_PATTERN = `${SURVEY_PATTERN}/submit` as const;
    export const SUCCESS_PATTERN = `${SURVEY_PATTERN}/success` as const;
    export const CLOSED_PATTERN = `${SURVEY_PATTERN}/closed` as const;
    export const UNSUBSCRIBE_PATTERN = `${SURVEY_PATTERN}/unsubscribe` as const;
    export const KIOSK_PATTERN = `${SURVEY_PATTERN}/kiosk` as const;
    export const SAVE_SUCCESS_PATTERN = `${SURVEY_PATTERN}/saved-success` as const;
  }

  export namespace Preview {
    export const PAGE_PATTERN = `${PREVIEW_PATTERN}/page/:page` as const;
    export const SUBMIT_PATTERN = `${PREVIEW_PATTERN}/submit` as const;
    export const SUCCESS_PATTERN = `${PREVIEW_PATTERN}/success` as const;
    export const SAVE_SUCCESS_PATTERN = `${PREVIEW_PATTERN}/saved-success` as const;
  }
}
