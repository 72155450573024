import { alpha, ThemeOptions } from '@mui/material/styles';
import { inputBorderRadius, pxToRem, typography, breakpoints, colors } from 'app/theme/config';
import { mergeStrict } from 'shared/utils/merge/mergeStrict';
import { tooltipClasses } from '@mui/material';
import { ThemeModeEnum } from 'shared/enums/ThemeModeEnum';

import { MuiButtonComp } from './components/MuiButton.comp';

export const baseThemeOptions: ThemeOptions = {
  themeName: ThemeModeEnum.LIGHT,

  breakpoints,
  spacing: 8,
  typography,
  components: {
    MuiRadio: {
      styleOverrides: {
        root: {
          padding: '0 9px 0 0',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: '0 9px 0 0',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: 10,
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          borderBottomWidth: 1,
          borderBottomStyle: 'solid',
          marginBottom: 15,
        },
      },
    },
    MuiIcon: {
      defaultProps: {
        baseClassName: 'material-icons',
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: 6,
        },
      },
    },
    MuiFormHelperText: {
      defaultProps: {
        variant: 'standard',
      },
      styleOverrides: {
        root: mergeStrict(typography.h6, {
          marginTop: 10,
        }),
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: mergeStrict(typography.h6, {
          marginBottom: 10,
        }),
        asterisk: {
          fontWeight: typography.fontWeightBold,
          position: 'relative',
          top: '-0.2em',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: colors.endeavour,
          textDecorationColor: colors.endeavour,
          cursor: 'pointer',
          textDecorationLine: 'underline',
          textDecorationThickness: '1px',
          textUnderlineOffset: '4px',
          fontWeight: typography.fontWeightBold,
          '&:hover': {
            textDecorationThickness: '3px',
          },
          '&:focus:not(:active)': {
            outline: `2px solid ${colors.cornflowerBlue}`,
            color: colors.cornflowerBlue,
            textDecoration: 'none',
          },
          '&:active': {
            color: colors.ebonyClay,
            outline: 'none',
          },
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        shrink: true,
      },
    },
    MuiFormControl: {
      defaultProps: {
        fullWidth: true,
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        notched: true,
        fullWidth: true,
      },
      styleOverrides: {
        root: {
          '& fieldset': {
            borderRadius: inputBorderRadius,
          },
        },
      },
    },
    MuiButton: MuiButtonComp.base,
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          '&.MuiSvgIcon-fontSizeSmall': {
            fontSize: pxToRem(20),
          },
          '&.MuiSvgIcon-fontSizeMedium': {
            fontSize: pxToRem(30),
          },
          '&.MuiSvgIcon-fontSizeLarge': {
            fontSize: pxToRem(35),
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          margin: `7px !important`,
          padding: `10px 20px`,
          fontSize: pxToRem(14),
        },
      },
    },
    WBTooltip: {
      styleOverrides: {
        root: {
          [`& .${tooltipClasses.arrow}`]: { color: colors.tundora },
          [`& .${tooltipClasses.tooltip}`]: { backgroundColor: colors.tundora },
        },
      },
    },
    WBToggleIconButton: {
      styleOverrides: { root: { borderWidth: '2px', borderStyle: 'solid', borderRadius: '10px' } },
    },
    WBLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: alpha(colors.white, 0.8),
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          position: 'sticky',
          top: 0,
          left: 0,
          zIndex: 1,
        },
      },
    },
  },
};
