import React from 'react';

import { IconButtonProps } from '@mui/material/IconButton/IconButton';

import { Tooltip, WBToggleIconButton } from './Toggle.s';

export type ToggleProps = IconButtonProps & {
  title: string;
  icon: JSX.Element;
  onClick: () => void;
};

export const Toggle: React.VFC<ToggleProps> = ({ title, icon, onClick, ...props }: ToggleProps) => {
  return (
    <Tooltip title={title} placement="left">
      <WBToggleIconButton onClick={onClick} {...props}>
        {icon}
      </WBToggleIconButton>
    </Tooltip>
  );
};
