import { px } from 'util/transformToPixels';

import { TypographyVariantsOptions } from '@mui/material';
import { createBreakpoints } from '@mui/system';

const htmlFontSize = 16;

// Use theme.typography.pxToRem if possible
export const pxToRem = (px: number) => `${px / htmlFontSize}rem`;

// That config will be injected in theme, use theme.breakpoints if possible
export const breakpoints = createBreakpoints({
  values: {
    xs: 0,
    sm: 600,
    md: 902,
    lg: 1200,
    xl: 1536,
  },
});

// https://chir.ag/projects/name-that-color/
export const colors = {
  athensGray: '#FAFBFC',
  alizarinCrimson: '#E7312D',
  alto: '#DBDBDB',
  black: '#000000',
  boulder: '#747474',
  brightRed: '#B00303',
  candyCorn: '#F7F751',
  caper: '#CFE7B3',
  christi36: '#64B20936',
  christi: '#5CA509',
  cornflowerBlue: '#5383EC',
  ebonyClay: '#262D3D',
  endeavour: '#004CA1',
  fernGreen: '#3E7534',
  gallery: '#EBEBEB',
  iron: '#D8D9DD',
  japaneseLaurel: '#1D9508',
  limeade: '#339607',
  malibu: '#6EE8FC',
  mantis: '#95C65E',
  monza: '#DD0606',
  oxfordBlue: '#3A4254',
  parsley: '#275A1E',
  silver: '#C6C6C6',
  tundora: '#414141',
  white: '#FFFFFF',
  woodland: '#316228',
};

export const focusBorderColor = {
  light: colors.cornflowerBlue,
  highContrastAccessible: colors.malibu,
};

export const borderWidth = 3;
export const borderWidthPx = px(borderWidth);

export const boxBorderWidth = 4;
export const boxBorderWidthPx = px(borderWidth);

export const buttonBorderWidth = borderWidth;
export const buttonBorderWidthPx = px(buttonBorderWidth);

export const mobileButtonBorderWidth = 2;
export const mobileButtonBorderWidthPx = px(2);

export const buttonOutlineWidth = 4;
export const buttonOutlineWidthPx = px(buttonOutlineWidth);

export const inputBorderRadius = 10;
export const inputBorderRadiusPx = px(inputBorderRadius);

export const inputBorderWidth = borderWidth;
export const inputBorderWidthPx = px(inputBorderWidth);

export const highContrastInputBorderWidthFocused = 6;
export const highContrastInputBorderWidthFocusedPx = px(highContrastInputBorderWidthFocused);

const typographyFontWeight: TypographyVariantsOptions = {
  fontWeightRegular: 500,
  fontWeightMedium: 600,
  fontWeightBold: 700,
};

// That config will be injected in theme, use theme.typography if possible
export const typography: TypographyVariantsOptions = {
  ...typographyFontWeight,
  htmlFontSize,
  allVariants: {
    lineHeight: 1.4,
  },
  fontFamily: ['Montserrat', '-apple-system', 'BlinkMacSystemFont', 'Arial', 'sans-serif'].join(
    ','
  ),
  h1: {
    fontSize: pxToRem(22),
    fontWeight: typographyFontWeight.fontWeightBold,
    [breakpoints.up('sm')]: {
      fontSize: pxToRem(44.79),
    },
  },
  h2: {
    fontSize: pxToRem(20),
    fontWeight: typographyFontWeight.fontWeightBold,
    [breakpoints.up('sm')]: {
      fontSize: pxToRem(37.32),
    },
  },
  h3: {
    fontSize: pxToRem(19),
    fontWeight: typographyFontWeight.fontWeightBold,
    [breakpoints.up('sm')]: {
      fontSize: pxToRem(31.1),
    },
  },
  h4: {
    fontSize: pxToRem(18),
    fontWeight: typographyFontWeight.fontWeightBold,
    [breakpoints.up('sm')]: {
      fontSize: pxToRem(25.92),
    },
  },
  h5: {
    fontSize: pxToRem(17),
    fontWeight: typographyFontWeight.fontWeightBold,
    [breakpoints.up('sm')]: {
      fontSize: pxToRem(21.6),
    },
  },
  h6: {
    fontSize: pxToRem(16),
    fontWeight: typographyFontWeight.fontWeightBold,
    [breakpoints.up('sm')]: {
      fontSize: pxToRem(18),
    },
  },
  body1: {
    fontSize: pxToRem(16),
    fontWeight: typographyFontWeight.fontWeightRegular,
    lineHeight: 1.5,
    [breakpoints.up('sm')]: {
      fontSize: pxToRem(18),
    },
  },
  body2: {
    fontSize: pxToRem(13),
    fontWeight: typographyFontWeight.fontWeightRegular,
    lineHeight: 1.5,
    [breakpoints.up('sm')]: {
      fontSize: pxToRem(14),
    },
  },
};
