import { Components } from '@mui/material';
import { Theme } from '@mui/material/styles';

import { answerToggleButtonBorderWidth } from './WBAnswerToggleButton.comp';

const light: Components<Theme>['WBAnswerToggleButtonGroup'] = {
  defaultProps: {
    borderWidth: answerToggleButtonBorderWidth.light,
  },
};

const highContrastAccessible: Components<Theme>['WBAnswerToggleButtonGroup'] = {
  defaultProps: {
    borderWidth: answerToggleButtonBorderWidth.highContrastAccessible,
  },
};

export const WBAnswerToggleButtonGroupComp = {
  light,
  highContrastAccessible,
};
