import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useSurveyNavigation } from 'shared/hooks/useSurveyNavigation';
import { useRouteQuery } from 'shared/hooks/useRouteQuery';
import { selectIsPaperDistribution } from 'selector/distributionSelector';
import { useDistributionSlug } from 'shared/hooks/useDistributionSlug';
import { AnswerFormData, FormQuestionKey, SurveyFormData } from 'shared/models/SurveyFormData';
import { surveyApi } from 'container/surveyApi';
import { QUESTION_ID_PREFIX } from 'shared/utils/form/surveyFormUtils';
import { List } from 'immutable';
import { Answer } from 'model/Answer';
import { useIsPreview } from 'shared/hooks/useIsPreview';
import { useSnackbar } from 'notistack';
import { LangNamespaceEnum } from 'shared/enums/LangNamespaceEnum';

const mapFormQuestionKeyToQuestionId = (formQuestionKey: FormQuestionKey) => {
  return parseInt(formQuestionKey.replace(QUESTION_ID_PREFIX, ''), 10);
};

export const mapFormDataToAnswers = (formData: SurveyFormData): List<Answer> => {
  const answersValues = Object.entries(formData.question) as [
    FormQuestionKey,
    AnswerFormData[] | undefined
  ][];
  const result: Answer[] = [];

  for (let [questionKey, answerFormData] of answersValues) {
    const isAnswerForQuestionEmpty = !(answerFormData?.length > 0);
    if (isAnswerForQuestionEmpty) {
      continue;
    }

    const questionId = mapFormQuestionKeyToQuestionId(questionKey);
    const answerListForSingleQuestion = answerFormData.map(
      ({ option, answer }) => new Answer({ question: questionId, option, answer })
    );
    result.push(...answerListForSingleQuestion);
  }

  return List(result);
};

export const useSurveyFormSubmit = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { goToSuccessPage } = useSurveyNavigation();
  const { accessCode, submitId } = useRouteQuery();
  const isPreview = useIsPreview();
  const isPaperDistribution = useSelector(selectIsPaperDistribution);

  const distributionSlug = useDistributionSlug();

  const { t } = useTranslation(LangNamespaceEnum.SURVEY_UI_HOOKS);

  const submitSurvey = async ({
    formData,
    token,
  }: {
    formData: SurveyFormData;
    token?: string;
  }) => {
    if (isPreview || isPaperDistribution) {
      goToSuccessPage();
      return;
    }

    const answers = mapFormDataToAnswers(formData);
    try {
      await surveyApi.submit(distributionSlug, answers, accessCode, submitId, token);
      goToSuccessPage();
    } catch (errors) {
      enqueueSnackbar(t('useSurveyFormSubmit.error'), { variant: 'error' });
    }
  };

  return { submitSurvey };
};

export const useSurveyFormSave = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { goToSaveSuccessPage } = useSurveyNavigation();

  const { accessCode, submitId } = useRouteQuery();
  const isPreview = useIsPreview();
  const isPaperDistribution = useSelector(selectIsPaperDistribution);
  const distributionSlug = useDistributionSlug();

  const { t } = useTranslation(LangNamespaceEnum.SURVEY_UI_HOOKS);

  const saveSurvey = async ({
    continueEmail,
    formData,
    token,
    useBeacon = false,
    redirect = true,
  }: {
    continueEmail?: string;
    formData: SurveyFormData;
    token?: string;
    useBeacon?: boolean;
    redirect?: boolean;
  }) => {
    if (isPreview || isPaperDistribution) {
      redirect && goToSaveSuccessPage();
      return;
    }

    const answers = mapFormDataToAnswers(formData);

    try {
      await surveyApi.save(
        distributionSlug,
        answers,
        accessCode,
        continueEmail,
        useBeacon,
        submitId,
        token
      );
      redirect && goToSaveSuccessPage();
    } catch (errors) {
      enqueueSnackbar(t('useSurveyFormSave.error'), {
        variant: 'error',
      });
    }
  };

  return { saveSurvey };
};
