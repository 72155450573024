import { Middleware } from 'redux';
import { latestReduxActions, wrappedReduxStateSnapshot } from 'shared/constants/datadog';

export const loggingMiddleware: Middleware = (store) => (next) => (action) => {
  try {
    const { type, payload } = action;
    latestReduxActions.push({ type, payload });
    wrappedReduxStateSnapshot.state = store.getState();

    // We track up to 30 redux actions
    if (latestReduxActions.length > 30) {
      latestReduxActions.shift();
    }
  } finally {
    return next(action);
  }
};
