import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { selectSurveyPages } from 'selector/surveySelector';
import { useNullableCurrentPage } from 'shared/hooks/useCurrentPage';
import { NotFoundPage } from 'view/NotFoundPage/NotFoundPage';
import { LangNamespaceEnum } from 'shared/enums/LangNamespaceEnum';

import { SurveyFormProvider } from './SurveyFormProvider';

export const ProtectedSurveyFormProvider: React.VFC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { t } = useTranslation(LangNamespaceEnum.SURVEY_UI_COMPONENTS);
  const currentPage = useNullableCurrentPage();
  const pages = useSelector(selectSurveyPages).toArray();

  if (
    currentPage === null ||
    currentPage === undefined ||
    currentPage <= 0 ||
    currentPage > pages.length
  ) {
    return <NotFoundPage />;
  }

  if (pages.length === 0) {
    return <>{t('ProtectedSurveyFormProvider.noPagesDescription')}</>;
  }

  return <SurveyFormProvider pages={pages}>{children}</SurveyFormProvider>;
};
