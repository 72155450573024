import { AxiosError } from 'axios';
import { getStatusText } from 'http-status-codes';
import { selectErrorCode, selectErrorData } from 'selector/axiosSelector';

import { Exception } from './Exception';

export class HttpException extends Exception {
  static createFromAxiosError(response: AxiosError, field?: string): HttpException {
    return new this(selectErrorData(response, field), selectErrorCode(response));
  }

  protected httpCode?: number;

  constructor(message: string, httpCode?: number) {
    super(message || (httpCode ? getStatusText(httpCode) : 'HTTP Exception'));
    this.httpCode = httpCode;
    Object.setPrototypeOf(this, HttpException.prototype);
  }

  getHttpCode(): number {
    return this.httpCode;
  }
}
