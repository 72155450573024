import React from 'react';

import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import { getLanguageDirection } from 'shared/utils/getLanguageDirection';
import { useLanguage } from 'translations/LanguageProvider';

export type BidirectionalStatusProps = {
  children: JSX.Element | JSX.Element[];
};

export const BidirectionalStatus: React.VFC<BidirectionalStatusProps> = ({ children }) => {
  const { language } = useLanguage();

  const cacheRtl = createCache({
    key: getLanguageDirection(language) === 'rtl' ? 'muirtl' : 'muiltr',
    stylisPlugins: getLanguageDirection(language) === 'rtl' && [prefixer, rtlPlugin],
  });

  return <CacheProvider value={cacheRtl}>{children}</CacheProvider>;
};
