import React from 'react';

import { useThemeContext } from 'shared/hooks/useThemeContext';
import { ThemeContext } from 'context/ThemeContext';
import { ThemeProvider } from '@mui/material';
import { useThemeModeCookie } from 'shared/hooks/useAccessibilityModeCookie';

export const WorkbuzzTheme: React.FC = ({ children }) => {
  const { initialTheme } = useThemeModeCookie();

  const themeContext = useThemeContext(initialTheme);

  return (
    <ThemeContext.Provider value={themeContext}>
      <ThemeProvider theme={themeContext.theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};
