import { ProjectKind } from 'model/ProjectKind';
import { SimpleModel } from 'model/SimpleModel';
import { LangCodeEnum } from 'shared/enums/LangCodeEnum';

export namespace Meta {
  export interface Shape {
    almostFinishedContent: string | null;
    automaticNumbering: boolean;
    closedPageContent: string | null;
    companyBrandColor: string;
    companyLogo: string;
    companyName: string;
    enabledLocales: Array<LangCodeEnum>;
    isNewSurveyUIEnabled: boolean;
    multiLanguageEnabled: boolean;
    preventIndexing: boolean;
    progressBar: boolean;
    projectId: number;
    projectKind: ProjectKind;
    projectName: string;
    saveAndContinue: boolean;
    saveAndContinueContent: string | null;
    surveyCodeQuestionContent: string | null;
    thankYouPageContent: string | null;
    surveyLogoUrl: string;
  }
}

export class Meta extends SimpleModel<Meta.Shape> {
  getMultiLanguageEnabled() {
    return this.get('multiLanguageEnabled');
  }

  getClosedPageContent() {
    return this.get('closedPageContent');
  }

  getCompanyName() {
    return this.get('companyName');
  }

  getCompanyLogo() {
    return this.get('companyLogo');
  }

  getCompanyBrandColor() {
    return this.get('companyBrandColor');
  }

  getEnabledLocales() {
    return this.get('enabledLocales');
  }

  getIsNewSurveyUIEnabled() {
    return this.get('isNewSurveyUIEnabled');
  }

  getProjectId() {
    return this.get('projectId');
  }

  getProjectKind() {
    return this.get('projectKind');
  }

  hasProgressBar() {
    return this.get('progressBar');
  }

  hasAutomaticNumbering() {
    return this.get('automaticNumbering');
  }

  hasPreventIndexing() {
    return this.get('preventIndexing');
  }

  hasSaveAndContinue() {
    return this.get('saveAndContinue');
  }

  getThankYouPageContent() {
    return this.get('thankYouPageContent');
  }

  getSurveyLogoUrl() {
    return this.get('surveyLogoUrl');
  }

  getAlmostFinishedContent() {
    return this.get('almostFinishedContent');
  }

  getSaveAndContinueContent() {
    return this.get('saveAndContinueContent');
  }

  isNewSurveyUIEnabled() {
    return this.get('isNewSurveyUIEnabled');
  }
}
