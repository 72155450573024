import React from 'react';
import { Route, Switch } from 'react-router';

import { SurveyRoute } from 'app/route';
import { NotFoundPage } from 'view/NotFoundPage/NotFoundPage';

import { SurveyFormRoutes } from './SurveyFormRoutes';

export const SurveyPreviewRoutes: React.VFC = () => {
  return (
    <Switch>
      <Route
        path={[
          SurveyRoute.Preview.PAGE_PATTERN,
          SurveyRoute.Preview.SUBMIT_PATTERN,
          SurveyRoute.Preview.SUCCESS_PATTERN,
          SurveyRoute.Preview.SAVE_SUCCESS_PATTERN,
          SurveyRoute.PREVIEW_PATTERN,
        ]}
        exact
      >
        <SurveyFormRoutes />
      </Route>
      <Route component={NotFoundPage} />
    </Switch>
  );
};
