import { categoryMapper } from 'container/categoryMapper';
import { distributionMapper } from 'container/distributionMapper';
import { ProjectMapper } from 'mapper/ProjectMapper';

import { pageMapper } from './pageMapper';
import { projectLayoutMapper } from './projectLayoutMapper';

export const projectMapper = new ProjectMapper(
  pageMapper,
  projectLayoutMapper,
  distributionMapper,
  categoryMapper
);
