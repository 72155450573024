export const chunkArrayToArraysWithNoOrphans = <T>(arr: readonly T[], chunkSize: number): T[][] => {
  const chunks: T[][] = [];
  let i = 0;
  while (i < arr.length) {
    chunks.push(arr.slice(i, i + chunkSize));
    i += chunkSize;
  }
  if (chunks.length > 1 && chunks[chunks.length - 1].length === 1) {
    const lastItem = chunks[chunks.length - 1][0];
    chunks[chunks.length - 1] = [chunks[chunks.length - 2].pop(), lastItem];
  }
  return chunks;
};
