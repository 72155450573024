import { List } from 'immutable';
import { SimpleModel } from 'model/SimpleModel';

import { CustomQuestionOption } from './CustomQuestionOption';
import { DiscreteQuestion } from './DiscreteQuestion';
import { NumberingSkippable } from './NumberingSkippable';
import { PageItemKind } from './PageItemKind';
import { Question } from './Question';

export namespace QuestionDemographic {
  export interface Shape extends Question.Shape {
    options: List<CustomQuestionOption>;
    isGhost: boolean;
    numberingSkipped: boolean;
    isRequired?: boolean;
  }
}

export class QuestionDemographic
  extends Question<QuestionDemographic.Shape>
  implements NumberingSkippable, DiscreteQuestion {
  constructor(initialData: SimpleModel.Data<QuestionDemographic.Shape>) {
    super(initialData, PageItemKind.QuestionDemographic);
  }

  getOptions(): List<CustomQuestionOption> {
    return this.get('options', List<CustomQuestionOption>());
  }

  isGhost(): boolean {
    return this.get('isGhost', false);
  }

  isRequired(): boolean {
    return this.get('isRequired');
  }

  isNumberingSkipped(): boolean {
    return this.get('numberingSkipped');
  }

  getAvailableOptions(): List<CustomQuestionOption> {
    return this.getOptions();
  }
}
