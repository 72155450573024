import { PageItemApi } from 'api/PageItemApi';
import { PageItem } from 'model/PageItem';

export interface PageItemKindMapper<
  M extends PageItem = PageItem,
  E extends PageItemApi.ItemEntry = PageItemApi.ItemEntry
> {
  deserialize(entry: E): M;
  supportsDeserialize(entry: E): boolean;
}

export class PageItemMapper implements PageItemKindMapper<PageItem, PageItemApi.ItemEntryAny> {
  private mappers: PageItemKindMapper[];

  constructor(mappers: PageItemKindMapper[]) {
    this.mappers = mappers;
  }

  deserialize<T extends PageItem>(entry: PageItemApi.ItemEntryAny): T {
    return this.findDeserializer(entry).deserialize(entry) as T;
  }

  supportsDeserialize(entry: PageItemApi.ItemEntry): boolean {
    return this.mappers.some((mapper) => mapper.supportsDeserialize(entry));
  }

  private findDeserializer(entry: PageItemApi.ItemEntry): PageItemKindMapper {
    const deserializer = this.mappers.find((mapper) => mapper.supportsDeserialize(entry));

    if (!deserializer) {
      throw new Error('Cannot find deserializer for page item entry: ' + JSON.stringify(entry));
    }

    return deserializer;
  }
}
