import { SurveyApi } from 'api/SurveyApi';
import { AvailableSurvey, createAvailableSurveyInstance } from 'model/AvailableSurvey';
import moment from 'moment';

export class AvailableSurveysMapper {
  deserialize(survey: SurveyApi.AvailableSurvey): AvailableSurvey.Instance {
    return createAvailableSurveyInstance({
      distributionId: survey.distributionId,
      name: survey.projectName,
      startDate: survey.startDate ? moment(survey.startDate) : null,
    });
  }
}
