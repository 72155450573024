import { PageItemApi } from 'api/PageItemApi';
import { CustomQuestionOption } from 'model/CustomQuestionOption';

export class CustomQuestionOptionMapper {
  deserialize(entry: PageItemApi.CustomQuestionOptionEntry): CustomQuestionOption {
    return new CustomQuestionOption({
      id: entry.id,
      option: entry.option,
      position: entry.position,
    });
  }
}
