import { List } from 'immutable';
import { SimpleModel } from 'model/SimpleModel';

import { DiscreteQuestion } from './DiscreteQuestion';
import { PageItemKind } from './PageItemKind';
import { Question } from './Question';
import { QuestionCASScore } from './QuestionCASScore';
import { StaticQuestionOption } from './StaticQuestionOption';

export namespace QuestionCAS {
  export interface Shape extends Question.Shape {
    isRequired?: boolean;
  }
}

export class QuestionCAS extends Question<QuestionCAS.Shape> implements DiscreteQuestion {
  static readonly SCORES: QuestionCASScore[] = [
    QuestionCASScore.Score1,
    QuestionCASScore.Score2,
    QuestionCASScore.Score3,
    QuestionCASScore.Score4,
    QuestionCASScore.Score5,
  ];

  constructor(initialData: SimpleModel.Data<QuestionCAS.Shape>) {
    super(initialData, PageItemKind.QuestionCAS);
  }

  isRequired(): boolean {
    return this.get('isRequired');
  }

  getAvailableOptions(): List<StaticQuestionOption> {
    return List(
      QuestionCAS.SCORES.map(
        (option) =>
          new StaticQuestionOption({
            option: String(option),
            value: option,
          })
      )
    );
  }
}
