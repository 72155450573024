import { createSelector } from 'reselect';
import { SurveyState } from 'store/SurveyState';

export const selectSnackbars = (state: SurveyState) => (state && state.snackbars) || [];

export const selectNextSnackbarId = createSelector(
  selectSnackbars,
  (snackbars) => snackbars.reduce((max, snackbar) => Math.max(max, snackbar.id), 0) + 1
);

export const selectSnackbar = (id: number) =>
  createSelector(selectSnackbars, (snackbars) => snackbars.find((snackbar) => snackbar.id === id));
