import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { colors } from 'app/theme/config';

export const linkStyles: SxProps<Theme> = {
  '&.anonymous-link': {
    color: colors.tundora,
    textDecorationColor: colors.alto,
    ':hover, :focus': {
      color: colors.tundora,
      textDecorationColor: colors.tundora,
      textDecorationThickness: '2px',
    },
    '&:focus': {
      backgroundColor: colors.gallery,
      outline: 'none',
      textDecoration: 'underline',
      textDecorationThickness: '2px',
    },
  },
};

export const linkStylesHighContrast: SxProps<Theme> = {
  '&.anonymous-link': {
    color: colors.candyCorn,
    textDecorationColor: colors.candyCorn,

    ':hover, :focus': {
      textDecorationThickness: '4px',
    },

    '&:focus': {
      color: colors.black,
      backgroundColor: colors.candyCorn,
      textDecoration: 'none',
      outline: 'none',
    },
  },
};
