import { Box, BoxTypeMap, styled } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

export const FooterContainer = styled('footer')(() => ({
  width: '100%',
}));

export const FixedBottom = styled<OverridableComponent<BoxTypeMap>>(Box)(() => ({
  bottom: 30,
  position: 'fixed',
}));
