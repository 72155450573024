import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import { Box, Grid, Hidden } from '@mui/material';
import { getXsGridItemStyles } from 'view/WelcomePage/WelcomePageHeader/WelcomePageHeader.s';
import { AccessibilityToggle } from 'component/Toggles/Accessibility/AccessibilityToggle';
import { Container } from 'component/Container/Container';
import { LanguageToggle } from 'component/Toggles/Language/LanguageToggle';
import { Actions } from 'component/Header/HeaderPure.s';
import { ThemeContext } from 'context/ThemeContext';
import workBuzzDesktopLogoUrl from 'app/public/workbuzz-logo-colour.svg';
import workBuzzDesktopLogoDarkUrl from 'app/public/workbuzz-logo-dark.svg';
import workBuzzMobileLogoUrl from 'app/public/workbuzz-logo-stacked.svg';
import workBuzzMobileLogoDarkUrl from 'app/public/workbuzz-logo-stacked-dark.svg';
import { colors } from 'app/theme/config';
import { ThemeModeEnum } from 'shared/enums/ThemeModeEnum';
import { selectIsMultiLanguageEnabled } from 'selector/metaSelector';

import { logosWrapperDesktopStyles } from '../WelcomePage.s';

export type WelcomePageHeaderProps = {
  companyName: string;
  barColor: string;
  companyLogoUrl?: string;
  hideLanguageToggle?: boolean;
};

export const WelcomePageHeader: React.VFC<WelcomePageHeaderProps> = ({
  companyName,
  barColor,
  companyLogoUrl,
  hideLanguageToggle,
}) => {
  const { themeMode } = useContext(ThemeContext);
  const workbuzzDesktopLogoComputedUrl =
    themeMode === ThemeModeEnum.HIGH_CONTRAST_ACCESSIBLE
      ? workBuzzDesktopLogoDarkUrl
      : workBuzzDesktopLogoUrl;
  const workbuzzMobileLogoComputedUrl =
    themeMode === ThemeModeEnum.HIGH_CONTRAST_ACCESSIBLE
      ? workBuzzMobileLogoDarkUrl
      : workBuzzMobileLogoUrl;
  const barColorComputed =
    themeMode === ThemeModeEnum.HIGH_CONTRAST_ACCESSIBLE ? colors.white : barColor;

  const isMultiLanguageEnabled = useSelector(selectIsMultiLanguageEnabled);

  const renderLanguageToggle = () =>
    !hideLanguageToggle && isMultiLanguageEnabled && <LanguageToggle />;

  return (
    <Box
      sx={{
        position: 'relative',
        py: { xs: '10px', sm: '35px' },
        borderBottom: `3px solid ${barColorComputed}`,
      }}
      component="header"
      data-testid="welcomeHeader"
    >
      <Hidden lgUp>
        <Container>
          <Grid container>
            <Grid item xs={4} sx={getXsGridItemStyles()}>
              <Box
                data-testid="companyLogoMobile"
                component="img"
                sx={{ width: 75, height: 'auto' }}
                alt={companyLogoUrl ? companyName : 'WorkBuzz'}
                src={companyLogoUrl ? companyLogoUrl : workbuzzMobileLogoComputedUrl}
              />
            </Grid>
            <Grid item xs={4} sx={getXsGridItemStyles({ justifyContent: 'center' })}>
              {companyLogoUrl && (
                <Box
                  data-testid="workbuzzLogoMobile"
                  component="img"
                  sx={{ width: 75, height: 56 }}
                  alt="WorkBuzz"
                  src={workbuzzMobileLogoComputedUrl}
                />
              )}
            </Grid>
            <Grid item xs sx={getXsGridItemStyles({ justifyContent: 'flex-end' })}>
              <Actions>
                {renderLanguageToggle()}
                <AccessibilityToggle data-testid="accessibilityToggleMobile" />
              </Actions>
            </Grid>
          </Grid>
        </Container>
      </Hidden>
      <Hidden lgDown>
        <Container>
          <Box
            sx={{
              position: 'absolute',
              right: '27px',
              top: '50%',
              transform: 'translate(0, -50%)',
            }}
          >
            <Actions>
              {renderLanguageToggle()}
              <AccessibilityToggle data-testid="accessibilityToggleDesktop" />
            </Actions>
          </Box>
          <Box sx={logosWrapperDesktopStyles}>
            <Box
              data-testid="companyLogoDesktop"
              component="img"
              sx={{
                maxWidth: '100%',
                maxHeight: companyLogoUrl ? '100px' : '75px',
                height: companyLogoUrl ? 'auto' : '60px',
              }}
              alt={companyLogoUrl ? companyName : 'WorkBuzz'}
              src={companyLogoUrl ? companyLogoUrl : workbuzzDesktopLogoComputedUrl}
            />
            {companyLogoUrl && (
              <Box
                data-testid="workbuzzLogoDesktop"
                component="img"
                sx={{ maxWidth: '50%', maxHeight: '51px' }}
                alt="WorkBuzz"
                src={workbuzzDesktopLogoComputedUrl}
              />
            )}
          </Box>
        </Container>
      </Hidden>
    </Box>
  );
};
