// application entry file
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider as StoreProvider } from 'react-redux';

import { ConnectedRouter } from 'connected-react-router/immutable';
import 'navigator.sendbeacon';
import { history, store } from 'store/appStore';

import { App } from './App';
import './index.css';

document.addEventListener('DOMContentLoaded', () => {
  const root = document.getElementById('root');

  ReactDOM.render(
    <StoreProvider store={store}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </StoreProvider>,
    root
  );
});
