import * as React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

import { LoaderWrapper } from './Loader.s';

export namespace Loader {
  export type Props = {
    className?: string;
    style?: unknown;
  };
}

export const Loader: React.VFC<Loader.Props> = (props) => (
  <LoaderWrapper data-testid="loader" className={props.className} style={props.style}>
    <CircularProgress />
  </LoaderWrapper>
);
