import { PageItemApi } from 'api/PageItemApi';
import { PageItemKind } from 'model/PageItemKind';
import { QuestionCAS } from 'model/QuestionCAS';

import { PageItemKindMapper } from './PageItemMapper';
import { QuestionAbstractMapper } from './QuestionAbstractMapper';

export class QuestionCASMapper
  implements PageItemKindMapper<QuestionCAS, PageItemApi.QuestionCASEntry> {
  deserialize(entry: PageItemApi.QuestionCASEntry): QuestionCAS {
    return new QuestionCAS({
      ...QuestionAbstractMapper.deserializeShape(entry),
      isRequired: Boolean(entry.required),
    });
  }

  supportsDeserialize(entry: PageItemApi.ItemEntry): boolean {
    return entry.kind === PageItemKind.QuestionCAS;
  }
}
