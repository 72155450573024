import { CategoryApi } from 'api/CategoryApi';
import { Category } from 'model/Category';

export class CategoryMapper {
  deserialize(entry: CategoryApi.Entry): Category {
    return new Category({
      id: entry.id,
      isGlobal: entry.isGlobal,
      name: entry.name,
    });
  }
}
