import { useParams } from 'react-router';

export const useNullableCurrentPage = (): number | undefined | null => {
  const { page } = useParams<{ page?: string }>();

  if (page === undefined) {
    return 1;
  }

  const parsedValue = Number(page);
  return Number.isInteger(parsedValue) ? parsedValue : null;
};

export const useCurrentPage = (): number => {
  const result = useNullableCurrentPage();

  if (result === null) {
    throw new Error('Param "page" must be INTEGER');
  }

  return result;
};
