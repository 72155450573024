import {
  CHECK_AVAILABLE_SURVEYS,
  CheckAvailableSurveysAction,
  CLEAR_ERROR,
  FETCH_ACCESS_CODE_INFORMATION,
  FETCH_META,
  FETCH_SURVEY,
  REMOVE_SUBMITTED_SURVEY,
  SAVE_ERROR,
  SaveError,
  UNSUBSCRIBE,
} from 'action/surveyAction';
import { Action, handleAction } from 'redux-actions';
import { SurveyState } from 'store/SurveyState';
import { handleResolved } from 'reducer/asyncReducer';

import { combineReducers } from './combineReducers';
import { modalReducer } from './modalReducer';
import { reduceReducers } from './reduceReducers';
import { createResourceFetchReducer } from './resourceReducer';
import { snackbarReducer } from './snackbarReducer';
import { submitReducer } from './submitReducer';

export const fetchMetaReducer = handleAction(
  FETCH_META,
  createResourceFetchReducer(),
  SurveyState.INITIAL_META_RESOURCE
);

export const fetchSurveyReducer = handleAction(
  FETCH_SURVEY,
  createResourceFetchReducer(),
  SurveyState.INITIAL_SURVEY_RESOURCE
);

export const fetchAccessCodeInformation = handleAction(
  FETCH_ACCESS_CODE_INFORMATION,
  createResourceFetchReducer(),
  SurveyState.INITIAL_ACCESS_CODE_INFORMATION_RESOURCE
);

export const unsubscribeAccessCode = handleAction(
  UNSUBSCRIBE,
  createResourceFetchReducer(),
  SurveyState.INITIAL_ACCESS_CODE_INFORMATION_RESOURCE
);

export const saveErrorReducer = handleAction(
  SAVE_ERROR,
  (_, action: SaveError) => action.payload,
  SurveyState.INITIAL_ERROR
);

export const clearErrorReducer = handleAction(
  CLEAR_ERROR,
  () => SurveyState.INITIAL_ERROR,
  SurveyState.INITIAL_ERROR
);

export const checkAvailableSurveysReducer = handleAction<
  SurveyState['availableSurveys'],
  CheckAvailableSurveysAction['payload']
>(
  CHECK_AVAILABLE_SURVEYS,
  createResourceFetchReducer<
    SurveyState['availableSurveys'],
    CheckAvailableSurveysAction['payload']
  >(),
  SurveyState.INITIAL_AVAILABLE_SURVEYS
);

export const removeSubmittedSurveyReducer = handleResolved<
  SurveyState['availableSurveys'],
  string // distributionId
>(
  REMOVE_SUBMITTED_SURVEY,
  (state, action: Action<string>) => {
    if (!state || !state.isResolved()) {
      return SurveyState.INITIAL_AVAILABLE_SURVEYS;
    }

    const submittedSurveyDistributionId = action.payload;

    return state.updateContent((surveys) =>
      surveys.filter((survey) => survey.distributionId !== submittedSurveyDistributionId)
    );
  },
  SurveyState.INITIAL_AVAILABLE_SURVEYS
);

export const surveyReducer = reduceReducers<SurveyState>(
  modalReducer,
  snackbarReducer,
  submitReducer,
  combineReducers(
    {
      metaResource: fetchMetaReducer,
      surveyResource: fetchSurveyReducer,
      accessCode: reduceReducers(fetchAccessCodeInformation, unsubscribeAccessCode),
      error: reduceReducers(saveErrorReducer, clearErrorReducer),
      availableSurveys: reduceReducers<SurveyState['availableSurveys']>(
        checkAvailableSurveysReducer,
        removeSubmittedSurveyReducer
      ),
    },
    SurveyState.INITIAL
  )
);
