import { Box, styled, iconClasses } from '@mui/material';
import { colors } from 'app/theme/config';
import { common } from '@mui/material/colors';
import { ThemeModeEnum } from 'shared/enums/ThemeModeEnum';

export const IconWrapper = styled(Box)(({ theme }) => ({
  width: '60px',
  height: '60px',
  borderRadius: '50%',
  backgroundColor:
    theme.themeName === ThemeModeEnum.HIGH_CONTRAST_ACCESSIBLE ? colors.white : colors.boulder,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: '20px',
  color: common.white,

  '.MuiIcon-root': {
    color: theme.themeName === ThemeModeEnum.HIGH_CONTRAST_ACCESSIBLE ? colors.black : colors.white,
  },

  [theme.breakpoints.up('sm')]: {
    width: '70px',
    height: '70px',
    [`& > .${iconClasses.root}`]: {
      fontSize: '28px',
    },
  },
}));
