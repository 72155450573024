import { initReactI18next } from 'react-i18next';

import { getAppConfig } from 'app/getConfig';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import resourcesToBackend from 'i18next-resources-to-backend';
import { getInitialLanguage } from 'translations/getInitialLanguage';
import { LangCodeEnum } from 'shared/enums/LangCodeEnum';
import { LangNamespaceEnum } from 'shared/enums/LangNamespaceEnum';

import AFRIKAANS_SHARED from './af_ZA/shared.json';
import AFRIKAANS_SURVEY_UI_COMMON from './af_ZA/surveyUICommon.json';
import ARABIC_UAE_SHARED from './ar_AE/shared.json';
import ARABIC_UAE_SURVEY_UI_COMMON from './ar_AE/surveyUICommon.json';
import BELGIAN_SHARED from './nl_BE/shared.json';
import BELGIAN_SURVEY_UI_COMMON from './nl_BE/surveyUICommon.json';
import BENGALI_SHARED from './bn_IN/shared.json';
import BENGALI_SURVEY_UI_COMMON from './bn_IN/surveyUICommon.json';
import BOSNIAN_SHARED from './bs_BA/shared.json';
import BOSNIAN_SURVEY_UI_COMMON from './bs_BA/surveyUICommon.json';
import BULGARIAN_SHARED from './bg_BG/shared.json';
import BULGARIAN_SURVEY_UI_COMMON from './bg_BG/surveyUICommon.json';
import BURMESE_SHARED from './my_MY/shared.json';
import BURMESE_SURVEY_UI_COMMON from './my_MY/surveyUICommon.json';
import CHINESE_DEFAULT_SURVEY_UI_COMPONENTS from './zh_CN/surveyUIComponents.json';
import CHINESE_DEFAULT_SURVEY_UI_HOOKS from './zh_CN/surveyUIHooks.json';
import CHINESE_DEFAULT_SURVEY_UI_PAGES from './zh_CN/surveyUIPages.json';
import CHINESE_DEFAULT_SURVEY_UI_STORIES from './zh_CN/surveyUIStories.json';
import CHINESE_SIMPLIFIED_SHARED from './zh_CN/shared.json';
import CHINESE_SIMPLIFIED_SURVEY_UI_COMMON from './zh_CN/surveyUICommon.json';
import CHINESE_TRADITIONAL_SHARED from './zh_TW/shared.json';
import CHINESE_TRADITIONAL_SURVEY_UI_COMMON from './zh_TW/surveyUICommon.json';
import CZECH_SHARED from './cs_CZ/shared.json';
import CZECH_SURVEY_UI_COMMON from './cs_CZ/surveyUICommon.json';
import DANISH_SHARED from './da_DK/shared.json';
import DANISH_SURVEY_UI_COMMON from './da_DK/surveyUICommon.json';
import DUTCH_SHARED from './nl_NL/shared.json';
import DUTCH_SURVEY_UI_COMMON from './nl_NL/surveyUICommon.json';
import ENGLISH_AMERICAN_SHARED from './en_US/shared.json';
import ENGLISH_AMERICAN_SURVEY_UI_COMMON from './en_US/surveyUICommon.json';
import ENGLISH_BRITISH_SHARED from './en_GB/shared.json';
import ENGLISH_BRITISH_SURVEY_UI_COMMON from './en_GB/surveyUICommon.json';
import ENGLISH_DEFAULT_SURVEY_UI_COMPONENTS from './en_GB/surveyUIComponents.json';
import ENGLISH_DEFAULT_SURVEY_UI_HOOKS from './en_GB/surveyUIHooks.json';
import ENGLISH_DEFAULT_SURVEY_UI_PAGES from './en_GB/surveyUIPages.json';
import ENGLISH_DEFAULT_SURVEY_UI_STORIES from './en_GB/surveyUIStories.json';
import FRENCH_SHARED from './fr_FR/shared.json';
import FRENCH_SURVEY_UI_COMMON from './fr_FR/surveyUICommon.json';
import FILIPINO_SHARED from './fil_PH/shared.json';
import FILIPINO_SURVEY_UI_COMMON from './fil_PH/surveyUICommon.json';
import JAPANESE_SHARED from './ja_JP/shared.json';
import JAPANESE_SURVEY_UI_COMMON from './ja_JP/surveyUICommon.json';
import GERMAN_SHARED from './de_DE/shared.json';
import GERMAN_SURVEY_UI_COMMON from './de_DE/surveyUICommon.json';
import GUJARATI_SHARED from './gu_IN/shared.json';
import GUJARATI_SURVEY_UI_COMMON from './gu_IN/surveyUICommon.json';
import HINDI_SHARED from './hi_IN/shared.json';
import HINDI_SURVEY_UI_COMMON from './hi_IN/surveyUICommon.json';
import HUNGARIAN_SHARED from './hu_HU/shared.json';
import HUNGARIAN_SURVEY_UI_COMMON from './hu_HU/surveyUICommon.json';
import INDONESIAN_SHARED from './id_ID/shared.json';
import INDONESIAN_SURVEY_UI_COMMON from './id_ID/surveyUICommon.json';
import IRISH_SHARED from './ga_IE/shared.json';
import IRISH_SURVEY_UI_COMMON from './ga_IE/surveyUICommon.json';
import ITALIAN_SHARED from './it_IT/shared.json';
import ITALIAN_SURVEY_UI_COMMON from './it_IT/surveyUICommon.json';
import KOREAN_SHARED from './ko_KR/shared.json';
import KOREAN_SURVEY_UI_COMMON from './ko_KR/surveyUICommon.json';
import LATVIAN_SHARED from './lv_LV/shared.json';
import LATVIAN_SURVEY_UI_COMMON from './lv_LV/surveyUICommon.json';
import LITHUANIAN_SHARED from './lt_LT/shared.json';
import LITHUANIAN_SURVEY_UI_COMMON from './lt_LT/surveyUICommon.json';
import MALAGASY_SHARED from './mg_MG/shared.json';
import MALAGASY_SURVEY_UI_COMMON from './mg_MG/surveyUICommon.json';
import NORWEGIAN_SHARED from './no_NO/shared.json';
import NORWEGIAN_SURVEY_UI_COMMON from './no_NO/surveyUICommon.json';
import PERSIAN_SHARED from './fa_FA/shared.json';
import PERSIAN_SURVEY_UI_COMMON from './fa_FA/surveyUICommon.json';
import POLISH_SHARED from './pl_PL/shared.json';
import POLISH_SURVEY_UI_COMMON from './pl_PL/surveyUICommon.json';
import PORTUGUESE_BRAZILIAN_SHARED from './pt_BR/shared.json';
import PORTUGUESE_BRAZILIAN_SURVEY_UI_COMMON from './pt_BR/surveyUICommon.json';
import PORTUGUESE_DEFAULT_SURVEY_UI_COMPONENTS from './pt_PT/surveyUIComponents.json';
import PORTUGUESE_DEFAULT_SURVEY_UI_HOOKS from './pt_PT/surveyUIHooks.json';
import PORTUGUESE_DEFAULT_SURVEY_UI_PAGES from './pt_PT/surveyUIPages.json';
import PORTUGUESE_DEFAULT_SURVEY_UI_STORIES from './pt_PT/surveyUIStories.json';
import PORTUGUESE_SHARED from './pt_PT/shared.json';
import PORTUGUESE_SURVEY_UI_COMMON from './pt_PT/surveyUICommon.json';
import PUNJABI_INDIAN_SHARED from './pa_IN/shared.json';
import PUNJABI_INDIAN_SURVEY_UI_COMMON from './pa_IN/surveyUICommon.json';
import ROMANIAN_SHARED from './ro_RO/shared.json';
import ROMANIAN_SURVEY_UI_COMMON from './ro_RO/surveyUICommon.json';
import RUSSIAN_SHARED from './ru_RU/shared.json';
import RUSSIAN_SURVEY_UI_COMMON from './ru_RU/surveyUICommon.json';
import SLOVAKIAN_SHARED from './sk_SK/shared.json';
import SLOVAKIAN_SURVEY_UI_COMMON from './sk_SK/surveyUICommon.json';
import SLOVENIAN_SHARED from './sl_SI/shared.json';
import SLOVENIAN_SURVEY_UI_COMMON from './sl_SI/surveyUICommon.json';
import SWAHILI_SHARED from './sw_KE/shared.json';
import SWAHILI_SURVEY_UI_COMMON from './sw_KE/surveyUICommon.json';
import SPANISH_DEFAULT_SURVEY_UI_COMPONENTS from './es_MX/surveyUIComponents.json';
import SPANISH_DEFAULT_SURVEY_UI_HOOKS from './es_MX/surveyUIHooks.json';
import SPANISH_DEFAULT_SURVEY_UI_PAGES from './es_MX/surveyUIPages.json';
import SPANISH_DEFAULT_SURVEY_UI_STORIES from './es_MX/surveyUIStories.json';
import SPANISH_MEXICAN_SHARED from './es_MX/shared.json';
import SPANISH_MEXICAN_SURVEY_UI_COMMON from './es_MX/surveyUICommon.json';
import SPANISH_SHARED from './es_ES/shared.json';
import SPANISH_SURVEY_UI_COMMON from './es_ES/surveyUICommon.json';
import SWEDISH_SHARED from './sv_SE/shared.json';
import SWEDISH_SURVEY_UI_COMMON from './sv_SE/surveyUICommon.json';
import TAMIL_SHARED from './ta_IN/shared.json';
import TAMIL_SURVEY_UI_COMMON from './ta_IN/surveyUICommon.json';
import TSONGA_SHARED from './ts_TS/shared.json';
import TSONGA_SURVEY_UI_COMMON from './ts_TS/surveyUICommon.json';
import TURKISH_SHARED from './tr_TR/shared.json';
import TURKISH_SURVEY_UI_COMMON from './tr_TR/surveyUICommon.json';
import UKRAINIAN_SHARED from './uk_UA/shared.json';
import UKRAINIAN_SURVEY_UI_COMMON from './uk_UA/surveyUICommon.json';
import URDU_PAKISTAN_SHARED from './ur_PK/shared.json';
import URDU_PAKISTAN_SURVEY_UI_COMMON from './ur_PK/surveyUICommon.json';
import VIETNAMESE_SHARED from './vi_VN/shared.json';
import VIETNAMESE_SURVEY_UI_COMMON from './vi_VN/surveyUICommon.json';
import WELSH_SHARED from './cy_GB/shared.json';
import WELSH_SURVEY_UI_COMMON from './cy_GB/surveyUICommon.json';
import XHOSA_SHARED from './xh_XH/shared.json';
import XHOSA_SURVEY_UI_COMMON from './xh_XH/surveyUICommon.json';
import CROATIAN_SHARED from './hr_HR/shared.json';
import CROATIAN_SURVEY_UI_COMMON from './hr_HR/surveyUICommon.json';
import FINNISH_SHARED from './fi_FI/shared.json';
import FINNISH_SURVEY_UI_COMMON from './fi_FI/surveyUICommon.json';
import SERBIAN_SHARED from './sr_RS/shared.json';
import SERBIAN_SURVEY_UI_COMMON from './sr_RS/surveyUICommon.json';
import TETUN_SHARED from './tet_TET/shared.json';
import TETUN_SURVEY_UI_COMMON from './tet_TET/surveyUICommon.json';

const isStorybook = getAppConfig().STORYBOOK === 'true';

// ! TODO: WB3-695 - This needs to be initialized when multi language is enabled (Does this matter now there's a fallback to send language as 'en_GB'?)
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(
    resourcesToBackend(
      (language: LangCodeEnum, namespace: string) => import(`./${language}/${namespace}.json`)
    )
  )
  .init({
    react: { useSuspense: !isStorybook },
    partialBundledLanguages: true,
    debug: getAppConfig().NODE_ENV === 'development',
    returnEmptyString: false,
    ns: [
      LangNamespaceEnum.SHARED,
      LangNamespaceEnum.SURVEY_UI_COMMON,
      LangNamespaceEnum.SURVEY_UI_COMPONENTS,
      LangNamespaceEnum.SURVEY_UI_PAGES,
      LangNamespaceEnum.SURVEY_UI_HOOKS,
    ],
    lng: getInitialLanguage(),
    resources: {
      // All fallback languages have to be initially loaded
      [LangCodeEnum.AFRIKAANS]: {
        [LangNamespaceEnum.SHARED]: AFRIKAANS_SHARED,
        [LangNamespaceEnum.SURVEY_UI_COMMON]: AFRIKAANS_SURVEY_UI_COMMON,
      },
      [LangCodeEnum.ARABIC_UAE]: {
        [LangNamespaceEnum.SHARED]: ARABIC_UAE_SHARED,
        [LangNamespaceEnum.SURVEY_UI_COMMON]: ARABIC_UAE_SURVEY_UI_COMMON,
      },
      [LangCodeEnum.BELGIAN]: {
        [LangNamespaceEnum.SHARED]: BELGIAN_SHARED,
        [LangNamespaceEnum.SURVEY_UI_COMMON]: BELGIAN_SURVEY_UI_COMMON,
      },
      [LangCodeEnum.BENGALI]: {
        [LangNamespaceEnum.SHARED]: BENGALI_SHARED,
        [LangNamespaceEnum.SURVEY_UI_COMMON]: BENGALI_SURVEY_UI_COMMON,
      },
      [LangCodeEnum.BOSNIAN]: {
        [LangNamespaceEnum.SHARED]: BOSNIAN_SHARED,
        [LangNamespaceEnum.SURVEY_UI_COMMON]: BOSNIAN_SURVEY_UI_COMMON,
      },
      [LangCodeEnum.BULGARIAN]: {
        [LangNamespaceEnum.SHARED]: BULGARIAN_SHARED,
        [LangNamespaceEnum.SURVEY_UI_COMMON]: BULGARIAN_SURVEY_UI_COMMON,
      },
      [LangCodeEnum.BURMESE]: {
        [LangNamespaceEnum.SHARED]: BURMESE_SHARED,
        [LangNamespaceEnum.SURVEY_UI_COMMON]: BURMESE_SURVEY_UI_COMMON,
      },
      [LangCodeEnum.CHINESE_SIMPLIFIED]: {
        [LangNamespaceEnum.SHARED]: CHINESE_SIMPLIFIED_SHARED,
        [LangNamespaceEnum.SURVEY_UI_COMMON]: CHINESE_SIMPLIFIED_SURVEY_UI_COMMON,
      },
      [LangCodeEnum.CHINESE_TRADITIONAL]: {
        [LangNamespaceEnum.SHARED]: CHINESE_TRADITIONAL_SHARED,
        [LangNamespaceEnum.SURVEY_UI_COMMON]: CHINESE_TRADITIONAL_SURVEY_UI_COMMON,
      },
      [LangCodeEnum.CROATIAN]: {
        [LangNamespaceEnum.SHARED]: CROATIAN_SHARED,
        [LangNamespaceEnum.SURVEY_UI_COMMON]: CROATIAN_SURVEY_UI_COMMON,
      },
      [LangCodeEnum.CZECH]: {
        [LangNamespaceEnum.SHARED]: CZECH_SHARED,
        [LangNamespaceEnum.SURVEY_UI_COMMON]: CZECH_SURVEY_UI_COMMON,
      },
      [LangCodeEnum.DANISH]: {
        [LangNamespaceEnum.SHARED]: DANISH_SHARED,
        [LangNamespaceEnum.SURVEY_UI_COMMON]: DANISH_SURVEY_UI_COMMON,
      },
      [LangCodeEnum.DUTCH]: {
        [LangNamespaceEnum.SHARED]: DUTCH_SHARED,
        [LangNamespaceEnum.SURVEY_UI_COMMON]: DUTCH_SURVEY_UI_COMMON,
      },
      [LangCodeEnum.ENGLISH_AMERICAN]: {
        [LangNamespaceEnum.SHARED]: ENGLISH_AMERICAN_SHARED,
        [LangNamespaceEnum.SURVEY_UI_COMMON]: ENGLISH_AMERICAN_SURVEY_UI_COMMON,
      },
      [LangCodeEnum.ENGLISH_BRITISH]: {
        [LangNamespaceEnum.SHARED]: ENGLISH_BRITISH_SHARED,
        [LangNamespaceEnum.SURVEY_UI_COMMON]: ENGLISH_BRITISH_SURVEY_UI_COMMON,
      },
      [LangCodeEnum.FRENCH]: {
        [LangNamespaceEnum.SHARED]: FRENCH_SHARED,
        [LangNamespaceEnum.SURVEY_UI_COMMON]: FRENCH_SURVEY_UI_COMMON,
      },
      [LangCodeEnum.FILIPINO]: {
        [LangNamespaceEnum.SHARED]: FILIPINO_SHARED,
        [LangNamespaceEnum.SURVEY_UI_COMMON]: FILIPINO_SURVEY_UI_COMMON,
      },
      [LangCodeEnum.FINNISH]: {
        [LangNamespaceEnum.SHARED]: FINNISH_SHARED,
        [LangNamespaceEnum.SURVEY_UI_COMMON]: FINNISH_SURVEY_UI_COMMON,
      },
      [LangCodeEnum.JAPANESE]: {
        [LangNamespaceEnum.SHARED]: JAPANESE_SHARED,
        [LangNamespaceEnum.SURVEY_UI_COMMON]: JAPANESE_SURVEY_UI_COMMON,
      },
      [LangCodeEnum.GERMAN]: {
        [LangNamespaceEnum.SHARED]: GERMAN_SHARED,
        [LangNamespaceEnum.SURVEY_UI_COMMON]: GERMAN_SURVEY_UI_COMMON,
      },
      [LangCodeEnum.GUJARATI]: {
        [LangNamespaceEnum.SHARED]: GUJARATI_SHARED,
        [LangNamespaceEnum.SURVEY_UI_COMMON]: GUJARATI_SURVEY_UI_COMMON,
      },
      [LangCodeEnum.HINDI]: {
        [LangNamespaceEnum.SHARED]: HINDI_SHARED,
        [LangNamespaceEnum.SURVEY_UI_COMMON]: HINDI_SURVEY_UI_COMMON,
      },
      [LangCodeEnum.HUNGARIAN]: {
        [LangNamespaceEnum.SHARED]: HUNGARIAN_SHARED,
        [LangNamespaceEnum.SURVEY_UI_COMMON]: HUNGARIAN_SURVEY_UI_COMMON,
      },
      [LangCodeEnum.INDONESIAN]: {
        [LangNamespaceEnum.SHARED]: INDONESIAN_SHARED,
        [LangNamespaceEnum.SURVEY_UI_COMMON]: INDONESIAN_SURVEY_UI_COMMON,
      },
      [LangCodeEnum.IRISH]: {
        [LangNamespaceEnum.SHARED]: IRISH_SHARED,
        [LangNamespaceEnum.SURVEY_UI_COMMON]: IRISH_SURVEY_UI_COMMON,
      },
      [LangCodeEnum.ITALIAN]: {
        [LangNamespaceEnum.SHARED]: ITALIAN_SHARED,
        [LangNamespaceEnum.SURVEY_UI_COMMON]: ITALIAN_SURVEY_UI_COMMON,
      },
      [LangCodeEnum.KOREAN]: {
        [LangNamespaceEnum.SHARED]: KOREAN_SHARED,
        [LangNamespaceEnum.SURVEY_UI_COMMON]: KOREAN_SURVEY_UI_COMMON,
      },
      [LangCodeEnum.LATVIAN]: {
        [LangNamespaceEnum.SHARED]: LATVIAN_SHARED,
        [LangNamespaceEnum.SURVEY_UI_COMMON]: LATVIAN_SURVEY_UI_COMMON,
      },
      [LangCodeEnum.LITHUANIAN]: {
        [LangNamespaceEnum.SHARED]: LITHUANIAN_SHARED,
        [LangNamespaceEnum.SURVEY_UI_COMMON]: LITHUANIAN_SURVEY_UI_COMMON,
      },
      [LangCodeEnum.MALAGASY]: {
        [LangNamespaceEnum.SHARED]: MALAGASY_SHARED,
        [LangNamespaceEnum.SURVEY_UI_COMMON]: MALAGASY_SURVEY_UI_COMMON,
      },
      [LangCodeEnum.NORWEGIAN]: {
        [LangNamespaceEnum.SHARED]: NORWEGIAN_SHARED,
        [LangNamespaceEnum.SURVEY_UI_COMMON]: NORWEGIAN_SURVEY_UI_COMMON,
      },
      [LangCodeEnum.PERSIAN]: {
        [LangNamespaceEnum.SHARED]: PERSIAN_SHARED,
        [LangNamespaceEnum.SURVEY_UI_COMMON]: PERSIAN_SURVEY_UI_COMMON,
      },
      [LangCodeEnum.POLISH]: {
        [LangNamespaceEnum.SHARED]: POLISH_SHARED,
        [LangNamespaceEnum.SURVEY_UI_COMMON]: POLISH_SURVEY_UI_COMMON,
      },
      [LangCodeEnum.PORTUGUESE]: {
        [LangNamespaceEnum.SHARED]: PORTUGUESE_SHARED,
        [LangNamespaceEnum.SURVEY_UI_COMMON]: PORTUGUESE_SURVEY_UI_COMMON,
      },
      [LangCodeEnum.PORTUGUESE_BRAZILIAN]: {
        [LangNamespaceEnum.SHARED]: PORTUGUESE_BRAZILIAN_SHARED,
        [LangNamespaceEnum.SURVEY_UI_COMMON]: PORTUGUESE_BRAZILIAN_SURVEY_UI_COMMON,
      },
      [LangCodeEnum.PUNJABI_INDIAN]: {
        [LangNamespaceEnum.SHARED]: PUNJABI_INDIAN_SHARED,
        [LangNamespaceEnum.SURVEY_UI_COMMON]: PUNJABI_INDIAN_SURVEY_UI_COMMON,
      },
      [LangCodeEnum.ROMANIAN]: {
        [LangNamespaceEnum.SHARED]: ROMANIAN_SHARED,
        [LangNamespaceEnum.SURVEY_UI_COMMON]: ROMANIAN_SURVEY_UI_COMMON,
      },
      [LangCodeEnum.RUSSIAN]: {
        [LangNamespaceEnum.SHARED]: RUSSIAN_SHARED,
        [LangNamespaceEnum.SURVEY_UI_COMMON]: RUSSIAN_SURVEY_UI_COMMON,
      },
      [LangCodeEnum.SERBIAN]: {
        [LangNamespaceEnum.SHARED]: SERBIAN_SHARED,
        [LangNamespaceEnum.SURVEY_UI_COMMON]: SERBIAN_SURVEY_UI_COMMON,
      },
      [LangCodeEnum.SLOVAKIAN]: {
        [LangNamespaceEnum.SHARED]: SLOVAKIAN_SHARED,
        [LangNamespaceEnum.SURVEY_UI_COMMON]: SLOVAKIAN_SURVEY_UI_COMMON,
      },
      [LangCodeEnum.SLOVENIAN]: {
        [LangNamespaceEnum.SHARED]: SLOVENIAN_SHARED,
        [LangNamespaceEnum.SURVEY_UI_COMMON]: SLOVENIAN_SURVEY_UI_COMMON,
      },
      [LangCodeEnum.SPANISH]: {
        [LangNamespaceEnum.SHARED]: SPANISH_SHARED,
        [LangNamespaceEnum.SURVEY_UI_COMMON]: SPANISH_SURVEY_UI_COMMON,
      },
      [LangCodeEnum.SPANISH_MEXICAN]: {
        [LangNamespaceEnum.SHARED]: SPANISH_MEXICAN_SHARED,
        [LangNamespaceEnum.SURVEY_UI_COMMON]: SPANISH_MEXICAN_SURVEY_UI_COMMON,
      },
      [LangCodeEnum.SWAHILI]: {
        [LangNamespaceEnum.SHARED]: SWAHILI_SHARED,
        [LangNamespaceEnum.SURVEY_UI_COMMON]: SWAHILI_SURVEY_UI_COMMON,
      },
      [LangCodeEnum.SWEDISH]: {
        [LangNamespaceEnum.SHARED]: SWEDISH_SHARED,
        [LangNamespaceEnum.SURVEY_UI_COMMON]: SWEDISH_SURVEY_UI_COMMON,
      },
      [LangCodeEnum.TAMIL]: {
        [LangNamespaceEnum.SHARED]: TAMIL_SHARED,
        [LangNamespaceEnum.SURVEY_UI_COMMON]: TAMIL_SURVEY_UI_COMMON,
      },
      [LangCodeEnum.TETUN]: {
        [LangNamespaceEnum.SHARED]: TETUN_SHARED,
        [LangNamespaceEnum.SURVEY_UI_COMMON]: TETUN_SURVEY_UI_COMMON,
      },
      [LangCodeEnum.TSONGA]: {
        [LangNamespaceEnum.SHARED]: TSONGA_SHARED,
        [LangNamespaceEnum.SURVEY_UI_COMMON]: TSONGA_SURVEY_UI_COMMON,
      },
      [LangCodeEnum.TURKISH]: {
        [LangNamespaceEnum.SHARED]: TURKISH_SHARED,
        [LangNamespaceEnum.SURVEY_UI_COMMON]: TURKISH_SURVEY_UI_COMMON,
      },
      [LangCodeEnum.UKRAINIAN]: {
        [LangNamespaceEnum.SHARED]: UKRAINIAN_SHARED,
        [LangNamespaceEnum.SURVEY_UI_COMMON]: UKRAINIAN_SURVEY_UI_COMMON,
      },
      [LangCodeEnum.URDU_PAKISTAN]: {
        [LangNamespaceEnum.SHARED]: URDU_PAKISTAN_SHARED,
        [LangNamespaceEnum.SURVEY_UI_COMMON]: URDU_PAKISTAN_SURVEY_UI_COMMON,
      },
      [LangCodeEnum.VIETNAMESE]: {
        [LangNamespaceEnum.SHARED]: VIETNAMESE_SHARED,
        [LangNamespaceEnum.SURVEY_UI_COMMON]: VIETNAMESE_SURVEY_UI_COMMON,
      },
      [LangCodeEnum.WELSH]: {
        [LangNamespaceEnum.SHARED]: WELSH_SHARED,
        [LangNamespaceEnum.SURVEY_UI_COMMON]: WELSH_SURVEY_UI_COMMON,
      },
      [LangCodeEnum.XHOSA]: {
        [LangNamespaceEnum.SHARED]: XHOSA_SHARED,
        [LangNamespaceEnum.SURVEY_UI_COMMON]: XHOSA_SURVEY_UI_COMMON,
      },

      [LangCodeEnum.ENGLISH_DEFAULT]: {
        [LangNamespaceEnum.SHARED]: ENGLISH_BRITISH_SHARED,
        [LangNamespaceEnum.SURVEY_UI_COMMON]: ENGLISH_BRITISH_SURVEY_UI_COMMON,
        [LangNamespaceEnum.SURVEY_UI_COMPONENTS]: ENGLISH_DEFAULT_SURVEY_UI_COMPONENTS,
        [LangNamespaceEnum.SURVEY_UI_PAGES]: ENGLISH_DEFAULT_SURVEY_UI_PAGES,
        [LangNamespaceEnum.SURVEY_UI_STORIES]: ENGLISH_DEFAULT_SURVEY_UI_STORIES,
        [LangNamespaceEnum.SURVEY_UI_HOOKS]: ENGLISH_DEFAULT_SURVEY_UI_HOOKS,
      },
      [LangCodeEnum.PORTUGUESE_DEFAULT]: {
        [LangNamespaceEnum.SHARED]: PORTUGUESE_SHARED,
        [LangNamespaceEnum.SURVEY_UI_COMMON]: PORTUGUESE_SURVEY_UI_COMMON,
        [LangNamespaceEnum.SURVEY_UI_COMPONENTS]: PORTUGUESE_DEFAULT_SURVEY_UI_COMPONENTS,
        [LangNamespaceEnum.SURVEY_UI_PAGES]: PORTUGUESE_DEFAULT_SURVEY_UI_PAGES,
        [LangNamespaceEnum.SURVEY_UI_STORIES]: PORTUGUESE_DEFAULT_SURVEY_UI_STORIES,
        [LangNamespaceEnum.SURVEY_UI_HOOKS]: PORTUGUESE_DEFAULT_SURVEY_UI_HOOKS,
      },
      [LangCodeEnum.CHINESE_DEFAULT]: {
        [LangNamespaceEnum.SHARED]: CHINESE_SIMPLIFIED_SHARED,
        [LangNamespaceEnum.SURVEY_UI_COMMON]: CHINESE_SIMPLIFIED_SURVEY_UI_COMMON,
        [LangNamespaceEnum.SURVEY_UI_COMPONENTS]: CHINESE_DEFAULT_SURVEY_UI_COMPONENTS,
        [LangNamespaceEnum.SURVEY_UI_PAGES]: CHINESE_DEFAULT_SURVEY_UI_PAGES,
        [LangNamespaceEnum.SURVEY_UI_STORIES]: CHINESE_DEFAULT_SURVEY_UI_STORIES,
        [LangNamespaceEnum.SURVEY_UI_HOOKS]: CHINESE_DEFAULT_SURVEY_UI_HOOKS,
      },
      [LangCodeEnum.SPANISH_DEFAULT]: {
        [LangNamespaceEnum.SHARED]: SPANISH_MEXICAN_SHARED,
        [LangNamespaceEnum.SURVEY_UI_COMMON]: SPANISH_MEXICAN_SURVEY_UI_COMMON,
        [LangNamespaceEnum.SURVEY_UI_COMPONENTS]: SPANISH_DEFAULT_SURVEY_UI_COMPONENTS,
        [LangNamespaceEnum.SURVEY_UI_PAGES]: SPANISH_DEFAULT_SURVEY_UI_PAGES,
        [LangNamespaceEnum.SURVEY_UI_STORIES]: SPANISH_DEFAULT_SURVEY_UI_STORIES,
        [LangNamespaceEnum.SURVEY_UI_HOOKS]: SPANISH_DEFAULT_SURVEY_UI_HOOKS,
      },
    },
    fallbackLng: {
      [LangCodeEnum.PORTUGUESE]: [LangCodeEnum.PORTUGUESE_DEFAULT, LangCodeEnum.ENGLISH_DEFAULT],
      [LangCodeEnum.PORTUGUESE_BRAZILIAN]: [
        LangCodeEnum.PORTUGUESE_DEFAULT,
        LangCodeEnum.ENGLISH_DEFAULT,
      ],
      [LangCodeEnum.CHINESE_SIMPLIFIED]: [
        LangCodeEnum.CHINESE_DEFAULT,
        LangCodeEnum.ENGLISH_DEFAULT,
      ],
      [LangCodeEnum.CHINESE_TRADITIONAL]: [
        LangCodeEnum.CHINESE_DEFAULT,
        LangCodeEnum.ENGLISH_DEFAULT,
      ],
      [LangCodeEnum.SPANISH]: [LangCodeEnum.SPANISH_DEFAULT, LangCodeEnum.ENGLISH_DEFAULT],
      [LangCodeEnum.SPANISH_MEXICAN]: [LangCodeEnum.SPANISH_DEFAULT, LangCodeEnum.ENGLISH_DEFAULT],
      default: [LangCodeEnum.ENGLISH_DEFAULT],
    },
  });

export { i18n };
