import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import Recaptcha from 'react-recaptcha';
import { useSelector } from 'react-redux';

import { selectIsWebLinkDistribution } from 'selector/distributionSelector';
import { selectRecaptchaKey } from 'selector/apiSelector';
import { useRecaptchaTokenAtom } from 'shared/hooks/useAtoms';

export type WithRecaptchaProps = {
  children: React.ReactNode;
};

export const WithRecaptcha: React.VFC<WithRecaptchaProps> = ({ children }) => {
  const recaptchaInstance = useRef<Recaptcha>();

  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const isWebLinkDistribution = useSelector(selectIsWebLinkDistribution);
  const recaptchaSiteKey = selectRecaptchaKey();
  const [, setRecaptchaToken] = useRecaptchaTokenAtom();

  useEffect(() => {
    if (isLoaded) {
      recaptchaInstance.current.execute();
    }
  }, [isLoaded]);

  return (
    <div>
      {(isLoaded || !isWebLinkDistribution || !recaptchaSiteKey) && children}
      {isWebLinkDistribution && recaptchaSiteKey && (
        <Recaptcha
          ref={(ref) => (recaptchaInstance.current = ref)}
          sitekey={recaptchaSiteKey}
          render="explicit"
          size="invisible"
          onloadCallback={() => setIsLoaded(true)}
          verifyCallback={setRecaptchaToken}
        />
      )}
    </div>
  );
};
