import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { CONFIDENTIALITY_PROMISE } from 'util/links';

import { Box, Icon, Link } from '@mui/material';
import { LangNamespaceEnum } from 'shared/enums/LangNamespaceEnum';
import { ThemeContext } from 'context/ThemeContext';
import { ThemeModeEnum } from 'shared/enums/ThemeModeEnum';

import { linkStyles, linkStylesHighContrast } from './AnonymousLink.s';

export type AnonymousLinkProps = {
  variant?: 'inline' | 'stacked';
};

export const AnonymousLink: React.FC<AnonymousLinkProps> = ({ variant = 'inline' }) => {
  const { t } = useTranslation(LangNamespaceEnum.SURVEY_UI_COMPONENTS);
  const { themeMode } = useContext(ThemeContext);

  const linkSx =
    themeMode === ThemeModeEnum.HIGH_CONTRAST_ACCESSIBLE ? linkStylesHighContrast : linkStyles;

  return (
    <Box alignItems="center" display="flex" flexDirection={variant === 'inline' ? 'row' : 'column'}>
      <Icon data-testid="visibilityOffIcon" sx={{ marginRight: variant === 'inline' ? 1.5 : 0 }}>
        visibility_off
      </Icon>
      <Link
        className="anonymous-link"
        href={CONFIDENTIALITY_PROMISE}
        sx={linkSx}
        target="_blank"
        variant="body2"
      >
        {t('AnonymousLink.title')}
      </Link>
    </Box>
  );
};
