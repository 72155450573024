import { LangCodeEnum } from 'shared/enums/LangCodeEnum';

export type RespondentMetaDto = {
  preferredLanguage: LangCodeEnum | null;
};

export type RespondentMetaModel = RespondentMetaDto;

export const mapRespondentMetaDtoToModel = (dto: RespondentMetaDto): RespondentMetaModel => {
  return {
    preferredLanguage: dto.preferredLanguage,
  };
};
