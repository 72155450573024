import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { ThemeContext } from 'context/ThemeContext';
import { LangNamespaceEnum } from 'shared/enums/LangNamespaceEnum';
import { Toggle } from 'component/Toggles/Toggle';
import { Icon } from 'component/Toggles/Toggle.s';

type AccessibilityToggleProps = {
  'data-testid'?: string;
};

export const AccessibilityToggle: React.VFC<AccessibilityToggleProps> = ({
  'data-testid': dataTestId,
}) => {
  const { t } = useTranslation(LangNamespaceEnum.SURVEY_UI_COMPONENTS);
  const { toggleTheme } = useContext(ThemeContext);

  return (
    <Toggle
      data-testid={dataTestId}
      title={t('AccessibilityToggle.title')}
      icon={<Icon>accessibilityOutlined</Icon>}
      onClick={toggleTheme}
    />
  );
};
