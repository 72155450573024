import { PageApi } from 'api/PageApi';
import { List } from 'immutable';
import { Page } from 'model/Page';

import { PageItemMapper } from './PageItemMapper';

export class PageMapper {
  private pageItemMapper: PageItemMapper;

  constructor(pageItemMapper: PageItemMapper) {
    this.pageItemMapper = pageItemMapper;
  }

  deserialize(entry: PageApi.Entry): Page {
    return new Page({
      project: entry.project,
      id: entry.id,
      position: entry.position,
      items: List((entry.items || []).map((item) => this.pageItemMapper.deserialize(item)))
        .sortBy((item) => item.getPosition())
        .toList(),
    });
  }
}
