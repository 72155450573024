import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import {
  selectAccessCode,
  selectDistributionSlug,
  selectKioskCode,
} from 'selector/navigationSelector';
import { RespondentMetaModel } from 'api/dto/RespondentMeta.dto';
import { surveyApi } from 'container/surveyApi';

export const useFetchRespondentMeta = () => {
  const distributionSlug = useSelector(selectDistributionSlug);
  const accessCode = useSelector(selectAccessCode);
  const kioskCode = useSelector(selectKioskCode);
  const accessCodeToUse = accessCode || kioskCode;

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [respondentMeta, setRespondentMeta] = useState<RespondentMetaModel>({
    preferredLanguage: null,
  });

  useEffect(() => {
    if (distributionSlug && accessCodeToUse) {
      setIsLoading(true);
      surveyApi
        .getRespondentMeta(distributionSlug, accessCodeToUse)
        .then(setRespondentMeta)
        .catch(setError)
        .finally(() => setIsLoading(false));
    }
  }, [distributionSlug, accessCodeToUse]);

  return { isLoading, respondentMeta, error };
};
