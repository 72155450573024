import { SxProps } from '@mui/system';
import { Property } from 'csstype';

export const getXsGridItemStyles = ({
  justifyContent,
}: {
  justifyContent?: Property.JustifyContent;
} = {}): SxProps => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent,
});
