import { styled } from '@mui/material';
import { Container } from 'component/Container/Container';

export const CenteredContent = styled(Container)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  height: '100%',
  justifyContent: 'center',
  textAlign: 'center',
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(4),
}));
