import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';

import { useFetchSurvey } from 'shared/hooks/useFetchSurvey';
import { AppLoader } from 'component/AppLoader/AppLoader';
import { selectSurveyResource } from 'selector/surveySelector';
import styled from '@emotion/styled';

import { ResourceLoader } from './ResourceLoader';

type WithSurveyResourceLoaderProps = {
  children: JSX.Element | JSX.Element[];
};

const Centered = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  width: '100%',
  height: '100%',
});

export const WithSurveyResourceLoader: React.VFC<WithSurveyResourceLoaderProps> = ({
  children,
}) => {
  const surveyResource = useSelector(selectSurveyResource);
  const Children = () => <>{children}</>;

  useFetchSurvey();

  return (
    <ResourceLoader
      optimistic
      silent
      resource={surveyResource}
      loader={() => (
        <Suspense fallback="">
          <Centered>
            <AppLoader />
          </Centered>
        </Suspense>
      )}
      error={Children}
      content={Children}
    />
  );
};
