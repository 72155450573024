import { AnswerApi } from 'api/AnswerApi';
import { Answer } from 'model/Answer';

export class AnswerMapper {
  serialize(model: Answer): AnswerApi.Entry {
    return {
      id: model.getId(),
      option: model.getOption(),
      answer: model.getAnswer(),
      question: model.getQuestion(),
    };
  }

  deserialize(entry: AnswerApi.Entry): Answer {
    return new Answer({
      id: entry.id,
      option: entry.option !== null ? entry.option : undefined,
      answer: entry.answer !== null ? entry.answer : undefined,
      question: entry.questionId,
    });
  }
}
