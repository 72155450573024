import { createTheme, ThemeOptions } from '@mui/material/styles';
import { mergeStrict } from 'shared/utils/merge/mergeStrict';
import { mediaQueryMode } from 'shared/constants/mediaQueryMode';
import { MuiFormControlLabelComp } from 'app/theme/components/MuiFormControlLabel.comp';
import { MuiButtonComp } from 'app/theme/components/MuiButton.comp';
import { lightPalette } from 'app/theme/palettes/light';
import { ThemeModeEnum } from 'shared/enums/ThemeModeEnum';

import { WBAnswerToggleButtonComp } from './components/WBAnswerToggleButton.comp';
import { baseThemeOptions } from './base';
import { colors, focusBorderColor, inputBorderWidthPx } from './config';
import { WBAnswerToggleButtonGroupComp } from './components/WBAnswerToggleButtonGroup.comp';
import { MuiSelectComp } from './components/MuiSelect.comp';

export const lightThemeOptions: ThemeOptions = {
  themeName: ThemeModeEnum.LIGHT,

  components: {
    MuiButton: MuiButtonComp.light,
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          borderColor: colors.alto,
        },
      },
    },
    MuiFormControlLabel: MuiFormControlLabelComp.light,
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: colors.tundora,
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& fieldset': {
            border: `${inputBorderWidthPx} solid ${colors.alto}`,
          },
          '&.Mui-focused fieldset': {
            borderColor: `${focusBorderColor.light} !important`,
            borderWidth: `${inputBorderWidthPx} !important`,
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.oxfordBlue + '!important',
          },
        },
      },
    },
    MuiSelect: MuiSelectComp.light,
    WBAccessTimeIcon: { styleOverrides: { root: { color: colors.christi } } },
    WBToggleIconButton: {
      styleOverrides: {
        root: {
          color: colors.tundora,
          backgroundColor: colors.white,
          borderColor: colors.alto,
          '&:hover': {
            color: colors.white,
            backgroundColor: colors.tundora,
            borderColor: 'inherit',
            // Reset on touch devices, it doesn't add specificity
            [mediaQueryMode.touchDevices]: {
              color: colors.tundora,
              backgroundColor: colors.white,
              borderColor: 'inherit',
            },
          },
        },
      },
    },
    WBAnswerToggleButton: WBAnswerToggleButtonComp.light,
    WBAnswerToggleButtonGroup: WBAnswerToggleButtonGroupComp.light,
  },
  palette: lightPalette,
  typography: {
    allVariants: {
      color: colors.tundora,
    },
  },
};

export const lightTheme = createTheme(
  mergeStrict<ThemeOptions>(baseThemeOptions, lightThemeOptions)
);
