export class Exception extends Error {
  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, Exception.prototype);
  }

  getMessage(): string {
    return this.message;
  }

  getStack(): string {
    return this.stack;
  }

  toJSON(): object {
    return Object.getOwnPropertyNames(this).reduce(
      (json, key) => ({ ...json, [key]: (this as never)[key] }),
      {}
    );
  }
}
