import { draftEntityTransformer } from 'util/draftEntityTransformer';

import { RawDraftContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { SimpleModel } from 'model/SimpleModel';

import { PageItem } from './PageItem';
import { PageItemKind } from './PageItemKind';

export namespace TextWidget {
  export interface Shape extends PageItem.Shape {
    content: RawDraftContentState;
    htmlContent?: string;
  }
}

export class TextWidget extends PageItem<TextWidget.Shape> {
  constructor(initialData: SimpleModel.Data<TextWidget.Shape>) {
    super(initialData, PageItemKind.TextWidget);
  }

  hasContent(): boolean {
    return !!this.get('content');
  }

  getContent(): RawDraftContentState {
    return this.get('content');
  }

  setContent(content: RawDraftContentState): this {
    return this.set('content', content);
  }

  getHtmlContent(): string {
    return this.hasContent()
      ? draftToHtml(this.getContent(), undefined, undefined, draftEntityTransformer)
      : '';
  }
}
