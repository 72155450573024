import { LogicApi } from 'api/LogicApi';
import { LogicDistribution } from 'model/LogicDistribution';
import { LogicType } from 'model/LogicType';

import { LogicTypeMapper } from './LogicMapper';

export class LogicDistributionMapper
  implements LogicTypeMapper<LogicDistribution, LogicApi.DistributionEntry> {
  deserialize(entry: LogicApi.DistributionEntry): LogicDistribution {
    return new LogicDistribution({
      id: entry.id,
      type: entry.type,
      channel: entry.channel,
    });
  }

  supportsDeserialize(entry: LogicApi.Entry): boolean {
    return entry.type === LogicType.Distribution;
  }
}
