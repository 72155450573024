import { Box, styled } from '@mui/material';
import errorImage from 'app/public/error.png';

export const Root = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  backgroundImage: `url(${errorImage})`,
  backgroundPosition: 'center bottom',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '500px',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  height: '100%',
  justifyContent: 'center',
  paddingBottom: 'calc((236 / 500) * 500px)',
  textAlign: 'center',
  [theme.breakpoints.up('sm')]: {
    backgroundSize: '838px',
    paddingBottom: 'calc((397 / 838) * 838px)',
  },
}));
