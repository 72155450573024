import { EmailDistributionApi } from 'api/EmailDistributionApi';
import { DistributionChannel } from 'model/DistributionChannel';
import { EmailDistribution } from 'model/EmailDistribution';

import { DistributionMapperByChannel } from './DistributionMapper';

export class EmailDistributionMapper
  implements DistributionMapperByChannel<EmailDistribution, EmailDistributionApi.Entry> {
  deserialize(entry: EmailDistributionApi.Entry): EmailDistribution {
    return new EmailDistribution({
      id: entry.id,
      slug: entry.slug,
    });
  }

  supportsDeserialize(entry: EmailDistributionApi.Entry): boolean {
    return entry.channel === DistributionChannel.Email;
  }
}
