import {
  AsyncAction,
  isActionPending,
  isActionRejected,
  isActionResolved,
} from 'action/asyncAction';
import { Reducer } from 'redux';
import { Action } from 'redux-actions';

export function createAsyncReducer<S>(
  reducers: {
    begin?: Reducer<S>;
    resolve?: Reducer<S>;
    reject?: Reducer<S>;
  },
  initialState?: S
): Reducer<S> {
  return (state: S = initialState, action: AsyncAction<unknown>) => {
    if (action.pending) {
      return reducers.begin ? reducers.begin(state, null) : state;
    }

    if (action.error) {
      if (action.payload) {
        // log error of async action
        console.error(action.payload);
      }
      return reducers.reject ? reducers.reject(state, action) : state;
    }

    return reducers.resolve ? reducers.resolve(state, action) : state;
  };
}

export function handlePending<S>(type: string, reducer: Reducer<S>, initialState: S): Reducer<S> {
  return (state: S = initialState, action: AsyncAction<unknown>) =>
    action.type === type && isActionPending(action) ? reducer(state, action) : state;
}

export function handleResolved<S, P>(
  type: string,
  reducer: Reducer<S>,
  initialState: S
): Reducer<S> {
  return (state: S = initialState, action: Action<P>) =>
    action.type === type && isActionResolved(action) ? reducer(state, action) : state;
}

export function handleRejected<S>(type: string, reducer: Reducer<S>, initialState: S): Reducer<S> {
  return (state: S = initialState, action: AsyncAction<unknown>) =>
    action.type === type && isActionRejected(action) ? reducer(state, action) : state;
}
