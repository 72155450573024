import {
  alpha,
  Box,
  BoxTypeMap,
  FormControl,
  InputBase,
  inputLabelClasses,
  styled,
  SxProps,
  Theme,
} from '@mui/material';
import { colors } from 'app/theme/config';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { FormControlTypeMap } from '@mui/material/FormControl/FormControl';
import { ThemeModeEnum } from 'shared/enums/ThemeModeEnum';
import { buttonStrikeoutHighContrast } from 'app/theme/utils/buttonStrikeout';

import { wbInputClasses } from './classes';

export type OwnerState = {
  marginBottom?: false | 'normal';
  disabled?: boolean;
  error?: boolean;
};

type WithOwnerState = {
  ownerState: OwnerState;
};

const BORDER_RADIUS = '6px';

export const FormControlRoot = styled<OverridableComponent<FormControlTypeMap<WithOwnerState>>>(
  FormControl
)(({ theme, ownerState }) => ({
  ...(ownerState.marginBottom === 'normal'
    ? {
        marginBottom: theme.spacing(3),
      }
    : {}),

  ...(ownerState.disabled && theme.themeName === ThemeModeEnum.HIGH_CONTRAST_ACCESSIBLE
    ? {
        'label.Mui-disabled': {
          color: colors.white,
        },
        '.MuiInputBase-root.Mui-disabled': {
          color: colors.white,
          border: `2px solid ${colors.white}`,
          background: buttonStrikeoutHighContrast(colors.black, colors.white),
        },
      }
    : {}),
}));

export const inputLabelSx: SxProps<Theme> = (theme) => ({
  ...theme.typography.h6,
  color: theme.palette.text.primary,
  [`&.${inputLabelClasses.error}`]: {
    color: theme.palette.text.primary,
  },
  [`&.${inputLabelClasses.focused}`]: {
    color: theme.palette.text.primary,
  },
  [`&.${inputLabelClasses.disabled}`]: {
    color: theme.palette.text.disabled,
  },
});

export const WBInput = styled(InputBase, {
  overridesResolver: (props, styles) => {
    return [styles.root, styles.error];
  },
})(({ theme }) => {
  const themeBasedConfig = getThemeBasedTextFieldConfig(theme);

  return {
    ...themeBasedConfig.additionalBaseStyles,

    'label + &': {
      marginTop: theme.spacing(3),
    },
    borderRadius: BORDER_RADIUS,
    position: 'relative',
    backgroundColor: themeBasedConfig.baseBackground,
    border: `3px solid ${themeBasedConfig.baseBorderColor}`,
    fontSize: 16,
    width: '100%',
    overflow: 'hidden',
    transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
    [`&:focus-within:not(.${wbInputClasses.disabled})`]: themeBasedConfig.focusStyles,
    [`&:hover:not(.${wbInputClasses.disabled}), &:focus:not(.${wbInputClasses.disabled})`]: themeBasedConfig.hoverStyles,
    [`& .${wbInputClasses.input}`]: {
      padding: theme.spacing(1.25, 2),
      height: 24,
    },
    [`&.${wbInputClasses.adornedEnd} .${wbInputClasses.input}`]: {
      paddingRight: 0,
    },
    [`&.${wbInputClasses.adornedEnd}`]: {
      paddingRight: theme.spacing(1.75),
    },
    [`&.${wbInputClasses.adornedStart} .${wbInputClasses.input}`]: {
      paddingLeft: 0,
    },
    [`&.${wbInputClasses.adornedStart}`]: {
      paddingLeft: theme.spacing(1.75),
    },
    [`&.${wbInputClasses.error}:not(.${wbInputClasses.disabled})`]: themeBasedConfig.errorStyles,
  };
});

export const ErrorMessage = styled<OverridableComponent<BoxTypeMap<WithOwnerState>>>(Box)(
  ({ theme, ownerState }) => ({
    ...theme.typography.subtitle2,
    color:
      theme.themeName === ThemeModeEnum.HIGH_CONTRAST_ACCESSIBLE
        ? colors.black
        : theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    backgroundColor:
      theme.themeName === ThemeModeEnum.HIGH_CONTRAST_ACCESSIBLE
        ? colors.white
        : alpha(colors.monza, 0.1),
    padding: theme.spacing(0.875, 1, 0.75),
    marginTop: theme.spacing(1),
    marginBottom: 0,
    borderRadius: BORDER_RADIUS,
    ...(ownerState.disabled
      ? {
          color: theme.palette.text.disabled,
          backgroundColor: alpha(colors.monza, 0.08),
        }
      : {}),
  })
);

export const helperTextSx: SxProps<Theme> = (theme) => ({
  ...theme.typography.subtitle2,
  fontWeight: theme.typography.fontWeightRegular,
  marginTop: theme.spacing(0.5),
});

function getThemeBasedTextFieldConfig(theme: Theme) {
  switch (theme.themeName) {
    case ThemeModeEnum.HIGH_CONTRAST_ACCESSIBLE:
      return {
        baseBackground: colors.black,
        baseBorderColor: colors.candyCorn,
        hoverStyles: {
          borderColor: colors.white,
          outline: `4px solid ${colors.white}`,
          outlineOffset: '-1px',
        },
        focusStyles: {
          borderColor: colors.candyCorn,
          outline: `4px solid ${colors.candyCorn}`,
          outlineOffset: '-1px',
        },
        errorStyles: {},

        additionalBaseStyles: {
          'span.MuiIcon-root': {
            color: colors.white,
          },
        },
      };
    default:
      return {
        baseBackground: colors.white,
        baseBorderColor: colors.iron,
        hoverStyles: {
          backgroundColor: `${alpha(colors.oxfordBlue, 0.05)}`,
          borderColor: colors.oxfordBlue,
        },
        focusStyles: {
          borderColor: colors.cornflowerBlue,
        },
        errorStyles: {
          borderColor: theme.palette.error.main,
        },

        additionalBaseStyles: {},
      };
  }
}
