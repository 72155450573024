import { WebLinkDistributionApi } from 'api/WebLinkDistributionApi';
import { DistributionChannel } from 'model/DistributionChannel';
import { WebLinkDistribution } from 'model/WebLinkDistribution';

import { DistributionMapperByChannel } from './DistributionMapper';

export class WebLinkDistributionMapper
  implements DistributionMapperByChannel<WebLinkDistribution, WebLinkDistributionApi.Entry> {
  deserialize(entry: WebLinkDistributionApi.Entry): WebLinkDistribution {
    return new WebLinkDistribution({
      id: entry.id,
      slug: entry.slug,
      link: entry.surveyLink,
    });
  }

  supportsDeserialize(entry: WebLinkDistributionApi.Entry): boolean {
    return entry.channel === DistributionChannel.WebLink;
  }
}
