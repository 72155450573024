import React from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextFieldController } from 'component/Form/TextField/TextFieldController';
import { createEmailRules } from 'shared/utils/rules/createEmailRules';
import { SubmitButton } from 'component/SubmitButton/SubmitButton';
import { LangNamespaceEnum } from 'shared/enums/LangNamespaceEnum';

import { Fieldset } from './SaveAndContinueForm.s';

const defaultValues: FieldValues = {
  email: '',
  confirmEmail: '',
};

export type SaveAndContinueFormProps = {
  isSubmitting?: boolean;
  onSave: (email: string) => void;
};

export const SaveAndContinueForm: React.VFC<SaveAndContinueFormProps> = ({
  isSubmitting = false,
  onSave,
}: SaveAndContinueFormProps) => {
  const { t } = useTranslation(LangNamespaceEnum.SURVEY_UI_COMPONENTS);
  const { control, getValues, trigger, watch } = useForm({ defaultValues, mode: 'onTouched' });
  const incorrectMessage = t('SaveAndContinueForm.emailAddress.incorrectRule');
  const requiredMessage = t('SaveAndContinueForm.emailAddress.requiredRule');
  const emailRules = createEmailRules({ incorrectMessage, requiredMessage });

  const onSubmit = async () => {
    const result = await trigger(['email', 'confirmEmail']);
    const { email } = getValues();

    if (!result) {
      return;
    }

    onSave(email);
  };

  return (
    <Fieldset component="fieldset">
      <TextFieldController
        control={control}
        label={t('SaveAndContinueForm.emailAddressLabel')}
        name="email"
        rules={emailRules}
        type="email"
      />
      <TextFieldController
        control={control}
        label={t('SaveAndContinueForm.confirmEmailAddressLabel')}
        name="confirmEmail"
        rules={{
          required: {
            message: t('SaveAndContinueForm.confirmEmailAddress.requiredRule'),
            value: true,
          },
          validate: (value) =>
            value === watch('email') || t('SaveAndContinueForm.confirmEmailAddress.matchRule'),
        }}
        type="email"
      />
      <SubmitButton
        isSubmitting={isSubmitting}
        onClick={() => onSubmit()}
        size="medium"
        sx={{ float: 'right' }}
        type="button"
        data-testid="sendButton"
      >
        {t('SaveAndContinueForm.submitButton')}
      </SubmitButton>
    </Fieldset>
  );
};
