import { List } from 'immutable';
import { Answer } from 'model/Answer';
import { ProjectSubmit } from 'model/ProjectSubmit';
import { Resource } from 'model/Resource';
import { createSelector, Selector } from 'reselect';
import { SurveyState } from 'store/SurveyState';
import { QuestionFormData } from 'shared/models/SurveyFormData';

import { createResourceContentSelector } from './resourceSelector';

export const selectSubmitResource: Selector<SurveyState, Resource<ProjectSubmit>> = (state) =>
  (state && state.submitResource) || Resource.void();

export const selectSubmit = createResourceContentSelector(selectSubmitResource);

export const selectSubmitAnswers = createSelector(selectSubmit, (submit) =>
  submit ? submit.getAnswers() : List<Answer>()
);

export const selectSurveyInitialAnswersFromSubmit = createSelector(selectSubmitAnswers, (answers) =>
  answers.reduce((initialAnswers, answer) => {
    const key = `id:${answer.getQuestion()}`;

    // internal mutations - safe
    if (!initialAnswers[key]) {
      initialAnswers[key] = [];
    }

    initialAnswers[key].push({ option: answer.getOption(), answer: answer.getAnswer() });
    return initialAnswers;
  }, {} as QuestionFormData)
);
