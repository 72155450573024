import { Box, styled } from '@mui/material';
import { colors } from 'app/theme/config';
import { ThemeModeEnum } from 'shared/enums/ThemeModeEnum';

export const RootBox = styled(Box)(({ theme }) => ({
  backgroundColor:
    theme.themeName === ThemeModeEnum.HIGH_CONTRAST_ACCESSIBLE ? colors.black : colors.gallery,
  padding: '30px 0',
  borderBottom:
    theme.themeName === ThemeModeEnum.HIGH_CONTRAST_ACCESSIBLE
      ? `3px solid ${colors.white}`
      : 'none',
}));
