import React from 'react';

import { Box } from '@mui/material';

export type PageContainerProps = {
  children: React.ReactNode;
  headerElement?: React.ReactNode;
  footerElement?: React.ReactNode;
};

export const PageContainer: React.VFC<PageContainerProps> = ({
  children,
  headerElement,
  footerElement,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      {headerElement}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
        }}
      >
        {children}
      </Box>
      {footerElement}
    </Box>
  );
};
