import { List } from 'immutable';
import { CustomQuestionOption } from 'model/CustomQuestionOption';
import { DiscreteQuestion } from 'model/DiscreteQuestion';
import { PageItemKind } from 'model/PageItemKind';
import { Question } from 'model/Question';
import { SimpleModel } from 'model/SimpleModel';

const ConsentOptionId = {
  Yes: 1,
  No: 2,
} as const;

export namespace QuestionConsent {
  export interface Shape extends Question.Shape {
    optionNo: string;
    optionYes: string;
    isRequired?: boolean;
  }
}

export class QuestionConsent extends Question<QuestionConsent.Shape> implements DiscreteQuestion {
  private readonly consentOptions = List<CustomQuestionOption>();

  constructor(initialData: SimpleModel.Data<QuestionConsent.Shape>) {
    super(initialData, PageItemKind.QuestionConsent);

    this.consentOptions = List<CustomQuestionOption>([
      new CustomQuestionOption({
        id: ConsentOptionId.Yes,
        option: this.get('optionYes'),
        position: 1,
      }),
      new CustomQuestionOption({
        id: ConsentOptionId.No,
        option: this.get('optionNo'),
        position: 2,
      }),
    ]);
  }

  getOptions(): List<CustomQuestionOption> {
    return this.consentOptions;
  }

  getAvailableOptions(): List<CustomQuestionOption> {
    return this.getOptions();
  }

  isRequired(): boolean {
    return this.get('isRequired');
  }

  hasOther(): boolean {
    return false;
  }
}
