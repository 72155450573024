import React from 'react';
import { useSelector } from 'react-redux';

import { sortLanguageCodes } from 'shared/utils/sortLanguageCodes';
import { LangCodeEnum } from 'shared/enums/LangCodeEnum';
import { useSessionStorage } from 'shared/hooks/useSessionStorage';
import { useIsPreview } from 'shared/hooks/useIsPreview';
import { selectMeta } from 'selector/metaSelector';
import { PageContainer } from 'component/PageContainer/PageContainer';
import { FooterPure } from 'component/Footer/Footer';
import { PreferredLanguagePicker } from 'component/PreferredLanguagePicker/PreferredLanguagePicker';
import { useChangeLanguageToPreferred } from 'view/WithPreferredLanguage/hooks/useChangeLanguageToPreferred';
import { useFetchRespondentMeta } from 'shared/hooks/useFetchRespondentMeta';

import { WelcomePageHeader } from '../WelcomePage/WelcomePageHeader/WelcomePageHeader';

type WithPreferredLanguageProps = { children: React.ReactElement };

export const PREFERRED_LANGUAGE_STORAGE_KEY = 'preferredLanguage';

export const WithPreferredLanguage: React.VFC<WithPreferredLanguageProps> = ({ children }) => {
  // TODO: Handle error case for this component
  const isPreview = useIsPreview();
  const [sessionStoragePreferredLanguage] = useSessionStorage<LangCodeEnum>(
    PREFERRED_LANGUAGE_STORAGE_KEY
  );

  const meta = useSelector(selectMeta);

  const {
    isLoading: isLoadingRespondentMeta,
    respondentMeta: { preferredLanguage },
  } = useFetchRespondentMeta();

  const { isLanguageChangeInProgress } = useChangeLanguageToPreferred(preferredLanguage);

  if (!meta || isLanguageChangeInProgress || isLoadingRespondentMeta) {
    return null;
  }

  const shouldShowSurvey =
    isPreview || !meta?.getMultiLanguageEnabled() || !!sessionStoragePreferredLanguage;

  if (shouldShowSurvey) {
    return children;
  }

  return (
    <PageContainer
      headerElement={
        <WelcomePageHeader
          companyName={meta.getCompanyName()}
          companyLogoUrl={meta.getCompanyLogo()}
          barColor={meta.getCompanyBrandColor()}
          hideLanguageToggle
        />
      }
      footerElement={<FooterPure />}
    >
      <PreferredLanguagePicker enabledLocales={sortLanguageCodes(meta.getEnabledLocales())} />
    </PageContainer>
  );
};
