import {
  buttonBorderWidthPx,
  buttonOutlineWidthPx,
  colors,
  focusBorderColor,
} from 'app/theme/config';
import { Components, formControlLabelClasses, typographyClasses } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { mediaQueryMode } from 'shared/constants/mediaQueryMode';
import { common } from '@mui/material/colors';
import {
  whiteEmptyCheckboxSvgUrl,
  malibuCheckedCheckboxSvgUrl,
  candyCornEmptyCheckboxSvgUrl,
} from 'component/PageItem/QuestionCheckboxField/Checkbox.s';

import { buttonStrikeoutHighContrast } from '../utils/buttonStrikeout';

const light: Components<Theme>['MuiFormControlLabel'] = {
  styleOverrides: {
    root: ({ ownerState }) => ({
      border: `${buttonBorderWidthPx} solid ${colors.alto}`,
      [`&:hover:not(.${formControlLabelClasses.disabled})`]: {
        borderColor: colors.silver,
        // Reset on touch devices, it doesn't add specificity
        [mediaQueryMode.touchDevices]: {
          borderColor: 'inherit',
        },
      },
      '&:focus-within': {
        outline: `${buttonOutlineWidthPx} solid ${focusBorderColor.light}`,
      },
      [`&.${formControlLabelClasses.disabled}`]: {
        backgroundColor: colors.gallery,
        '& .MuiTypography-root': {
          color: colors.boulder,
        },
        '& .MuiCheckbox-root.Mui-disabled > span': {
          backgroundImage:
            "url(\"data:image/svg+xml;charset=utf8,%3Csvg width='26' height='26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg%3E%3Ctitle%3ELayer 1%3C/title%3E%3Cpath fill='%23747474' d='m22.88878,3.11122l0,20.222l-20.222,0l0,-20.222l20.222,0m0,-2.889l-20.222,0a2.9,2.9 0 0 0 -2.889,2.889l0,20.222a2.9,2.9 0 0 0 2.889,2.889l20.222,0a2.9,2.9 0 0 0 2.889,-2.889l0,-20.222a2.9,2.9 0 0 0 -2.889,-2.889z' data-name='Path 15' id='Path_15'/%3E%3C/g%3E%3C/svg%3E\")",
        },
      },

      ...(ownerState.checked && {
        backgroundColor: colors.tundora,
        borderColor: colors.tundora,
        [`& .${typographyClasses.root}`]: {
          color: common.white,
        },
        [`&:hover:not(.${formControlLabelClasses.disabled})`]: {
          borderColor: 'inherit',
        },
      }),
    }),
  },
};

const highContrastAccessible: Components<Theme>['MuiFormControlLabel'] = {
  styleOverrides: {
    root: ({ ownerState }) => ({
      '& > span': {
        color: ownerState.checked ? colors.malibu : colors.candyCorn,
      },

      border: `${buttonBorderWidthPx} solid ${colors.white}`,

      '.MuiRadio-root': {
        color: colors.candyCorn,
      },

      '.MuiCheckbox-root > span': {
        backgroundImage: candyCornEmptyCheckboxSvgUrl,
      },

      [`&:hover:not(.${formControlLabelClasses.disabled}):not(:focus-within)`]: {
        outline: `6px solid ${colors.white}`,
        outlineOffset: '-3px',
      },

      '&:focus-within': {
        color: ownerState.checked ? colors.malibu : colors.candyCorn,
        outline: `6px solid ${colors.malibu}`,
        outlineOffset: '-3px',

        '& > span': {
          color: ownerState.checked ? colors.malibu : colors.candyCorn,
        },

        '.MuiRadio-root': {
          color: ownerState.checked ? colors.malibu : colors.candyCorn,
        },

        '& .MuiCheckbox-root > span': {
          backgroundImage: ownerState.checked
            ? malibuCheckedCheckboxSvgUrl
            : candyCornEmptyCheckboxSvgUrl,
        },
      },

      '&.Mui-disabled': {
        background: buttonStrikeoutHighContrast(colors.black, colors.white),
        '& > span': {
          color: colors.white,
        },

        '.MuiRadio-root': {
          color: colors.white,
        },

        '& .MuiCheckbox-root > span': {
          backgroundImage: whiteEmptyCheckboxSvgUrl,
        },
      },

      ...(ownerState.checked && {
        color: colors.malibu,
        borderColor: colors.malibu,

        '.MuiRadio-root': {
          color: colors.malibu,
        },

        '.MuiCheckbox-root > span': {
          backgroundImage: malibuCheckedCheckboxSvgUrl,
        },
      }),
    }),
  },
};

export const MuiFormControlLabelComp = {
  light,
  highContrastAccessible,
};
