import { styled } from '@mui/material';
import { colors } from 'app/theme/config';
import { ThemeModeEnum } from 'shared/enums/ThemeModeEnum';

export const FlagStyled = styled('img')(({ theme }) => ({
  border: `2px solid ${
    theme.themeName === ThemeModeEnum.HIGH_CONTRAST_ACCESSIBLE ? colors.candyCorn : colors.alto
  }`,
  borderRadius: '50%',
}));
