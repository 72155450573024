import { SimpleModel } from 'model/SimpleModel';

import { AvailableQuestionOption } from './AvailableQuestionOption';

export namespace StaticQuestionOption {
  export interface Shape {
    option: string;
    value: number;
  }
}

export class StaticQuestionOption
  extends SimpleModel<StaticQuestionOption.Shape>
  implements AvailableQuestionOption {
  getOption(): string {
    return this.get('option');
  }

  getValue(): number {
    return this.get('value');
  }
}
