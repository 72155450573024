import { colors } from 'app/theme/config';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { mergeSx } from 'shared/utils/merge/mergeSx';

export const boxInnerStyles = {
  margin: '12px 8px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const boxOuterStyles: SxProps<Theme> = (theme) => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '10px',
  marginTop: { xs: '14px', sm: '17px' },
  position: 'relative',
  background: theme.palette.background.default,
  border: `2px solid ${colors.alto}`,
  width: 'fit-content',
  minWidth: '133px',
});

export const boxOuterHighContrastOverrideStyles: SxProps<Theme> = {
  borderColor: colors.white,
};

export const boxTitleContainerStyles: SxProps = {
  position: 'absolute',
  marginTop: '-17px',
  textAlign: 'center',
  left: 0,
  right: 0,
};

export const boxBottomTitleContainerStyles: SxProps = {
  position: 'absolute',
  marginBottom: '-20px',
  textAlign: 'center',
  bottom: 0,
};

export const boxTitleStyles: SxProps<Theme> = (theme) => ({
  background: theme.palette.background.default,
  width: 'auto',
  minWidth: '100px',
  lineHeight: 1,
  display: 'inline-block',
  margin: '0 10px',
  padding: '0 5px',
});

export const boxTitleClientStyles: SxProps = mergeSx(boxTitleStyles, {
  fontSize: '.8rem!important',
});
