import React from 'react';
import { Route, Switch } from 'react-router';

import { SurveyRoute } from 'app/route';
import { NotFoundPage } from 'view/NotFoundPage/NotFoundPage';
import { wbLazy } from 'shared/utils/wbLazy';
import { SurveyUnsubscribedPage } from 'view/SurveyUnsubscribedPage/SurveyUnsubscribedPage';

import { SurveyFormRoutes } from './SurveyFormRoutes';

const KioskPage = wbLazy(() =>
  import(/* webpackChunkName: "KioskPage" */ 'view/KioskPage/KioskPage').then((module) => ({
    default: module['KioskPage'],
  }))
);

const SurveyClosedPage = wbLazy(() =>
  import(/* webpackChunkName: "KioskPage" */ 'view/SurveyClosedPage/SurveyClosedPage').then(
    (module) => ({
      default: module['SurveyClosedPage'],
    })
  )
);

export const SurveyRoutes: React.VFC = () => {
  return (
    <Switch>
      <Route path={SurveyRoute.Survey.CLOSED_PATTERN} component={SurveyClosedPage} exact />
      <Route path={SurveyRoute.Survey.KIOSK_PATTERN} component={KioskPage} exact />
      <Route
        path={SurveyRoute.Survey.UNSUBSCRIBE_PATTERN}
        component={SurveyUnsubscribedPage}
        exact
      ></Route>
      <Route
        path={[
          SurveyRoute.Survey.PAGE_PATTERN,
          SurveyRoute.Survey.SUBMIT_PATTERN,
          SurveyRoute.Survey.SUCCESS_PATTERN,
          SurveyRoute.Survey.SAVE_SUCCESS_PATTERN,
          SurveyRoute.SURVEY_PATTERN,
        ]}
        exact
      >
        <SurveyFormRoutes />
      </Route>
      <Route component={NotFoundPage} />
    </Switch>
  );
};
