import { routerMiddleware } from 'connected-react-router';
import { useDispatch } from 'react-redux';

import { applyMiddleware, compose, createStore, StoreEnhancer } from 'redux';
import { createRootReducer } from 'reducer/reducer';
import { SurveyState } from 'store/SurveyState';
import { createDetectorEnhancer, DetectableStore } from 'redux-detector';
import { surveyDetector } from 'detector/surveyDetector';
import thunkMiddleware from 'redux-thunk';
import * as container from 'container/index';
import { createBrowserHistory } from 'history';
import { markAsyncAction } from 'store/markAsyncAction';

import { loggingMiddleware } from '../middleware/loggingMiddleware';

export const history = createBrowserHistory();

const composeEnhancers =
  window?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?.({
    // @ts-ignore
    actionSanitizer: markAsyncAction,
  }) ?? compose;

const enhancer: StoreEnhancer<SurveyState> = composeEnhancers(
  createDetectorEnhancer(surveyDetector),
  applyMiddleware(
    routerMiddleware(history),
    thunkMiddleware.withExtraArgument(container),
    loggingMiddleware
  )
);

export const store = createStore(
  createRootReducer(history),
  {} as SurveyState,
  enhancer
) as DetectableStore<SurveyState>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
