import { StaticResourceApi } from 'api/StaticResourceApi';
import { StaticResource } from 'model/StaticResource';

export class StaticResourceMapper {
  deserialize(entry: StaticResourceApi.Entry): StaticResource {
    return new StaticResource({
      id: entry.id,
      file: entry.file,
      fileOriginalName: entry.fileOriginalName,
      fileSize: entry.fileSize,
      fileMimeType: entry.fileMimeType,
    });
  }
}
