import { PageItemApi } from 'api/PageItemApi';
import { PageItemKind } from 'model/PageItemKind';
import { QuestionConsent } from 'model/QuestionConsent';

import { PageItemKindMapper } from './PageItemMapper';
import { QuestionAbstractMapper } from './QuestionAbstractMapper';

export class QuestionConsentMapper
  implements PageItemKindMapper<QuestionConsent, PageItemApi.QuestionConsentEntry> {
  deserialize({
    optionYes,
    optionNo,
    ...entry
  }: PageItemApi.QuestionConsentEntry): QuestionConsent {
    return new QuestionConsent({
      ...QuestionAbstractMapper.deserializeShape(entry),
      optionYes,
      optionNo,
      isRequired: Boolean(entry.required),
    });
  }

  supportsDeserialize(entry: PageItemApi.ItemEntry): entry is PageItemApi.QuestionConsentEntry {
    return entry.kind === PageItemKind.QuestionConsent;
  }
}
