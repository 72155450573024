import { px } from 'util/transformToPixels';

import { Box, styled } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';

const spaceFromSide = 30;

type HeaderProps = {
  shouldBePlacedAbsolutely?: boolean;
};

export const HeaderRoot = styled('header')<HeaderProps>(({ theme, shouldBePlacedAbsolutely }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  margin: `10px 0`,
  position: shouldBePlacedAbsolutely ? 'initial' : 'absolute',
  [theme.breakpoints.up('lg')]: {
    alignItems: 'flex-start',
    left: 0,
    margin: shouldBePlacedAbsolutely ? 0 : `10px ${px(spaceFromSide)}`,
    top: '37px',
    width: `calc(100% - ${px(spaceFromSide * 2)})`,
    zIndex: 0,
  },
}));

type ActionsProps = {
  shouldBePlacedAbsolutely?: boolean;
};

export const Actions = styled(Box)<ActionsProps>(({ theme, shouldBePlacedAbsolutely = false }) => ({
  display: 'flex',
  '& > .MuiButtonBase-root:not(:last-child)': {
    margin: theme.spacing(0, 2, 0, 0),
  },
  [theme.breakpoints.up('lg')]: {
    position: shouldBePlacedAbsolutely ? 'absolute' : 'initial',
    top: shouldBePlacedAbsolutely ? '47px' : 'initial',
    right: shouldBePlacedAbsolutely ? `${px(spaceFromSide)}` : 'initial',
    flexDirection: 'column-reverse',
    '& > .MuiButtonBase-root:not(:last-child)': {
      margin: theme.spacing(2, 0, 0),
    },
  },
}));

export const companyLogo: SxProps<Theme> = () => ({
  maxHeight: '100px',
  maxWidth: { xs: '130px', lg: '11%' },
});

export const absoluteCompanyLogo: SxProps<Theme> = ({ breakpoints }) => ({
  maxHeight: '100px',
  maxWidth: '130px',
  [breakpoints.up('lg')]: {
    position: 'absolute',
    top: '47px',
    left: `${px(spaceFromSide)}`,
    maxWidth: '11%',
  },
});
