import React from 'react';

import { Icon } from '@mui/material';
import { Button, ButtonProps } from 'component/Button/Button';
import { mergeSx } from 'shared/utils/merge/mergeSx';
import { useArrowIcon } from 'shared/hooks/useArrowIcon';

import { OwnerState, submitButtonSx, AnimationBox, ButtonContent } from './SubmitButton.s';

export type SubmitButtonProps = Omit<ButtonProps, 'ref'> & {
  isSubmitting: boolean;
};

export const SubmitButton = React.forwardRef<HTMLButtonElement, SubmitButtonProps>(
  ({ children, isSubmitting, size = 'large', ...props }, ref) => {
    const ownerState: OwnerState = {
      isSubmitting,
    };

    const icon = useArrowIcon('next');

    return (
      <Button
        animateIcon="right"
        endIcon={<Icon fontSize="large">{icon}</Icon>}
        minWidth
        size={size}
        disabled={isSubmitting}
        {...props}
        ref={ref}
        sx={mergeSx(submitButtonSx(ownerState), props.sx)}
      >
        {isSubmitting && <AnimationBox aria-hidden="true" />}
        <ButtonContent>{children}</ButtonContent>
      </Button>
    );
  }
);
