import React from 'react';
import { Route, Switch } from 'react-router';

import { SurveyRoute } from 'app/route';
import { ProtectedSurveyFormProvider } from 'component/ProtectedSurveyFormProvider/ProtectedSurveyFormProvider';
import { wbLazy } from 'shared/utils/wbLazy';
import { ApiErrorFallbackPage } from 'view/ApiErrorFallbackPage/ApiErrorFallbackPage';

// const WelcomePage = wbLazy(() =>
//   import(/* webpackChunkName: "WelcomePage" */ 'view/WelcomePage/WelcomePage').then((module) => ({
//     default: module['WelcomePage'],
//   }))
// );
const SurveyFormPage = wbLazy(() =>
  import(/* webpackChunkName: "SurveyPage" */ 'view/SurveyFormPage/SurveyFormPage').then(
    (module) => ({
      default: module['SurveyFormPage'],
    })
  )
);

const SuccessPage = wbLazy(() =>
  import(/* webpackChunkName: "SuccessPage" */ 'view/SuccessPage/SuccessPage').then((module) => ({
    default: module['SuccessPage'],
  }))
);

const SaveSuccessPage = wbLazy(() =>
  import(/* webpackChunkName: "SaveSuccessPage" */ 'view/SaveSuccessPage/SaveSuccessPage').then(
    (module) => ({
      default: module['SaveSuccessPage'],
    })
  )
);

export const SurveyFormRoutes: React.VFC = () => {
  return (
    <ApiErrorFallbackPage>
      <ProtectedSurveyFormProvider>
        <Switch>
          <Route>
            <Route
              path={[
                SurveyRoute.SURVEY_PATTERN,
                SurveyRoute.Survey.PAGE_PATTERN,
                SurveyRoute.Survey.SUBMIT_PATTERN,
              ]}
              component={SurveyFormPage}
              exact
            />
            <Route
              path={[
                SurveyRoute.PREVIEW_PATTERN,
                SurveyRoute.Preview.PAGE_PATTERN,
                SurveyRoute.Preview.SUBMIT_PATTERN,
              ]}
              component={SurveyFormPage}
              exact
            />
            <Route
              path={[SurveyRoute.Preview.SUCCESS_PATTERN, SurveyRoute.Survey.SUCCESS_PATTERN]}
              component={SuccessPage}
              exact
            />
            <Route
              path={[
                SurveyRoute.Preview.SAVE_SUCCESS_PATTERN,
                SurveyRoute.Survey.SAVE_SUCCESS_PATTERN,
              ]}
              component={SaveSuccessPage}
              exact
            />
          </Route>
        </Switch>
      </ProtectedSurveyFormProvider>
    </ApiErrorFallbackPage>
  );
};
