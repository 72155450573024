import React, { forwardRef } from 'react';

import { Alert, alertClasses } from '@mui/material';
import { CustomContentProps, SnackbarContent } from 'notistack';
import { ThemeModeEnum } from 'shared/enums/ThemeModeEnum';
import { colors } from 'app/theme/config';

type ErrorAlertProps = CustomContentProps;

export const SnackbarAlert = forwardRef<HTMLDivElement, ErrorAlertProps>((props, ref) => {
  const { message, id, variant } = props;
  if (variant === 'default') {
    throw new Error('Unsupported variant `default` for SnackbarAlert');
  }

  const highContrastSx = {
    background: colors.white,
    color: colors.black,
  };

  return (
    <SnackbarContent ref={ref} id={id.toString()}>
      <Alert
        variant="filled"
        severity={variant}
        sx={(theme) => ({
          width: '100%',
          minHeight: '62px',
          alignItems: 'center',
          borderRadius: '10px',
          padding: '6px 20px',
          [`& .${alertClasses.message}`]: {
            fontWeight: theme.typography.fontWeightBold,
          },

          ...(theme.themeName === ThemeModeEnum.HIGH_CONTRAST_ACCESSIBLE ? highContrastSx : {}),
        })}
      >
        {message}
      </Alert>
    </SnackbarContent>
  );
});
