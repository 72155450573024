import { KioskDistributionApi } from 'api/KioskDistributionApi';
import { DistributionChannel } from 'model/DistributionChannel';
import { KioskDistribution } from 'model/KioskDistribution';

import { DistributionMapperByChannel } from './DistributionMapper';

export class KioskDistributionMapper
  implements DistributionMapperByChannel<KioskDistribution, KioskDistributionApi.Entry> {
  deserialize(entry: KioskDistributionApi.Entry): KioskDistribution {
    return new KioskDistribution({
      id: entry.id,
      slug: entry.slug,
      link: entry.surveyLink,
    });
  }

  supportsDeserialize(entry: KioskDistributionApi.Entry): boolean {
    return entry.channel === DistributionChannel.Kiosk;
  }
}
