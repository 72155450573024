import React from 'react';

import clsx from 'clsx';
import {
  BoxProps,
  FormHelperText,
  formHelperTextClasses,
  FormHelperTextProps,
  InputBaseProps,
  InputLabel,
  InputLabelProps,
  InputProps,
  unstable_useId,
} from '@mui/material';
import { mergeSx } from 'shared/utils/merge/mergeSx';

import { ValidationAdornmentIcon } from './components/ValidationAdornmentIcon';
import {
  ErrorMessage,
  FormControlRoot,
  helperTextSx,
  inputLabelSx,
  OwnerState,
  WBInput,
} from './TextField.s';

export type TextFieldProps = InputBaseProps & {
  label?: React.ReactNode;
  shrinkLabel?: boolean;
  InputLabelProps?: InputLabelProps;
  InputProps?: InputProps;
  helperText?: string;
  errorMessage?: string;
  ErrorMessageProps?: BoxProps<'p'>;
  HelperTextProps?: Omit<FormHelperTextProps, 'error'>;
  marginBottom?: false | 'normal';
  isDirty?: boolean;
  'data-testid'?: string;
  shouldHideErrorMessage?: boolean;
  shouldHideValidationAdornmentIcon?: boolean;
};

const getDataTestIdMap = (
  dataTestId?: string
): Partial<Record<'base' | 'label' | 'input' | 'error' | 'helperText', string>> => {
  if (!dataTestId) {
    return {};
  }
  return {
    base: dataTestId,
    label: `${dataTestId}Label`,
    input: `${dataTestId}Input`,
    error: `${dataTestId}ErrorMessage`,
    helperText: `${dataTestId}HelperText`,
  };
};

export const TextField: React.VFC<TextFieldProps> = ({
  id: idOverride,
  label,
  shrinkLabel = true,
  InputLabelProps,
  InputProps,
  helperText,
  errorMessage,
  error,
  ErrorMessageProps,
  HelperTextProps,
  fullWidth,
  marginBottom = 'normal',
  isDirty,
  sx,
  disabled,
  'data-testid': dataTestId,
  shouldHideErrorMessage,
  shouldHideValidationAdornmentIcon,
  ...rest
}) => {
  const id = unstable_useId(idOverride);
  const dataTestIdMap = getDataTestIdMap(dataTestId);

  const ownerState: OwnerState = {
    marginBottom,
    disabled,
  };

  return (
    <FormControlRoot
      variant="standard"
      data-testid={dataTestIdMap.base}
      ownerState={ownerState}
      fullWidth={fullWidth}
      disabled={disabled}
      error={error}
      sx={sx}
    >
      {label && (
        <InputLabel
          variant="standard"
          shrink={shrinkLabel}
          htmlFor={id}
          data-testid={dataTestIdMap.label}
          {...InputLabelProps}
          sx={mergeSx(inputLabelSx, InputLabelProps?.sx)}
        >
          {label}
        </InputLabel>
      )}
      <WBInput
        id={id}
        data-testid={dataTestIdMap.input}
        endAdornment={
          !shouldHideValidationAdornmentIcon && (
            <ValidationAdornmentIcon error={error} disabled={disabled} isDirty={isDirty} />
          )
        }
        {...InputProps}
        {...rest}
      />
      {error && !!errorMessage && !shouldHideErrorMessage && (
        <ErrorMessage
          data-testid={dataTestIdMap.error}
          {...ErrorMessageProps}
          className={clsx(
            {
              [formHelperTextClasses.disabled]: disabled,
            },
            ErrorMessageProps?.className
          )}
          component="p"
          ownerState={ownerState}
        >
          {errorMessage}
        </ErrorMessage>
      )}
      {helperText && (
        <FormHelperText
          data-testid={dataTestIdMap.helperText}
          {...HelperTextProps}
          error={false}
          sx={mergeSx(helperTextSx, HelperTextProps?.sx)}
        >
          {helperText}
        </FormHelperText>
      )}
    </FormControlRoot>
  );
};
