import { HttpException } from './HttpException';

export class UnexpectedErrorException extends HttpException {
  static readonly API_CODE = 'UNEXPECTED_ERROR';

  constructor(message: string, httpCode?: number) {
    super(message, httpCode);

    Object.setPrototypeOf(this, UnexpectedErrorException.prototype);
  }

  getApiCode(): string {
    return UnexpectedErrorException.API_CODE;
  }
}
