import { List } from 'immutable';
import { SimpleModel } from 'model/SimpleModel';

import { CustomQuestionOption } from './CustomQuestionOption';
import { DiscreteQuestion } from './DiscreteQuestion';
import { PageItemKind } from './PageItemKind';
import { Question } from './Question';

export namespace QuestionCheckbox {
  export interface Shape extends Question.Shape {
    options: List<CustomQuestionOption>;
    limitAnswerCount?: number;
    isRequired?: boolean;
    hasOther?: boolean;
    numberingSkipped?: boolean;
  }
}

export class QuestionCheckbox extends Question<QuestionCheckbox.Shape> implements DiscreteQuestion {
  constructor(initialData: SimpleModel.Data<QuestionCheckbox.Shape>) {
    super(initialData, PageItemKind.QuestionCheckbox);
  }

  getOptions(): List<CustomQuestionOption> {
    return this.get('options');
  }

  isRequired(): boolean {
    return this.get('isRequired');
  }

  hasOther(): boolean {
    return this.get('hasOther');
  }

  getLimitAnswerCount(): number {
    return this.get('limitAnswerCount');
  }

  getAvailableOptions(): List<CustomQuestionOption> {
    return this.getOptions();
  }

  isNumberingSkipped(): boolean {
    return this.get('numberingSkipped');
  }
}
