import { colors } from 'app/theme/config';
import { Components, SxProps, Theme } from '@mui/material';
import { ThemeModeEnum } from 'shared/enums/ThemeModeEnum';

const light: Components<Theme>['MuiSelect'] = {
  styleOverrides: {
    select: {
      color: colors.oxfordBlue,
      fontWeight: 700,

      padding: '24px',

      '&[aria-expanded="true"] ~ fieldset': {
        borderColor: colors.oxfordBlue + '!important',
      },

      '~ .MuiSelect-icon': {
        fontSize: '36px',
        color: colors.oxfordBlue,
      },

      '.MuiSelect-iconOpen': {
        transform: 'rotate(180deg)',
      },
    },
  },
};

const highContrastAccessible: Components<Theme>['MuiSelect'] = {
  styleOverrides: {
    select: {
      color: colors.candyCorn,
      fontWeight: 700,

      padding: '24px',

      '&[aria-expanded="true"] ~ fieldset': {
        borderColor: colors.malibu + '!important',
      },

      '~ .MuiSelect-icon': {
        fontSize: '36px',
        color: colors.candyCorn,
      },

      '.MuiSelect-iconOpen': {
        transform: 'rotate(180deg)',
      },
    },
  },
};

const WBSelectMenuStyles: SxProps<Theme> = (theme) => {
  const config = getWBSelectMenuConfig(theme.themeName);

  return {
    mt: 1,
    background: config.dropdownBackground,
    border: `3px solid ${config.dropdownBorderColor}`,

    '& .MuiMenuItem-root': {
      color: config.itemColor,
      fontWeight: 700,
      padding: '16px 24px',

      '&.Mui-selected': {
        color: config.itemSelectedColor,
        background: config.itemSelectedBackground,

        '&:focus-visible': {
          background: config.itemSelectedBackground,
        },

        '&:hover': {
          background: config.itemHoverBackground,
        },
      },

      '&:hover': {
        color: config.itemHoverColor,
        background: config.itemHoverBackground,
      },
    },
  };
};

function getWBSelectMenuConfig(themeName: ThemeModeEnum) {
  switch (themeName) {
    case ThemeModeEnum.HIGH_CONTRAST_ACCESSIBLE:
      return {
        dropdownBackground: colors.black,
        dropdownBorderColor: colors.white,
        itemColor: colors.candyCorn,
        itemSelectedColor: colors.black,
        itemSelectedBackground: colors.malibu,
        itemHoverColor: colors.black,
        itemHoverBackground: colors.white,
      };
    default:
      return {
        dropdownBackground: colors.white,
        dropdownBorderColor: colors.alto,
        itemColor: colors.oxfordBlue,
        itemSelectedColor: colors.white,
        itemSelectedBackground: colors.ebonyClay,
        itemHoverColor: colors.white,
        itemHoverBackground: colors.oxfordBlue,
      };
  }
}

export const MuiSelectComp = {
  light,
  highContrastAccessible,
  WBSelectMenuStyles,
};
