import { Map } from 'immutable';
import { SimpleModel } from 'model/SimpleModel';

import { AsyncModel } from './AsyncModel';
import { LogicType } from './LogicType';

export namespace Logic {
  export interface Shape {
    id?: number;
    type?: LogicType;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: unknown;
  }
}

export abstract class Logic<S extends Logic.Shape = Logic.Shape> extends AsyncModel<S> {
  protected constructor(initialData: SimpleModel.Data<S>, type?: LogicType) {
    const map = Map.isMap(initialData) ? (initialData as Map<string, unknown>) : Map(initialData);

    super(type !== undefined ? map.set('type', type) : map);
  }

  getId(): number {
    return this.get('id');
  }

  getType(): LogicType {
    return this.get('type');
  }
}
