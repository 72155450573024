import {
  AsyncAction,
  isActionPending,
  isActionRejected,
  isActionResolved,
} from 'action/asyncAction';

export function markAsyncAction(action: AsyncAction<unknown>): AsyncAction<unknown> {
  if (isActionPending(action)) {
    return { ...action, type: `${action.type} ⌛` };
  }
  if (isActionRejected(action)) {
    return { ...action, type: `${action.type} ✖` };
  }
  if (isActionResolved(action)) {
    return { ...action, type: `${action.type} ✔` };
  }

  return action;
}
