import { useDispatch, useSelector } from 'react-redux';

import { fetchSurvey } from 'action/surveyAction';
import { useLanguage } from 'translations/LanguageProvider';
import { selectAccessCode, selectDistributionSlug } from 'selector/navigationSelector';
import { LangCodeEnum } from 'shared/enums/LangCodeEnum';
import { useSessionStorage } from 'shared/hooks/useSessionStorage';
import { PREFERRED_LANGUAGE_STORAGE_KEY } from 'view/WithPreferredLanguage/WithPreferredLanguage';
import { selectHasMatch } from 'selector/routerSelector';
import { SurveyRoute } from 'app/route';

export const useSetChangeLanguageCallback = () => {
  const dispatch = useDispatch();
  const slug = useSelector(selectDistributionSlug);
  const accessCode = useSelector(selectAccessCode);

  const { setChangeLanguageCallback } = useLanguage();
  const [, setSessionStoragePreferredLanguage] = useSessionStorage<LangCodeEnum>(
    PREFERRED_LANGUAGE_STORAGE_KEY
  );
  const shouldFetchSurvey = !useSelector(
    selectHasMatch(
      SurveyRoute.Survey.SUCCESS_PATTERN,
      SurveyRoute.Survey.CLOSED_PATTERN,
      SurveyRoute.Survey.SAVE_SUCCESS_PATTERN,
      SurveyRoute.Survey.KIOSK_PATTERN
    )
  );

  setChangeLanguageCallback((locale: LangCodeEnum) => {
    setSessionStoragePreferredLanguage(locale);
    if (shouldFetchSurvey) {
      return dispatch(fetchSurvey({ accessCode, slug }));
    }
  });
};
