import { RouterState } from 'connected-react-router';

import { AccessCode } from 'model/AccessCode';
import { AvailableSurvey } from 'model/AvailableSurvey';
import { Project } from 'model/Project';
import { Meta } from 'model/Meta';
import { Resource } from 'model/Resource';

import { ModalState } from './ModalState';
import { SnackbarState } from './SnackbarState';
import { SubmitState } from './SubmitState';

export namespace SurveyState {
  export const INITIAL_META_RESOURCE: Resource<Meta> = Resource.void();
  export const INITIAL_SURVEY_RESOURCE: Resource<Project> = Resource.void();
  export const INITIAL_ACCESS_CODE_INFORMATION_RESOURCE: Resource<AccessCode> = Resource.void();
  export const INITIAL_ERROR: unknown = null;
  export const INITIAL_AVAILABLE_SURVEYS: SurveyState['availableSurveys'] = Resource.void();
  export const INITIAL: SurveyState = {
    surveyResource: INITIAL_SURVEY_RESOURCE,
    accessCode: INITIAL_ACCESS_CODE_INFORMATION_RESOURCE,
    error: INITIAL_ERROR,
    availableSurveys: INITIAL_AVAILABLE_SURVEYS,
  };
}

export type SurveyState = Partial<{ router: RouterState }> &
  ModalState &
  SnackbarState &
  SubmitState & {
    availableSurveys?: Resource<AvailableSurvey.Instance[]>;
    metaResource?: Resource<Meta>;
    surveyResource?: Resource<Project>;
    accessCode?: Resource<AccessCode>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error?: unknown;
  };
