import { HttpException } from './HttpException';

export class NoAvailableSurveysException extends HttpException {
  static readonly API_CODE = 'NO_SURVEYS_AVAILABLE';

  constructor(message: string, httpCode?: number) {
    super(message, httpCode);
    Object.setPrototypeOf(this, NoAvailableSurveysException.prototype);
  }
}
