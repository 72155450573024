import { SimpleModel } from 'model/SimpleModel';

export namespace Answer {
  export interface Shape {
    id?: number;
    option?: number;
    answer?: string;
    question?: number;
  }
}

export class Answer extends SimpleModel<Answer.Shape> {
  getId(): number {
    return this.get('id');
  }

  getOption(): number {
    return this.get('option');
  }

  hasOption(): boolean {
    return Number.isFinite(this.getOption());
  }

  getAnswer(): string {
    return this.get('answer');
  }

  hasAnswer(): boolean {
    return !!this.getAnswer();
  }

  getQuestion(): number {
    return this.get('question');
  }
}
