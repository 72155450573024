import { createTheme, ThemeOptions } from '@mui/material/styles';
import { mergeStrict } from 'shared/utils/merge/mergeStrict';
import { MuiFormControlLabelComp } from 'app/theme/components/MuiFormControlLabel.comp';
import { tooltipClasses } from '@mui/material';
import { ThemeModeEnum } from 'shared/enums/ThemeModeEnum';

import { WBAnswerToggleButtonComp } from './components/WBAnswerToggleButton.comp';
import { baseThemeOptions } from './base';
import { highContrastAccessiblePalette } from './palettes/highContrastAccessible';
import { WBAnswerToggleButtonGroupComp } from './components/WBAnswerToggleButtonGroup.comp';
import { colors, inputBorderWidthPx, highContrastInputBorderWidthFocusedPx } from './config';
import { buttonStrikeoutHighContrast } from './utils/buttonStrikeout';
import { MuiButtonComp } from './components/MuiButton.comp';
import { MuiSelectComp } from './components/MuiSelect.comp';

const highContrastAccessibleThemeOptions: ThemeOptions = {
  themeName: ThemeModeEnum.HIGH_CONTRAST_ACCESSIBLE,

  components: {
    MuiFormControlLabel: MuiFormControlLabelComp.highContrastAccessible,
    WBAnswerToggleButton: WBAnswerToggleButtonComp.highContrastAccessible,
    WBAnswerToggleButtonGroup: WBAnswerToggleButtonGroupComp.highContrastAccessible,
    MuiButton: MuiButtonComp.highContrastAccessible,

    WBCircleWithLabel: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          border: `2px solid ${colors.white}`,
        },
      },
    },

    WBToggleIconButton: {
      styleOverrides: {
        root: {
          color: colors.candyCorn,
          outlineOffset: '-1px',

          '&:focus': {
            outline: `4px solid ${colors.candyCorn}`,
          },
          '&:hover': {
            background: colors.white,
            color: colors.black,
            borderColor: colors.white,
            outlineColor: colors.white,
          },
          '&:active': {
            borderColor: colors.malibu,
            outline: `2px solid ${colors.malibu}`,
          },
          '&:disabled': {
            color: colors.white,
            background: buttonStrikeoutHighContrast(colors.black, colors.white),
          },
        },
      },
    },

    WBTooltip: {
      styleOverrides: {
        root: {
          [`& .${tooltipClasses.arrow}`]: { color: colors.white },
          [`& .${tooltipClasses.tooltip}`]: {
            color: colors.black,
            backgroundColor: colors.white,
          },
        },
      },
    },

    WBLinearProgress: {
      styleOverrides: {
        root: {
          background: colors.black,
        },
      },
    },

    MuiLink: {
      styleOverrides: {
        root: {
          color: colors.candyCorn,
          textDecorationColor: colors.candyCorn,

          '&:hover': {
            color: colors.white,
          },
          '&:focus:not(:active)': {
            color: colors.candyCorn,
            outlineColor: colors.malibu,
          },
          '&:active': {
            color: colors.candyCorn,
          },
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& fieldset': {
            border: `${inputBorderWidthPx} solid ${colors.candyCorn}`,
          },
          '&.Mui-focused:not(.Mui-disabled) fieldset': {
            border: `${highContrastInputBorderWidthFocusedPx} solid ${colors.candyCorn}`,
          },
          '&:hover:not(.Mui-disabled) .MuiOutlinedInput-notchedOutline': {
            border: `${highContrastInputBorderWidthFocusedPx} solid ${colors.white}`,
          },

          '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            border: `${inputBorderWidthPx} solid ${colors.white}`,
            background: buttonStrikeoutHighContrast(colors.black, colors.white),
          },
        },
      },
    },

    MuiSelect: MuiSelectComp.highContrastAccessible,
  },

  typography: {
    allVariants: {
      color: colors.white,
    },
  },

  palette: highContrastAccessiblePalette,
};

export const highContrastAccessibleTheme = createTheme(
  mergeStrict<ThemeOptions>(baseThemeOptions, highContrastAccessibleThemeOptions)
);
