import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LangCodeEnum } from 'shared/enums/LangCodeEnum';
import { Grid, Typography, useTheme } from '@mui/material';
import { useDimensions } from 'shared/hooks/useDimensions';
import { LangNamespaceEnum } from 'shared/enums/LangNamespaceEnum';
import {
  PreferredLanguagePickerAnimationContainer,
  PreferredLanguagePickerContainer,
} from 'component/PreferredLanguagePicker/PreferredLanguagePicker.s';
import { PreferredLanguagePickerItem } from 'component/PreferredLanguagePicker/PreferredLanguagePickerItem';
import { chunkArrayToArraysWithNoOrphans } from 'component/PreferredLanguagePicker/chunkArrayToArraysWithNoOrphans';
import { FadeChildren } from 'component/FadeChildren/FadeChildren';

export type PreferredLanguagePickerProps = {
  enabledLocales: readonly LangCodeEnum[];
};

export const PreferredLanguagePicker: React.VFC<PreferredLanguagePickerProps> = ({
  enabledLocales,
}) => {
  const { t } = useTranslation(LangNamespaceEnum.SURVEY_UI_COMPONENTS);

  const [languagesRows, setLanguagesRows] = useState<LangCodeEnum[][]>([]);
  const { dimensions } = useDimensions();
  const theme = useTheme();
  const isMobileView = dimensions.width < theme.breakpoints.values.sm;

  useEffect(() => {
    const rowChunkSize = isMobileView ? 4 : 8;
    setLanguagesRows(chunkArrayToArraysWithNoOrphans(enabledLocales, rowChunkSize));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobileView]);

  return (
    <PreferredLanguagePickerContainer data-testid="preferredLanguagePicker">
      <PreferredLanguagePickerAnimationContainer data-testid="selectLanguageTitleContainer">
        <FadeChildren fadeInTime={1500} fadeOutTime={1500} displayTime={2000}>
          {enabledLocales.map<React.ReactElement>((lng) => (
            <Typography key={lng} variant="h2">
              {t('selectLanguage.title', { lng, ns: 'surveyUICommon' })}
            </Typography>
          ))}
        </FadeChildren>
      </PreferredLanguagePickerAnimationContainer>
      {languagesRows.map((languages, index) => (
        <Grid
          container
          key={index}
          data-testid="preferredLanguagePickerRow"
          columns={{ xs: 4, sm: 8 }}
          sx={{ maxWidth: { xs: '375px', sm: 'none' } }}
          justifyContent="center"
        >
          {languages.map((language) => (
            <Grid item key={language} xs={1} sm={1} sx={{ wordBreak: 'break-word' }}>
              <PreferredLanguagePickerItem language={language} />
            </Grid>
          ))}
        </Grid>
      ))}
    </PreferredLanguagePickerContainer>
  );
};
