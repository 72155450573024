import { List } from 'immutable';
import { SimpleModel } from 'model/SimpleModel';

import { CustomQuestionOption } from './CustomQuestionOption';
import { DiscreteQuestion } from './DiscreteQuestion';
import { PageItemKind } from './PageItemKind';
import { Question } from './Question';

export namespace QuestionRadio {
  export interface Shape extends Question.Shape {
    options: List<CustomQuestionOption>;
    isRequired?: boolean;
    hasOther?: boolean;
  }
}

export class QuestionRadio extends Question<QuestionRadio.Shape> implements DiscreteQuestion {
  constructor(initialData: SimpleModel.Data<QuestionRadio.Shape>) {
    super(initialData, PageItemKind.QuestionRadioButton);
  }

  getOptions(): List<CustomQuestionOption> {
    return this.get('options', List<CustomQuestionOption>());
  }

  isRequired(): boolean {
    return this.get('isRequired');
  }

  hasOther(): boolean {
    return this.get('hasOther');
  }

  getAvailableOptions(): List<CustomQuestionOption> {
    return this.getOptions();
  }
}
