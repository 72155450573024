import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import bangladesh from 'app/public/flags/bangladesh.svg';
import belgium from 'app/public/flags/belgium.svg';
import bosniaAndHerzegovina from 'app/public/flags/bosnia-and-herzegovina.svg';
import brazil from 'app/public/flags/brazil.svg';
import bulgaria from 'app/public/flags/bulgaria.svg';
import burmese from 'app/public/flags/burmese.svg';
import china from 'app/public/flags/china.svg';
import croatia from 'app/public/flags/croatia.svg';
import czechRepublic from 'app/public/flags/czech-republic.svg';
import denmark from 'app/public/flags/denmark.svg';
import finland from 'app/public/flags/finland.svg';
import france from 'app/public/flags/france.svg';
import germany from 'app/public/flags/germany.svg';
import hungary from 'app/public/flags/hungary.svg';
import india from 'app/public/flags/india.svg';
import indonesia from 'app/public/flags/indonesia.svg';
import iran from 'app/public/flags/iran.svg';
import ireland from 'app/public/flags/ireland.svg';
import italy from 'app/public/flags/italy.svg';
import japan from 'app/public/flags/japan.svg';
import kenya from 'app/public/flags/kenya.svg';
import korea from 'app/public/flags/korea.svg';
import latvia from 'app/public/flags/latvia.svg';
import lithuania from 'app/public/flags/lithuania.svg';
import madagascar from 'app/public/flags/madagascar.svg';
import mexico from 'app/public/flags/mexico.svg';
import netherlands from 'app/public/flags/netherlands.svg';
import norway from 'app/public/flags/norway.svg';
import pakistan from 'app/public/flags/pakistan.svg';
import philippines from 'app/public/flags/philippines.svg';
import poland from 'app/public/flags/poland.svg';
import portugal from 'app/public/flags/portugal.svg';
import romania from 'app/public/flags/romania.svg';
import russia from 'app/public/flags/russia.svg';
import serbia from 'app/public/flags/serbia.svg';
import slovakia from 'app/public/flags/slovakia.svg';
import slovenia from 'app/public/flags/slovenia.svg';
import southAfrica from 'app/public/flags/southAfrica.svg';
import spain from 'app/public/flags/spain.svg';
import sriLanka from 'app/public/flags/sri-lanka.svg';
import sweden from 'app/public/flags/sweden.svg';
import tetun from 'app/public/flags/timor-leste.svg';
import tsonga from 'app/public/flags/tsonga.svg';
import turkey from 'app/public/flags/turkey.svg';
import ukraine from 'app/public/flags/ukraine.svg';
import unitedArabEmirates from 'app/public/flags/united-arab-emirates.svg';
import unitedKingdom from 'app/public/flags/united-kingdom.svg';
import unitedStates from 'app/public/flags/united-states.svg';
import vietnam from 'app/public/flags/vietnam.svg';
import wales from 'app/public/flags/wales.svg';
import { LangCodeEnum } from 'shared/enums/LangCodeEnum';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { selectMeta } from 'selector/metaSelector';

export const flagByLanguageMapDefaults: Record<LangCodeEnum, string> = {
  [LangCodeEnum.AFRIKAANS]: southAfrica,
  [LangCodeEnum.ARABIC_UAE]: unitedArabEmirates,
  [LangCodeEnum.BELGIAN]: belgium,
  [LangCodeEnum.BENGALI]: bangladesh,
  [LangCodeEnum.BOSNIAN]: bosniaAndHerzegovina,
  [LangCodeEnum.BULGARIAN]: bulgaria,
  [LangCodeEnum.BURMESE]: burmese,
  [LangCodeEnum.CHINESE_DEFAULT]: china,
  [LangCodeEnum.CHINESE_SIMPLIFIED]: china,
  [LangCodeEnum.CHINESE_TRADITIONAL]: china,
  [LangCodeEnum.CROATIAN]: croatia,
  [LangCodeEnum.CZECH]: czechRepublic,
  [LangCodeEnum.DANISH]: denmark,
  [LangCodeEnum.DUTCH]: netherlands,
  [LangCodeEnum.ENGLISH_AMERICAN]: unitedStates,
  [LangCodeEnum.ENGLISH_BRITISH]: unitedKingdom,
  [LangCodeEnum.ENGLISH_DEFAULT]: unitedKingdom,
  [LangCodeEnum.FILIPINO]: philippines,
  [LangCodeEnum.FINNISH]: finland,
  [LangCodeEnum.FRENCH]: france,
  [LangCodeEnum.GERMAN]: germany,
  [LangCodeEnum.GUJARATI]: india,
  [LangCodeEnum.HINDI]: india,
  [LangCodeEnum.HUNGARIAN]: hungary,
  [LangCodeEnum.INDONESIAN]: indonesia,
  [LangCodeEnum.IRISH]: ireland,
  [LangCodeEnum.ITALIAN]: italy,
  [LangCodeEnum.JAPANESE]: japan,
  [LangCodeEnum.KOREAN]: korea,
  [LangCodeEnum.LATVIAN]: latvia,
  [LangCodeEnum.LITHUANIAN]: lithuania,
  [LangCodeEnum.MALAGASY]: madagascar,
  [LangCodeEnum.NORWEGIAN]: norway,
  [LangCodeEnum.PERSIAN]: iran,
  [LangCodeEnum.POLISH]: poland,
  [LangCodeEnum.PORTUGUESE]: portugal,
  [LangCodeEnum.PORTUGUESE_BRAZILIAN]: brazil,
  [LangCodeEnum.PORTUGUESE_DEFAULT]: portugal,
  [LangCodeEnum.PUNJABI_INDIAN]: india,
  [LangCodeEnum.ROMANIAN]: romania,
  [LangCodeEnum.RUSSIAN]: russia,
  [LangCodeEnum.SERBIAN]: serbia,
  [LangCodeEnum.SLOVAKIAN]: slovakia,
  [LangCodeEnum.SLOVENIAN]: slovenia,
  [LangCodeEnum.SPANISH]: spain,
  [LangCodeEnum.SPANISH_DEFAULT]: spain,
  [LangCodeEnum.SPANISH_MEXICAN]: mexico,
  [LangCodeEnum.SWAHILI]: kenya,
  [LangCodeEnum.SWEDISH]: sweden,
  [LangCodeEnum.TAMIL]: sriLanka,
  [LangCodeEnum.TETUN]: tetun,
  [LangCodeEnum.TSONGA]: tsonga,
  [LangCodeEnum.TURKISH]: turkey,
  [LangCodeEnum.UKRAINIAN]: ukraine,
  [LangCodeEnum.URDU_PAKISTAN]: pakistan,
  [LangCodeEnum.VIETNAMESE]: vietnam,
  [LangCodeEnum.WELSH]: wales,
  [LangCodeEnum.XHOSA]: southAfrica,
};

type SurveyFlagsOverridesType = {
  language: LangCodeEnum;
  flagNameToUse: string;
  projectIds: number[];
}[];

export const useGetFlagByLanguageMap = () => {
  const projectId = useSelector(selectMeta)?.getProjectId();
  const [flagByLanguageMap, setFlagByLanguageMap] = useState(flagByLanguageMapDefaults);
  const surveyFlagsOverrides = useFeatureValue<SurveyFlagsOverridesType>(
    'multi-language.survey-flags-overrides',
    []
  );

  useEffect(() => {
    // Flags overriding logic specified in GrowthBook
    try {
      surveyFlagsOverrides.forEach((overrideRule) => {
        if (overrideRule.projectIds.includes(projectId)) {
          import(`app/public/flags/${overrideRule.flagNameToUse}`).then(
            ({ default: importedFlag }) => {
              setFlagByLanguageMap({ ...flagByLanguageMap, [overrideRule.language]: importedFlag });
            }
          );
        }
      });
    } catch (error) {
      console.warn(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, surveyFlagsOverrides]);

  return flagByLanguageMap;
};
