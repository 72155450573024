import { List } from 'immutable';

import { PageItem } from './PageItem';
import { PageItemKind } from './PageItemKind';
import { StaticResource } from './StaticResource';

export namespace ImageWidget {
  export interface Shape extends PageItem.Shape {
    image: StaticResource;
    // TODO improve this type
    imageNew?: Map<'url' | 'file', string | File>;
    width?: number;
    height?: number;
    align?: string;

    [key: string]: unknown;
  }
}

export class ImageWidget extends PageItem<ImageWidget.Shape> {
  static search(images: List<ImageWidget>, search: string): List<ImageWidget> {
    return images.filter((image) => image.getName().includes(search)).toList();
  }

  constructor(initialData: ImageWidget.Shape) {
    super(initialData, PageItemKind.ImageWidget);
  }

  getImage(): StaticResource {
    return this.get('image');
  }

  // TODO improve this type
  getImageNew(): ImageWidget.Shape['imageNew'] {
    return this.get('imageNew');
  }

  getName(): string {
    return this.getImage() && this.getImage().getFileOriginalName();
  }

  getWidth(): number {
    return this.get('width');
  }

  getHeight(): number {
    return this.get('height');
  }

  getAlign(): string {
    return this.get('align');
  }
}
