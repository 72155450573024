import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Icon,
  IconButton,
  ListItem,
  SwipeableDrawerProps as MuiSwipeableDrawerProps,
  Typography,
} from '@mui/material';
import { LanguageList } from 'component/LanguageList/LanguageList';
import { LangNamespaceEnum } from 'shared/enums/LangNamespaceEnum';
import { LangCodeEnum } from 'shared/enums/LangCodeEnum';

import { LanguageDrawStyled, drawerHeaderSx } from './LanguageDrawer.s';

export type LanguageDrawerProps = MuiSwipeableDrawerProps & {
  currentLanguage: LangCodeEnum;
  languages: Array<LangCodeEnum>;
  onSelection: (language: LangCodeEnum) => void;
  onClose: () => void;
};

export const LanguageDrawer: React.VFC<LanguageDrawerProps> = ({
  currentLanguage,
  languages,
  onSelection,
  onClose,
  ...props
}) => {
  const { t } = useTranslation(LangNamespaceEnum.SURVEY_UI_COMPONENTS);

  return (
    <LanguageDrawStyled anchor="right" onClose={onClose} {...props}>
      <ListItem component={'div'} sx={drawerHeaderSx}>
        <Typography component="span" variant="subtitle1" fontWeight={700}>
          {t('LanguageDrawer.title')}
        </Typography>
        <IconButton aria-label="close" onClick={onClose}>
          <Icon>close</Icon>
        </IconButton>
      </ListItem>
      <LanguageList
        languages={languages}
        onSelection={(language) => {
          onClose();
          onSelection(language);
        }}
        currentLanguage={currentLanguage}
      />
    </LanguageDrawStyled>
  );
};
