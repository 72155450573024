import React from 'react';
import { useTranslation } from 'react-i18next';

import { LangCodeEnum } from 'shared/enums/LangCodeEnum';
import { Typography } from '@mui/material';
import { Flag } from 'component/Flag/Flag';
import { LangNamespaceEnum } from 'shared/enums/LangNamespaceEnum';
import { useSessionStorage } from 'shared/hooks/useSessionStorage';
import { PREFERRED_LANGUAGE_STORAGE_KEY } from 'view/WithPreferredLanguage/WithPreferredLanguage';
import { useLanguage } from 'translations/LanguageProvider';
import { PreferredLanguagePickerItemButton } from 'component/PreferredLanguagePicker/PreferredLanguagePicker.s';

export type LanguagePickerItemProps = {
  language: LangCodeEnum;
};

export const PreferredLanguagePickerItem: React.VFC<LanguagePickerItemProps> = ({ language }) => {
  const { t } = useTranslation(LangNamespaceEnum.SHARED);
  const [, setSessionStoragePreferredLanguage] = useSessionStorage<LangCodeEnum>(
    PREFERRED_LANGUAGE_STORAGE_KEY
  );
  const { changeLanguage } = useLanguage();

  const onPreferredLanguageClick = () => {
    changeLanguage(language).then(() => setSessionStoragePreferredLanguage(language));
  };

  return (
    <PreferredLanguagePickerItemButton
      data-testid="preferredLanguagePickerItemButton"
      onClick={onPreferredLanguageClick}
    >
      <Flag
        language={language}
        sx={{
          width: { xs: '40px', sm: '50px' },
          height: { xs: '40px', sm: '50px' },
          mb: 1,
          cursor: 'pointer',
        }}
      />
      <Typography component="span" variant="body2">
        {t('language.name', { lng: language })}
      </Typography>
    </PreferredLanguagePickerItemButton>
  );
};
