import React, { useEffect, useState } from 'react';

export const useSessionStorage = <V>(key: string): [V, React.Dispatch<React.SetStateAction<V>>] => {
  const storedValue = sessionStorage.getItem(key);

  const [value, setValue] = useState<V>(JSON.parse(storedValue));

  useEffect(() => {
    const listener = (event: StorageEvent) => {
      if (event.key === key) {
        setValue(JSON.parse(event.newValue));
      }
    };

    window.addEventListener('storage', listener);
    return () => {
      window.removeEventListener('storage', listener);
    };
  }, [key]);

  useEffect(() => {
    const storedValue = JSON.stringify(value);

    sessionStorage.setItem(key, storedValue);
    window.dispatchEvent(new StorageEvent('storage', { key, newValue: storedValue }));
  }, [key, value]);

  return [value, setValue];
};
