import { ProjectApi } from 'api/ProjectApi';
import { List } from 'immutable';
import { PageMapper } from 'mapper/PageMapper';
import { Company } from 'model/Company';
import { Project } from 'model/Project';
import * as moment from 'moment';

import { CategoryMapper } from './CategoryMapper';
import { DistributionMapper } from './DistributionMapper';
import { ProjectLayoutMapper } from './ProjectLayoutMapper';

export class ProjectMapper {
  private pageMapper: PageMapper;
  private projectLayoutMapper: ProjectLayoutMapper;
  private distributionMapper: DistributionMapper;
  private categoryMapper: CategoryMapper;

  constructor(
    pageMapper: PageMapper,
    projectLayoutMapper: ProjectLayoutMapper,
    distributionMapper: DistributionMapper,
    categoryMapper: CategoryMapper
  ) {
    this.categoryMapper = categoryMapper;
    this.distributionMapper = distributionMapper;
    this.projectLayoutMapper = projectLayoutMapper;
    this.pageMapper = pageMapper;
  }

  deserialize(entry: ProjectApi.Entry): Project {
    return new Project({
      id: entry.id,
      name: entry.name,
      company:
        entry.company &&
        new Company({
          id: entry.company.id,
          name: entry.company.name,
          logo: entry.company.logo,
          brandColor: entry.company.brandColor,
        }),
      status: entry.status,
      kind: entry.kind,
      preferredLanguage: entry.preferredLanguage,
      liveStart: entry.liveStart && moment.utc(entry.liveStart),
      liveStop: entry.liveStop && moment.utc(entry.liveStop),
      pages: List(
        (entry.pages || [])
          // Filtering here because there's no use case when we need the empty page data in survey-ui
          .filter((page) => page.items.length > 0)
          .map((pageEntry) => this.pageMapper.deserialize(pageEntry))
      ),
      layout: entry.layout && this.projectLayoutMapper.deserialize(entry.layout),
      distributions: List(
        (entry.distributions || [])
          // we filter because we don't have all distributions implemented
          .filter((distributionEntry) =>
            this.distributionMapper.supportsDeserialize(distributionEntry)
          )
          .map((distributionEntry) => this.distributionMapper.deserialize(distributionEntry))
      ),
      categories: List(
        (entry.categories || []).map((categoryEntry) =>
          this.categoryMapper.deserialize(categoryEntry)
        )
      ),
    });
  }
}
