import { PaperDistributionApi } from 'api/PaperDistributionApi';
import { DistributionChannel } from 'model/DistributionChannel';
import { PaperDistribution } from 'model/PaperDistribution';

import { DistributionMapperByChannel } from './DistributionMapper';

export class PaperDistributionMapper
  implements DistributionMapperByChannel<PaperDistribution, PaperDistributionApi.Entry> {
  deserialize(entry: PaperDistributionApi.Entry): PaperDistribution {
    return new PaperDistribution({
      id: entry.id,
      slug: entry.slug,
      link: entry.surveyLink,
    });
  }

  supportsDeserialize(entry: PaperDistributionApi.Entry): boolean {
    return entry.channel === DistributionChannel.Paper;
  }
}
