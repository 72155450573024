import { LogicApi } from 'api/LogicApi';
import { Logic } from 'model/Logic';

export interface LogicTypeMapper<
  M extends Logic = Logic,
  E extends LogicApi.Entry = LogicApi.Entry
> {
  deserialize(entry: E): M;
  supportsDeserialize(entry: E): boolean;
}

export class LogicMapper implements LogicTypeMapper<Logic, LogicApi.EntryAny> {
  private mappers: LogicTypeMapper[];

  constructor(mappers: LogicTypeMapper[]) {
    this.mappers = mappers;
  }

  deserialize(entry: LogicApi.Entry): Logic {
    return this.findDeserializer(entry).deserialize(entry);
  }

  supportsDeserialize(entry: LogicApi.Entry): boolean {
    return this.mappers.some((mapper) => mapper.supportsDeserialize(entry));
  }

  private findDeserializer(entry: LogicApi.Entry): LogicTypeMapper {
    const deserializer = this.mappers.find((mapper) => mapper.supportsDeserialize(entry));

    if (!deserializer) {
      throw new Error('Cannot find deserializer for logic entry: ' + JSON.stringify(entry));
    }

    return deserializer;
  }
}
