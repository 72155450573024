import React from 'react';

import { px } from 'util/transformToPixels';

import { Button as MuiButton, ButtonProps as MuiButtonProps, styled } from '@mui/material';
import {
  largePadding,
  mobileLargePadding,
  mobileMediumPadding,
  mediumPadding,
} from 'app/theme/components/MuiButton.comp';
import { mediaQueryMode } from 'shared/constants/mediaQueryMode';

type animateIconType = 'left' | 'right' | 'none';

export type ButtonProps = MuiButtonProps & {
  animateIcon?: animateIconType;
  minWidth?: boolean;
};

const RootButton: React.ForwardRefExoticComponent<ButtonProps> = React.forwardRef(
  (inProps, ref) => {
    const { animateIcon, minWidth, ...props } = inProps;
    return <MuiButton ref={ref} variant="outlined" {...props} />;
  }
);

const styles = (animateIcon: animateIconType, padding: number) => {
  return {
    left: animateIcon === 'left' ? `-${px(padding / 2)}` : 'auto',
    right: animateIcon === 'right' ? `-${px(padding / 2)}` : 'auto',
  };
};

export const Button = styled(RootButton)(({ animateIcon, minWidth, theme }) => ({
  minWidth: minWidth ? 190 : 'auto',
  '.MuiIcon-root': {
    left: animateIcon === 'left' ? 0 : 'auto',
    right: animateIcon === 'right' ? 0 : 'auto',
    position: 'relative',
    transition:
      animateIcon === 'left'
        ? theme.transitions.create(['left'])
        : theme.transitions.create(['right']),
  },
  '&.MuiButton-sizeSmall:hover .MuiIcon-root, &.MuiButton-sizeMedium:hover .MuiIcon-root': {
    ...styles(animateIcon, mobileMediumPadding),
    [theme.breakpoints.up('sm')]: {
      ...styles(animateIcon, mediumPadding),
    },
    // Reset on touch devices, it doesn't add specificity
    [mediaQueryMode.touchDevices]: {
      left: animateIcon === 'left' ? 0 : 'auto',
      right: animateIcon === 'right' ? 0 : 'auto',
      [theme.breakpoints.up('sm')]: {
        left: animateIcon === 'left' ? 0 : 'auto',
        right: animateIcon === 'right' ? 0 : 'auto',
      },
    },
  },
  '&.MuiButton-sizeLarge:hover .MuiIcon-root': {
    ...styles(animateIcon, mobileLargePadding),
    [theme.breakpoints.up('sm')]: {
      ...styles(animateIcon, largePadding),
    },
    // Reset on touch devices, it doesn't add specificity
    [mediaQueryMode.touchDevices]: {
      left: animateIcon === 'left' ? 0 : 'auto',
      right: animateIcon === 'right' ? 0 : 'auto',
      [theme.breakpoints.up('sm')]: {
        left: animateIcon === 'left' ? 0 : 'auto',
        right: animateIcon === 'right' ? 0 : 'auto',
      },
    },
  },
}));
