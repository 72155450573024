import { Distribution } from './Distribution';
import { DistributionChannel } from './DistributionChannel';

export namespace EmailDistribution {
  export interface Shape extends Distribution.Shape {
    id: string;
  }
}

export class EmailDistribution extends Distribution<EmailDistribution.Shape> {
  getId(): string {
    return this.get('id');
  }

  getChannel(): DistributionChannel {
    return DistributionChannel.Email;
  }
}
