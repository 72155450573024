import { SimpleModel } from 'model/SimpleModel';

export namespace Company {
  export interface Shape {
    id?: number;
    name?: string;
    logo?: string;
    brandColor?: string;
  }
}

export class Company extends SimpleModel<Company.Shape> {
  getId(): number {
    return this.get('id');
  }

  getName(): string {
    return this.get('name');
  }

  setName(name: string): this {
    return this.set('name', name);
  }

  getLogo(): string {
    return this.get('logo');
  }

  setLogo(logo: string): this {
    return this.set('logo', logo);
  }

  getBrandColor(): string {
    return this.get('brandColor');
  }

  setBrandColor(brandColor: string): this {
    return this.set('brandColor', brandColor);
  }
}
