import { Action } from 'redux-actions';

export const OPEN_MODAL = 'OPEN_MODAL';
export type OpenModalAction<P = {}> = Action<{ name: string; params: P }>;
export const openModal = <P = {}>(name: string, params: P = {} as P): OpenModalAction<P> => ({
  type: OPEN_MODAL,
  payload: { name, params },
});

export const UPDATE_MODAL = 'UPDATE_MODAL';
export type UpdateModalAction<P = {}> = Action<P>;
export const updateModal = <P = {}>(params: P): UpdateModalAction<P> => ({
  type: UPDATE_MODAL,
  payload: params,
});

export const CLOSE_MODAL = 'CLOSE_MODAL';
export type CloseModalAction = Action<void>;
export const closeModal = (): CloseModalAction => ({
  type: CLOSE_MODAL,
});
