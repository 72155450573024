import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import workBuzzMobileLogoUrl from 'app/public/workbuzz-logo-stacked.svg';
import workBuzzMobileLogoDarkUrl from 'app/public/workbuzz-logo-stacked-dark.svg';
import { Box, Typography } from '@mui/material';
import { LangNamespaceEnum } from 'shared/enums/LangNamespaceEnum';
import { ThemeContext } from 'context/ThemeContext';
import { mergeSx } from 'shared/utils/merge/mergeSx';
import { ThemeModeEnum } from 'shared/enums/ThemeModeEnum';
import { selectSurveyLogoUrl } from 'selector/metaSelector';

import {
  rootStyles,
  rootHighContrastOverrideStyles,
  textStyles,
  mobileLogoSx,
} from './MobilePoweredByLogo.s';

export const MobilePoweredByLogo: React.VFC = () => {
  const { themeMode } = useContext(ThemeContext);
  const { t } = useTranslation(LangNamespaceEnum.SURVEY_UI_COMPONENTS);
  const surveyLogoUrl = useSelector(selectSurveyLogoUrl);

  const workbuzzLogoFallbackUrl =
    themeMode === ThemeModeEnum.HIGH_CONTRAST_ACCESSIBLE
      ? workBuzzMobileLogoDarkUrl
      : workBuzzMobileLogoUrl;
  const rootSx =
    themeMode === ThemeModeEnum.HIGH_CONTRAST_ACCESSIBLE
      ? mergeSx(rootStyles, rootHighContrastOverrideStyles)
      : rootStyles;

  return (
    <Box sx={rootSx}>
      <Box
        component="img"
        src={surveyLogoUrl || workbuzzLogoFallbackUrl}
        alt="Company Logo"
        sx={mobileLogoSx}
      />
      <Typography variant="body2" sx={textStyles}>
        {t('MobilePoweredByLogo.title')}
      </Typography>
    </Box>
  );
};
