import { useEffect, useState } from 'react';

import { useLanguage } from 'translations/LanguageProvider';
import { LangCodeEnum } from 'shared/enums/LangCodeEnum';
import { useSessionStorage } from 'shared/hooks/useSessionStorage';
import { PREFERRED_LANGUAGE_STORAGE_KEY } from 'view/WithPreferredLanguage/WithPreferredLanguage';

export const useChangeLanguageToPreferred = (preferredLanguage?: LangCodeEnum | null) => {
  const [isLanguageChangeInProgress, setIsLanguageChangeInProgress] = useState(false);
  const { changeLanguage } = useLanguage();
  const [sessionStoragePreferredLanguage] = useSessionStorage<LangCodeEnum>(
    PREFERRED_LANGUAGE_STORAGE_KEY
  );

  useEffect(() => {
    if (!sessionStoragePreferredLanguage && preferredLanguage) {
      setIsLanguageChangeInProgress(true);
      changeLanguage(preferredLanguage).then(() => setIsLanguageChangeInProgress(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preferredLanguage]);

  return { isLanguageChangeInProgress };
};
