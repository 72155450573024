import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Box as MuiBox, Typography } from '@mui/material';
import workBuzzDesktopLogoUrl from 'app/public/workbuzz-logo-colour.svg';
import workBuzzDesktopLogoDarkUrl from 'app/public/workbuzz-logo-dark.svg';
import { LangNamespaceEnum } from 'shared/enums/LangNamespaceEnum';
import { ThemeContext } from 'context/ThemeContext';
import { mergeSx } from 'shared/utils/merge/mergeSx';
import { ThemeModeEnum } from 'shared/enums/ThemeModeEnum';
import { selectSurveyLogoUrl } from 'selector/metaSelector';

import {
  boxInnerStyles,
  boxOuterStyles,
  boxTitleContainerStyles,
  boxBottomTitleContainerStyles,
  boxTitleStyles,
  boxOuterHighContrastOverrideStyles,
  boxTitleClientStyles,
} from './DesktopPoweredByLogo.s';

export const DesktopPoweredByLogo: React.FC = () => {
  const { themeMode } = useContext(ThemeContext);
  const { t } = useTranslation(LangNamespaceEnum.SURVEY_UI_COMPONENTS);
  const surveyLogoUrl = useSelector(selectSurveyLogoUrl);
  const isWorkbuzzCircle = !surveyLogoUrl;
  const imageContainerStyles = isWorkbuzzCircle
    ? boxInnerStyles
    : mergeSx(boxInnerStyles, { marginBottom: '10px' });

  const workbuzzLogoFallbackUrl =
    themeMode === ThemeModeEnum.HIGH_CONTRAST_ACCESSIBLE
      ? workBuzzDesktopLogoDarkUrl
      : workBuzzDesktopLogoUrl;

  const boxOuterStylesSx =
    themeMode === ThemeModeEnum.HIGH_CONTRAST_ACCESSIBLE
      ? mergeSx(boxOuterStyles, boxOuterHighContrastOverrideStyles)
      : boxOuterStyles;

  return (
    <MuiBox sx={boxOuterStylesSx}>
      {isWorkbuzzCircle && (
        <MuiBox sx={boxTitleContainerStyles}>
          <Typography sx={boxTitleStyles} variant="body2">
            {t('DesktopPoweredByLogo.boxTitle')}
          </Typography>
        </MuiBox>
      )}

      <MuiBox sx={imageContainerStyles}>
        <img
          src={surveyLogoUrl || workbuzzLogoFallbackUrl}
          alt={t('DesktopPoweredByLogo.imgAlt')}
          width={93}
        />
      </MuiBox>

      {!isWorkbuzzCircle && (
        <MuiBox sx={boxBottomTitleContainerStyles}>
          <Typography sx={boxTitleClientStyles} variant="body2">
            {t('DesktopPoweredByLogo.boxTitle')} WorkBuzz
          </Typography>
        </MuiBox>
      )}
    </MuiBox>
  );
};
