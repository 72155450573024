import { useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';

import { THEME_MODE_COOKIE } from 'shared/constants/cookies';
import { selectIsKioskDistribution } from 'selector/distributionSelector';
import { ThemeModeEnum } from 'shared/enums/ThemeModeEnum';
import moment from 'moment';

export const useThemeModeCookie = () => {
  const [cookies, setCookie] = useCookies([THEME_MODE_COOKIE]);
  const isKioskDistribution = useSelector(selectIsKioskDistribution);

  const setThemeModeCookie = (themeMode: ThemeModeEnum) => {
    const isNotInSurveyYet = isKioskDistribution === undefined;

    if (isNotInSurveyYet || isKioskDistribution) return;

    const expires = moment().add(1, 'year').toDate();
    setCookie(THEME_MODE_COOKIE, themeMode.toString(), { expires, sameSite: 'none', secure: true });
  };

  return {
    themeMode: cookies[THEME_MODE_COOKIE] as ThemeModeEnum,
    setThemeModeCookie,
    initialTheme: (cookies[THEME_MODE_COOKIE] as ThemeModeEnum) || ThemeModeEnum.LIGHT,
  };
};
