/* eslint-disable @typescript-eslint/no-explicit-any */
import { AsyncModel } from 'model/AsyncModel';
import { Reducer } from 'redux';

import { createAsyncReducer } from './asyncReducer';

/**
 * Creates reducer that reduces async model for async action
 * @see ../action/asyncAction.ts
 */
export function createAsyncModelReducer<T extends AsyncModel<any>, P>(
  asyncType: string,
  mapContent?: (payload: P) => T,
  mapError?: (error: unknown) => unknown,
  initialModel?: T
): Reducer<T> {
  return createAsyncReducer<T>(
    {
      begin: (model) => model.beginAsync(asyncType),
      resolve: (model, action) =>
        model.resolveAsync(asyncType, mapContent ? mapContent(action.payload) : action.payload),
      reject: (model, action) =>
        model.rejectAsync(asyncType, mapError ? mapError(action.payload) : action.payload),
    },
    initialModel
  );
}
