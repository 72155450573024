import { LogicApi } from 'api/LogicApi';
import { List } from 'immutable';
import { LogicDependency } from 'model/LogicDependency';
import { LogicType } from 'model/LogicType';

import { LogicTypeMapper } from './LogicMapper';

export class LogicDependencyMapper
  implements LogicTypeMapper<LogicDependency, LogicApi.DependencyEntry> {
  deserialize(entry: LogicApi.DependencyEntry): LogicDependency {
    return new LogicDependency({
      id: entry.id,
      type: entry.type,
      questionId: entry.question,
      options: List<number>(entry.options || []),
      mode: entry.mode,
    });
  }

  supportsDeserialize(entry: LogicApi.Entry): boolean {
    return entry.type === LogicType.Dependency;
  }
}
