import { RouterState } from 'connected-react-router';
import { match, matchPath } from 'react-router';

import memoize from 'fast-memoize';
import { Location } from 'history';
import { parse as parseQuery } from 'query-string';
import { createSelector, Selector } from 'reselect';

type RouterStateAware = { router?: Partial<RouterState>; [key: string]: unknown };

export function selectLocation(state: RouterStateAware): Location {
  return state && state.router && state.router.location;
}

export const selectLocationPath = createSelector(
  selectLocation,
  (location) => (location && location.pathname) || undefined
);

export const selectQueryString = createSelector(selectLocation, (location) => {
  let queryString = (location && location.search) || '';
  if (queryString.startsWith('?')) {
    queryString = queryString.substring(1);
  }

  return queryString;
});

export const selectQueryParams = createSelector(selectQueryString, (queryString) =>
  parseQuery(queryString)
);

export const selectQueryParamForMatch: <T>(
  param: string,
  ...routes: string[]
) => Selector<unknown, T> = memoize(
  (param: string, ...routes: string[]) =>
    createSelector(selectQueryParams, selectMatch(...routes), (params, match) =>
      match ? params[param] : undefined
    ) as Selector<unknown, unknown>,
  {
    strategy: memoize.strategies.variadic,
  }
);

export const selectMatch: <P>(...routes: string[]) => Selector<unknown, match<P>> = memoize(
  (...routes: string[]) =>
    createSelector(
      selectLocationPath,
      (currentPath) =>
        currentPath &&
        routes.reduce(
          (match, route) => match || matchPath(currentPath, { path: route, exact: false }),
          null
        )
    ) as Selector<unknown, match<unknown>>,
  {
    strategy: memoize.strategies.variadic,
  }
);

export const selectExactMatch = (...routes: string[]) =>
  createSelector(
    selectLocationPath,
    (currentPath) =>
      currentPath &&
      routes.reduce(
        (match, route) => match || matchPath(currentPath, { path: route, exact: true }),
        null
      )
  );

export const selectHasMatch: (...routes: string[]) => Selector<unknown, boolean> = memoize(
  (...routes: string[]) =>
    createSelector(selectMatch(...routes), (match) => !!match) as Selector<unknown, boolean>,
  { strategy: memoize.strategies.variadic }
);

export const selectHasExactMatch: (...routes: string[]) => Selector<unknown, boolean> = memoize(
  (...routes: string[]) =>
    createSelector(selectExactMatch(...routes), (match) => !!match) as Selector<unknown, boolean>,
  { strategy: memoize.strategies.variadic }
);
