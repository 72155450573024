import { px } from 'util/transformToPixels';

import {
  breakpoints,
  buttonBorderWidth,
  buttonBorderWidthPx,
  buttonOutlineWidthPx,
  colors,
  focusBorderColor,
  mobileButtonBorderWidth,
  pxToRem,
  typography,
} from 'app/theme/config';
import { Components, CSSInterpolation } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { mergeStrict } from 'shared/utils/merge/mergeStrict';
import { common } from '@mui/material/colors';
import { mediaQueryMode } from 'shared/constants/mediaQueryMode';
import { buttonStrikeoutHighContrast } from 'app/theme/utils/buttonStrikeout';

import { lightPalette } from '../palettes/light';

export const mobileMediumPadding = 20;
export const mediumPadding = 28;

export const mobileLargePadding = 28;
export const largePadding = 36;

const buttonOutlineOffsetPx = '-3px';
const buttonOutlineWidthWithOffsetPx = '6px';

const base: Components<Theme>['MuiButton'] = {
  styleOverrides: {
    iconSizeSmall: {
      '& > *:first-of-type': {
        fontSize: pxToRem(20),
      },
    },
    iconSizeMedium: {
      '& > *:first-of-type': {
        fontSize: pxToRem(30),
      },
    },
    iconSizeLarge: {
      '& > *:first-of-type': {
        fontSize: pxToRem(35),
      },
    },
    sizeSmall: mergeStrict<CSSInterpolation>(typography.body2, {
      fontWeight: 700,
      minHeight: 39,
      padding: `0 ${px(mobileMediumPadding)}`,
      [breakpoints.up('sm')]: {
        minHeight: 40,
        padding: `0 ${px(mediumPadding)}`,
      },
    }),
    sizeMedium: mergeStrict<CSSInterpolation>(typography.h5, {
      minHeight: 39,
      padding: `0 ${px(mobileMediumPadding)}`,
      [breakpoints.up('sm')]: {
        minHeight: 50,
        padding: `0 ${px(mediumPadding)}`,
      },
    }),
    sizeLarge: mergeStrict<CSSInterpolation>(typography.h5, {
      minHeight: 48,
      padding: `0 ${px(mobileLargePadding)}`,
      [breakpoints.up('sm')]: {
        minHeight: 62,
        padding: `0 ${px(largePadding)}`,
      },
    }),
    outlined: {
      borderWidth: mobileButtonBorderWidth,
      [breakpoints.up('sm')]: {
        borderWidth: buttonBorderWidth,
      },
      '&:hover': {
        borderWidth: mobileButtonBorderWidth,
        [breakpoints.up('sm')]: {
          borderWidth: buttonBorderWidth,
        },
      },
    },
    root: {
      borderRadius: 40,
      justifyContent: 'space-between',
      fontWeight: 700,
      textTransform: 'none',
      ':focus:not(:focus-visible)': {
        outline: 0,
      },
    },
  },
};

const light: Components<Theme>['MuiButton'] = {
  styleOverrides: {
    containedSuccess: {
      '&': {
        backgroundColor: colors.christi,
        color: common.white,
      },
      '&:hover': {
        backgroundColor: colors.limeade,
        // Reset on touch devices, it doesn't add specificity
        [mediaQueryMode.touchDevices]: {
          backgroundColor: colors.christi,
        },
      },
      '&:active': {
        backgroundColor: colors.japaneseLaurel,
        color: common.white,
        borderColor: 'transparent',
      },
      '&:disabled': {
        backgroundColor: colors.caper,
        border: `${buttonBorderWidthPx} solid ${colors.christi36}`,
        color: colors.mantis,
      },
    },
    outlinedPrimary: {
      '&': {
        backgroundColor: common.white,
        borderColor: colors.tundora,
      },
      '&:hover': {
        backgroundColor: colors.tundora,
        color: common.white,
        // Reset on touch devices, it doesn't add specificity
        [mediaQueryMode.touchDevices]: {
          backgroundColor: common.white,
          color: lightPalette.primary.main,
        },
      },
      '&:active': {
        backgroundColor: common.black,
        color: common.white,
        borderColor: 'transparent',
      },
      '&:disabled': {
        backgroundColor: colors.gallery,
        border: `${buttonBorderWidthPx} solid ${colors.alto}`,
        color: colors.boulder,
      },
    },
    outlinedSecondary: {
      '&': {
        backgroundColor: common.white,
        borderColor: colors.alto,
        border: `${buttonBorderWidthPx} solid ${colors.tundora}`,
      },
      '&:hover': {
        backgroundColor: colors.tundora,
        color: common.white,
        // Reset on touch devices, it doesn't add specificity
        [mediaQueryMode.touchDevices]: {
          backgroundColor: common.white,
          color: lightPalette.secondary.main,
          borderColor: colors.alto,
        },
      },
      '&:active': {
        backgroundColor: common.black,
        color: common.white,
        borderColor: 'transparent',
      },
      '&:disabled': {
        backgroundColor: colors.gallery,
        border: `${buttonBorderWidthPx} solid ${colors.alto}`,
        color: colors.boulder,
      },
    },
    root: {
      '&:focus': {
        outline: `${buttonOutlineWidthPx} solid ${focusBorderColor.light}`,
      },
    },
  },
};

const highContrastAccessible: Components<Theme>['MuiButton'] = {
  styleOverrides: {
    outlinedPrimary: {
      '&.MuiButton-outlinedPrimary': {
        color: colors.candyCorn,
        borderColor: colors.candyCorn,

        '&:focus': {
          outlineOffset: buttonOutlineOffsetPx,
          outline: `${buttonOutlineWidthWithOffsetPx} solid ${colors.candyCorn}`,
        },

        '&:hover': {
          borderColor: colors.white,
          outlineOffset: buttonOutlineOffsetPx,
          outline: `${buttonOutlineWidthWithOffsetPx} solid ${colors.white}`,
        },

        '&:active': {
          borderColor: colors.malibu,
          outline: `${buttonOutlineWidthWithOffsetPx} solid ${colors.malibu}`,
        },

        '&:disabled': {
          background: buttonStrikeoutHighContrast(colors.black, colors.white),
          borderColor: colors.white,
          borderWidth: buttonBorderWidthPx,
          color: colors.white,
        },
      },
    },

    outlinedSecondary: {
      '&.MuiButton-outlinedSecondary': {
        color: colors.candyCorn,
        border: `${buttonBorderWidthPx} dashed ${colors.candyCorn}`,

        '&:focus': {
          outlineOffset: buttonOutlineOffsetPx,
          outline: `${buttonOutlineWidthWithOffsetPx} solid ${colors.candyCorn}`,
        },

        '&:hover': {
          borderColor: colors.white,
          borderStyle: 'solid',
          outlineOffset: buttonOutlineOffsetPx,
          outline: `${buttonOutlineWidthWithOffsetPx} solid ${colors.white}`,
        },

        '&:active': {
          borderColor: colors.malibu,
          borderStyle: 'dashed',
          outline: 'none',
        },

        '&:disabled': {
          background: buttonStrikeoutHighContrast(colors.black, colors.white),
          borderColor: colors.white,
          borderWidth: buttonBorderWidthPx,
          color: colors.white,
        },
      },
    },
  },
};

export const MuiButtonComp = {
  base,
  light,
  highContrastAccessible,
};
