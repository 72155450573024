import { ProjectSubmit } from 'model/ProjectSubmit';
import { Resource } from 'model/Resource';

export namespace SubmitState {
  export const INITIAL_SUBMIT_RESOURCE: Resource<ProjectSubmit> = Resource.void();
  export const INITIAL: SubmitState = {
    submitResource: INITIAL_SUBMIT_RESOURCE,
  };
}

export type SubmitState = {
  submitResource?: Resource<ProjectSubmit>;
};
