import { useMemo } from 'react';
import { useLocation } from 'react-router';

import { RouteQueryResult } from 'shared/models/RouteQueryResult';

export const useRouteQuery = (): RouteQueryResult => {
  const { search } = useLocation();

  return useMemo(() => {
    const params = new URLSearchParams(search);
    return Object.fromEntries(params);
  }, [search]);
};
