import React from 'react';
import { useSelector } from 'react-redux';

import { useLanguage } from 'translations/LanguageProvider';
import { useSetChangeLanguageCallback } from 'shared/hooks/useSetChangeLanguageCallback';
import { selectError, selectIsMultiLanguageEnabled, selectMeta } from 'selector/metaSelector';
import { useSurveyForm } from 'component/ProtectedSurveyFormProvider/SurveyFormProvider';
import { LangCodeEnum } from 'shared/enums/LangCodeEnum';
import { useLanguageDrawContext } from 'context/LanguageDrawContext';
import { sortLanguageCodes } from 'shared/utils/sortLanguageCodes';

import { LanguageDrawer } from './LanguageDrawer';

export const LanguageDrawerContainer: React.VFC = () => {
  // TODO: Handle error case for this component
  const meta = useSelector(selectMeta);
  const error = useSelector(selectError);
  const formResult = useSurveyForm();
  const { changeLanguage, language } = useLanguage();
  const { toggle, value } = useLanguageDrawContext();
  const isMultiLanguageEnabled = useSelector(selectIsMultiLanguageEnabled);

  useSetChangeLanguageCallback();

  const changeSelection = (language: LangCodeEnum) => {
    changeLanguage(language).then(async () => await formResult?.clearErrors?.());
  };

  if (!isMultiLanguageEnabled || (!meta && !error)) {
    return null;
  }

  return (
    <LanguageDrawer
      currentLanguage={language}
      languages={sortLanguageCodes(meta.getEnabledLocales())}
      onSelection={changeSelection}
      open={value}
      onOpen={toggle}
      onClose={toggle}
    />
  );
};
