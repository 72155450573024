import { Map } from 'immutable';
import { SimpleModel } from 'model/SimpleModel';

import { Logic } from './Logic';
import { LogicType } from './LogicType';
import { NumberingSkippable } from './NumberingSkippable';
import { PageItemKind } from './PageItemKind';

export namespace PageItem {
  export interface Shape {
    id?: number;
    position?: number;
    kind?: PageItemKind;
    logic?: Logic;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: unknown;
  }
}

export abstract class PageItem<S extends PageItem.Shape = PageItem.Shape>
  extends SimpleModel<S>
  implements NumberingSkippable {
  static readonly QUESTION_KINDS: PageItemKind[] = [
    PageItemKind.QuestionOpinion,
    PageItemKind.QuestionRadioButton,
    PageItemKind.QuestionCheckbox,
    PageItemKind.QuestionCAS,
    PageItemKind.QuestionCommentsBox,
    PageItemKind.QuestionForm,
    PageItemKind.QuestionDemographic,
  ];

  static readonly KINDS: PageItemKind[] = [
    ...PageItem.QUESTION_KINDS,
    PageItemKind.TextWidget,
    PageItemKind.ImageWidget,
    PageItemKind.HeaderWidget,
  ];

  static readonly LOGICS: LogicType[] = [
    LogicType.Dependency,
    LogicType.Classification,
    LogicType.Distribution,
  ];

  constructor(initialData: SimpleModel.Data<S>, kind?: PageItemKind) {
    const map = Map.isMap(initialData) ? (initialData as Map<string, unknown>) : Map(initialData);
    super(kind ? map.set('kind', kind) : map);
  }

  getId(): number {
    return this.get('id');
  }

  hasLogic(): boolean {
    return !!this.get('logic');
  }

  setId(id: number): this {
    return this.set('id', id);
  }

  getPosition(): number {
    return this.get('position');
  }

  setPosition(position: number): this {
    return this.set('position', position);
  }

  getKind(): PageItemKind {
    return this.get('kind');
  }

  getGeneralKind(): PageItemKind.General {
    return PageItemKind.getGeneralKind(this.getKind());
  }

  getLogic(): Logic {
    return this.get('logic');
  }

  isNumberingSkipped(): boolean {
    return false;
  }

  copy(): this {
    return this.set('id', undefined).set('logic', undefined);
  }
}
