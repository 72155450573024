import React from 'react';

import {
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
  DialogContent,
  DialogTitle,
  Icon,
  Typography,
} from '@mui/material';

import { IconButton, dialogHighContrastStyles } from './Dialog.s';

type CloseReason = 'backdropClick' | 'escapeKeyDown' | 'closeButtonClick';

export type DialogProps = {
  children: JSX.Element | JSX.Element[];
  onClose: (reason: CloseReason) => void;
  title: string;
} & MuiDialogProps;

export const Dialog: React.VFC<DialogProps> = ({ children, onClose, title, ...props }) => {
  return (
    <MuiDialog sx={dialogHighContrastStyles} onClose={(_, reason) => onClose(reason)} {...props}>
      <DialogTitle>
        <Typography variant="h5">{title}</Typography>
        <IconButton aria-label="close" onClick={() => onClose('closeButtonClick')}>
          <Icon>close</Icon>
        </IconButton>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </MuiDialog>
  );
};
