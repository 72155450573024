/* eslint-disable @typescript-eslint/no-explicit-any */
import { Resource } from 'model/Resource';
import { Reducer } from 'redux';
import { Action } from 'redux-actions';

import { createAsyncModelReducer } from './asyncModelReducer';

/**
 * Create resource reducer for fetch async action
 */
export function createResourceFetchReducer<T extends Resource<any, any>, P>(
  mapContent?: (payload: P) => T,
  mapError?: (error: unknown) => unknown,
  initialResource?: T
): Reducer<T, Action<P>> {
  return createAsyncModelReducer<T, P>(Resource.FETCH, mapContent, mapError, initialResource);
}
