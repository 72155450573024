/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';

import { Resource } from 'model/Resource';

export namespace ResourceLoader {
  export type OwnProps<C> = {
    resource: Resource<C> | Resource<any>[];
    // if true, render content even if it's in pending state
    optimistic?: boolean;
    // if true, render content even if there is an error
    silent?: boolean;
    content: (content?: C | any[], pending?: boolean, error?: any | any[]) => JSX.Element;
    loader?: () => JSX.Element;
    error?: (error?: any | any[]) => JSX.Element;
    void?: JSX.Element;
  };

  export type StateProps = {
    locale?: string;
  };

  export type Props<C> = StateProps & OwnProps<C>;
}

export class ResourceLoader extends React.Component<ResourceLoader.Props<any>> {
  render(): JSX.Element {
    if (this.props.children) {
      console.warn(`Use 'content' props instead of children`);
    }

    switch (Resource.combine(...this.getResources()).getStatus()) {
      case Resource.RESOLVED:
        return this.renderContent();

      case Resource.VOID:
        return this.renderVoid() || this.renderLoader();

      case Resource.PENDING:
        if (
          this.props.optimistic &&
          this.props.content &&
          this.getResources().every((resource) => resource.hasContent())
        ) {
          return this.renderContent() || this.renderLoader();
        }
        return this.renderLoader();

      case Resource.REJECTED:
        return this.props.silent &&
          this.props.content &&
          this.getResources().every((resource) => resource.hasContent())
          ? this.renderContent() || this.renderError()
          : this.renderError();
    }
  }

  private renderContent(): JSX.Element {
    const content = this.props.content
      ? this.props.content(
          this.props.optimistic
            ? this.getFromResources((resource) => resource.getOptimisticContent())
            : this.getFromResources((resource) => resource.getContent()),
          Resource.combine(...this.getResources()).isPending(),
          this.getFromResources((resource) => resource.getError())
        )
      : null;

    return content ? React.Children.only(content) : null;
  }

  private renderVoid(): JSX.Element {
    return this.props.void ? React.Children.only(this.props.void) : null;
  }

  private renderLoader(): JSX.Element {
    const loader = this.props.loader ? this.props.loader() : <></>;

    return loader ? React.Children.only(loader) : null;
  }

  private renderError(): JSX.Element {
    const error = this.props.error ? (
      this.props.error(this.getFromResources((resource) => resource.getError()))
    ) : (
      <span>Cannot load resource.</span>
    );

    return error ? React.Children.only(error) : null;
  }

  private getResources(): Resource<any>[] {
    return Array.isArray(this.props.resource) ? this.props.resource : [this.props.resource];
  }

  private getFromResources(getter: (resource: Resource<any>) => any): any {
    return Array.isArray(this.props.resource)
      ? this.props.resource.map((resource) => getter(resource))
      : getter(this.props.resource);
  }
}
