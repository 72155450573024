import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { colors } from 'app/theme/config';

export const rootStyles: SxProps<Theme> = (theme) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(3.25, 1, 3.75),
  borderTop: `2px ${colors.alto} solid`,
  backgroundColor: colors.athensGray,
});

export const rootHighContrastOverrideStyles: SxProps<Theme> = (theme) => ({
  borderColor: colors.white,
  backgroundColor: theme.palette.background.default,
});

export const textStyles: SxProps<Theme> = (theme) => ({
  marginTop: theme.spacing(0.65),
});

export const mobileLogoSx: SxProps = {
  maxWidth: '120px',
  maxHeight: '40px',
  width: 'auto',
  height: 'auto',
};
