import { Moment } from 'moment';

export namespace AvailableSurvey {
  export type Shape = {
    name: string;
    distributionId: string;
    startDate: Moment | null;
  };

  export type Instance = AvailableSurvey.Shape & {
    // place for methods
    getDisplayName: () => string;
  };
}

export const createAvailableSurveyInstance = (
  shape: AvailableSurvey.Shape
): AvailableSurvey.Instance => {
  const formattedStartDate = shape.startDate ? `(${shape.startDate.format('DD MMM YY')})` : '';
  return {
    ...shape,
    getDisplayName: () => `${shape.name} ${formattedStartDate}`.trim(),
    // place for methods
  };
};
