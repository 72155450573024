import { List } from 'immutable';
import { SimpleModel } from 'model/SimpleModel';
import { Moment } from 'moment';

import { Answer } from './Answer';
import { ProjectSubmitStatus } from './ProjectSubmitStatus';

export namespace ProjectSubmit {
  export interface Shape {
    id: number;
    status: ProjectSubmitStatus;
    submitTime?: Moment;
    email?: string;
    firstName?: string;
    lastName?: string;
    answers?: List<Answer>;
  }
}

export class ProjectSubmit extends SimpleModel<ProjectSubmit.Shape> {
  getId(): number {
    return this.get('id');
  }

  getFirstName(): string {
    return this.get('firstName', '');
  }

  getLastName(): string {
    return this.get('lastName', '');
  }

  getName(): string {
    return this.getFirstName() ? `${this.getFirstName()} ${this.getLastName()}` : '';
  }

  getAnswers(): List<Answer> {
    return this.get('answers');
  }
}
