import React, { useEffect, useState } from 'react';

import { DisabledFade } from './FadeChildren.s';

type FadeChildrenProps = {
  displayTime: number;
  fadeInTime: number;
  fadeOutTime: number;
  children: React.ReactNode | React.ReactNode[];
};

export const FadeChildren: React.VFC<FadeChildrenProps> = ({
  displayTime,
  fadeInTime,
  fadeOutTime,
  children,
}) => {
  const [currentChild, setCurrentChild] = useState(0);
  const [showChild, setShowChild] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowChild(false);
    }, displayTime);

    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentChild]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowChild(true);
      setCurrentChild((currentChild + 1) % React.Children.count(children));
    }, displayTime + (fadeInTime + fadeOutTime) / 2);

    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentChild, children]);

  const renderChild = (child: React.ReactElement, index: number) => {
    const isCurrentChild = index === currentChild;

    return (
      <DisabledFade
        in={isCurrentChild && showChild}
        timeout={{ enter: fadeInTime, exit: fadeOutTime }}
        className={!isCurrentChild ? 'disable' : ''}
      >
        {child}
      </DisabledFade>
    );
  };

  return <>{React.Children.map(children, renderChild)}</>;
};
