import { SimpleModel } from 'model/SimpleModel';

import { NumberingSkippable } from './NumberingSkippable';
import { PageItemKind } from './PageItemKind';
import { Question } from './Question';

export namespace QuestionCommentBox {
  export interface Shape extends Question.Shape {
    textBoxWidth: number;
    textBoxHeight: number;
    reportedAs: string;
    isRequired?: boolean;
    numberingSkipped?: boolean;
  }
}

export class QuestionCommentBox
  extends Question<QuestionCommentBox.Shape>
  implements NumberingSkippable {
  constructor(initialData: SimpleModel.Data<QuestionCommentBox.Shape>) {
    super(initialData, PageItemKind.QuestionCommentsBox);
  }

  isRequired(): boolean {
    return this.get('isRequired');
  }

  getTextBoxWidth(): number {
    return this.get('textBoxWidth');
  }

  getTextBoxHeight(): number {
    return this.get('textBoxHeight');
  }

  getReportedAs(): string {
    return this.get('reportedAs');
  }

  isReportedInSync(): boolean {
    return this.getReportedAs() === this.getContent();
  }

  isNumberingSkipped(): boolean {
    return this.get('numberingSkipped');
  }
}
