import { PageItemApi } from 'api/PageItemApi';
import { StaticResourceApi } from 'api/StaticResourceApi';
import { PageItemAbstractMapper } from 'mapper/PageItemAbstractMapper';
import { ImageWidget } from 'model/ImageWidget';
import { PageItemKind } from 'model/PageItemKind';

import { PageItemKindMapper } from './PageItemMapper';
import { StaticResourceMapper } from './StaticResourceMapper';

export class ImageWidgetMapper
  implements PageItemKindMapper<ImageWidget, PageItemApi.ImageWidgetEntry> {
  private staticResourceMapper: StaticResourceMapper;

  constructor(staticResourceMapper: StaticResourceMapper) {
    this.staticResourceMapper = staticResourceMapper;
  }

  deserialize(entry: PageItemApi.ImageWidgetEntry): ImageWidget {
    return new ImageWidget({
      ...PageItemAbstractMapper.deserializeShape(entry),
      id: entry.id,
      position: entry.position,
      image: entry.image
        ? this.staticResourceMapper.deserialize(entry.image as StaticResourceApi.Entry)
        : undefined,
      width: entry.width,
      height: entry.height,
      align: entry.align,
    });
  }

  supportsDeserialize(entry: PageItemApi.HeaderWidgetEntry): boolean {
    return entry.kind === PageItemKind.ImageWidget;
  }
}
