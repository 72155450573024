import { LangCodeEnum } from 'shared/enums/LangCodeEnum';
import { i18n } from 'translations/i18n';

export const sortLanguageCodes = (languages: LangCodeEnum[]): LangCodeEnum[] => {
  return languages
    .map((language) => ({
      code: language,
      title: i18n.t('language.englishName', { lng: language }),
    }))
    .sort((a, b) => a.title.localeCompare(b.title))
    .map((languageCode) => languageCode.code);
};
