import { PageItemApi } from 'api/PageItemApi';
import { PageItem } from 'model/PageItem';

import { LogicClassificationMapper } from './LogicClassificationMapper';
import { LogicDependencyMapper } from './LogicDependencyMapper';
import { LogicDistributionMapper } from './LogicDistributionMapper';
import { LogicMapper } from './LogicMapper';

export abstract class PageItemAbstractMapper {
  static deserializeShape(entry: PageItemApi.ItemEntry): PageItem.Shape {
    // we can't deserialize question model, because it's an abstract model

    const model: PageItem.Shape = {
      id: entry.id,
      position: entry.position,
      kind: entry.kind,
    };

    if (entry.logic) {
      const logicMapper = new LogicMapper([
        new LogicDependencyMapper(),
        new LogicDistributionMapper(),
        new LogicClassificationMapper(),
      ]);

      model.logic = logicMapper.deserialize(entry.logic);
    }

    return model;
  }
}
