import { List } from 'immutable';
import { SimpleModel } from 'model/SimpleModel';

import { DiscreteQuestion } from './DiscreteQuestion';
import { PageItem } from './PageItem';
import { PageItemKind } from './PageItemKind';
import { Question } from './Question';

export namespace Page {
  export interface Shape {
    project?: number;
    id?: number;
    position?: number;
    items?: List<PageItem>;
  }
}

export class Page extends SimpleModel<Page.Shape> {
  getProject(): number {
    return this.get('project');
  }

  getId(): number {
    return this.get('id');
  }

  getPosition(): number {
    return this.get('position');
  }

  getItems(): List<PageItem> {
    return this.get('items', List<PageItem>())
      .sortBy((item) => item.getPosition())
      .toList();
  }

  isEmpty(): boolean {
    return this.getItems().isEmpty();
  }

  getQuestions(): List<Question> {
    return this.getItems()
      .filter((item) => item instanceof Question)
      .toList() as List<Question>;
  }

  getDiscreteQuestions(): List<Question & DiscreteQuestion> {
    return this.getQuestions()
      .filter(
        (question) =>
          question.getKind() !== PageItemKind.QuestionCommentsBox &&
          question.getKind() !== PageItemKind.QuestionForm
      )
      .toList() as List<Question & DiscreteQuestion>;
  }
}
