import { useState } from 'react';

import { Theme } from '@mui/material/styles';
import { lightTheme } from 'app/theme/light';
import { highContrastAccessibleTheme } from 'app/theme/highContrastAccessible';
import { ThemeContext } from 'context/ThemeContext';
import { getLanguageDirection } from 'shared/utils/getLanguageDirection';
import { useLanguage } from 'translations/LanguageProvider';
import { ThemeModeEnum } from 'shared/enums/ThemeModeEnum';

import { useThemeModeCookie } from './useAccessibilityModeCookie';

const getThemeByMode = (themeMode: ThemeModeEnum): Theme => {
  switch (themeMode) {
    case ThemeModeEnum.LIGHT:
      return lightTheme;
    case ThemeModeEnum.HIGH_CONTRAST_ACCESSIBLE:
      return highContrastAccessibleTheme;

    default:
      return lightTheme;
  }
};

export const useThemeContext = (initialThemeMode: ThemeModeEnum): ThemeContext => {
  const [themeMode, setThemeMode] = useState<ThemeModeEnum>(initialThemeMode);
  const { setThemeModeCookie } = useThemeModeCookie();
  const { language } = useLanguage();

  const setThemeByMode = (themeMode: ThemeModeEnum) => {
    setThemeMode(themeMode);
  };

  const toggleTheme = () => {
    setThemeMode((prevThemeMode) => {
      const newThemeMode =
        prevThemeMode === ThemeModeEnum.LIGHT
          ? ThemeModeEnum.HIGH_CONTRAST_ACCESSIBLE
          : ThemeModeEnum.LIGHT;

      setThemeModeCookie(newThemeMode);

      window.dataLayer?.push({
        event: 'accessibilityModeChanged',
        activeAccessibilityMode: newThemeMode,
      });

      return newThemeMode;
    });
  };

  return {
    themeMode,
    theme: { ...getThemeByMode(themeMode), direction: getLanguageDirection(language) },
    setThemeByMode: setThemeByMode,
    toggleTheme,
  };
};
