import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { SaveAndContinueContext } from 'context/SaveAndContinueContext';
import { LangNamespaceEnum } from 'shared/enums/LangNamespaceEnum';
import { Toggle } from 'component/Toggles/Toggle';
import { Icon } from 'component/Toggles/Toggle.s';

type SaveAndContinueToggleProps = {
  'data-testid'?: string;
};

export const SaveAndContinueToggle: React.VFC<SaveAndContinueToggleProps> = ({
  'data-testid': dataTestId,
}) => {
  const { t } = useTranslation(LangNamespaceEnum.SURVEY_UI_COMPONENTS);
  const { toggle } = useContext(SaveAndContinueContext);

  return (
    <Toggle
      data-testid={dataTestId}
      title={t('SaveAndContinueToggle.title')}
      icon={<Icon>save</Icon>}
      onClick={toggle}
    />
  );
};
