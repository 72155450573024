import { Resource } from 'model/Resource';
import { createSelector, Selector } from 'reselect';
import { SurveyState } from 'store/SurveyState';
import { Meta } from 'model/Meta';
import {
  selectIsEmailDistribution,
  selectIsKioskDistribution,
  selectIsWebLinkDistribution,
} from 'selector/distributionSelector';
import { isSaveAndContinueAvailableForProjectKind } from 'selector/surveySelector';

import { createResourceContentSelector } from './resourceSelector';

export const selectMetaResource: Selector<SurveyState, Resource<Meta>> = (state) =>
  state?.metaResource || Resource.void<Meta>();

export const selectMeta = createResourceContentSelector(selectMetaResource);

export const selectError = (state: SurveyState) => state?.error;

export const selectIsMultiLanguageEnabled = createSelector(selectMeta, (meta) =>
  meta?.getMultiLanguageEnabled()
);

export const selectHasProgressBar = createSelector(selectMeta, (meta) =>
  Boolean(meta?.hasProgressBar())
);

export const selectSurveyLogoUrl = createSelector(selectMeta, (meta) => meta?.getSurveyLogoUrl());

export const selectHasAutomaticNumbering = createSelector(selectMeta, (meta) =>
  Boolean(meta?.hasAutomaticNumbering())
);

export const selectHasSaveAndContinue = createSelector(
  selectMeta,
  selectIsEmailDistribution,
  selectIsWebLinkDistribution,
  selectIsKioskDistribution,
  isSaveAndContinueAvailableForProjectKind,
  (
    meta,
    isEmailDistribution,
    isWebLinkDistribution,
    isKioskDistribution,
    isSaveAndContinueAvailableForProjectKind
  ) => {
    return (
      meta?.hasSaveAndContinue() &&
      (isEmailDistribution || isWebLinkDistribution || isKioskDistribution) &&
      isSaveAndContinueAvailableForProjectKind
    );
  }
);

export const selectAlmostFinishedMessage = createSelector(
  selectMeta,
  (meta) =>
    meta?.getAlmostFinishedContent() ||
    `You have now reached the end of the questions.
Once you press ‘Complete Survey’, your answers will be sent to WorkBuzz and you will not be able to change these.`
);

export const selectSaveAndContinueMessage = createSelector(
  selectMeta,
  (meta) =>
    meta?.getSaveAndContinueContent() ||
    `Please supply your e-mail address to save your progress. 
A unique link will be emailed to you that will allow you to continue from the last question answered. 
If you have not received the email please check your spam or junk mail folder.`
);

export const selectThankYouMessage = createSelector(
  selectMeta,
  (meta) =>
    meta?.getThankYouPageContent() ||
    'Thank you for taking the time to complete the survey. Your time and thoughts are greatly appreciated.'
);

export const selectSurveyClosedMessage = createSelector(
  selectMeta,
  (meta) =>
    meta?.getClosedPageContent() ||
    'The survey you are trying to access is closed. Please contact your HR leader if you feel this is an error.'
);
