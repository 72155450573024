import React from 'react';
import { useSelector } from 'react-redux';

import { selectProjectKind } from 'selector/surveySelector';
import { AnonymousLink } from 'component/Footer/AnonymousLink/AnonymousLink';
import { isAnonymous } from 'shared/utils/isAnonymous/isAnonymous';
import { DesktopPoweredByLogo } from 'component/Footer/DesktopPoweredByLogo/DesktopPoweredByLogo';
import { Box } from '@mui/material';

import { FooterContainer, FixedBottom } from './Footer.s';
import { MobilePoweredByLogo } from './MobilePoweredByLogo/MobilePoweredByLogo';

type FooterPureProps = {
  isAnonymous?: boolean;
};

export const FooterPure: React.VFC<FooterPureProps> = ({ isAnonymous }) => {
  return (
    <FooterContainer data-testid="footer">
      <Box sx={{ display: { lg: 'none' }, paddingTop: 3.5 }}>
        {isAnonymous && (
          <Box mb="30px" sx={{ display: { xs: 'block', lg: 'none' } }}>
            <AnonymousLink variant="stacked" />
          </Box>
        )}
        <MobilePoweredByLogo />
      </Box>
      <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
        {isAnonymous && (
          <FixedBottom left={30}>
            <AnonymousLink />
          </FixedBottom>
        )}
        <FixedBottom right={30}>
          <DesktopPoweredByLogo />
        </FixedBottom>
      </Box>
    </FooterContainer>
  );
};

export const Footer: React.FC = () => {
  const projectKind = useSelector(selectProjectKind);

  return <FooterPure isAnonymous={isAnonymous(projectKind)} />;
};
