import React, { Suspense as ReactSuspense } from 'react';

import { getDisplayName } from 'shared/utils/getDisplayName';

type SuspenseProps = {
  children: React.ReactNode;
};

export const Suspense: React.VFC<SuspenseProps> = ({ children }) => {
  return <ReactSuspense fallback={null}>{children}</ReactSuspense>;
};

export const withSuspense = <P extends any>(WrappedComponent: React.ComponentType<P>) => {
  const Component: React.VFC<P> = (props) => {
    return (
      <Suspense>
        <WrappedComponent {...props} />
      </Suspense>
    );
  };

  Component.displayName = `withSuspense(${getDisplayName(WrappedComponent)})`;
  return Component;
};
