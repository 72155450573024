import { styled } from '@mui/material';
import { colors } from 'app/theme/config';
import { keyframes, SxProps } from '@mui/system';

export type OwnerState = {
  isSubmitting: boolean;
};

export const submitButtonSx = (ownerState: OwnerState): SxProps => ({
  position: 'relative',
  overflow: 'hidden',
  '&:disabled': {
    backgroundColor: 'inherit',
  },
  transition: ownerState.isSubmitting ? 'initial' : undefined,
});

export const AnimationBox = styled('div')(() => {
  const loadingFrame = keyframes`
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(100%);
    }
  `;

  return {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: colors.alto,
    left: 0,
    top: 0,
    animationName: loadingFrame.toString(),
    animationDuration: '0.8s',
    animationDelay: '0.1',
    animationTimingFunction: 'ease-out',
    animationIterationCount: 'infinite',
  };
});

export const ButtonContent = styled('span')(() => ({
  zIndex: 1,
}));
