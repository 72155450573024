import { ListItem, ListItemButton, styled } from '@mui/material';
import { colors } from 'app/theme/config';
import { ThemeModeEnum } from 'shared/enums/ThemeModeEnum';

export const ListItemStyled = styled(ListItem)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

const highContrastListItemHoverStyles = {
  background: colors.white,

  p: {
    color: colors.black,
  },

  '.language-flag': {
    borderColor: colors.black,
  },
};

const highContrastListItemStyles = {
  p: {
    color: colors.candyCorn,
  },

  '.language-flag': {
    borderColor: colors.candyCorn,
  },

  '&.Mui-selected': {
    background: 'none',

    p: {
      color: colors.white,
    },

    '.language-flag': {
      borderColor: colors.white,
    },

    '&:hover': highContrastListItemHoverStyles,
  },

  '&:hover': highContrastListItemHoverStyles,
};

export const ListItemButtonStyled = styled(ListItemButton)(({ theme }) => ({
  padding: theme.spacing(1, 3),
  '&.Mui-selected': {
    backgroundColor: 'transparent',
  },

  ...(theme.themeName === ThemeModeEnum.HIGH_CONTRAST_ACCESSIBLE ? highContrastListItemStyles : {}),
}));
