import {
  SNACKBAR_CLOSED,
  SNACKBAR_OPENED,
  SNACKBAR_REGISTERED,
  SNACKBAR_UNREGISTERED,
  SnackbarClosedAction,
  SnackbarOpenedAction,
  SnackbarRegisteredAction,
  SnackbarUnregisteredAction,
} from 'action/snackbarAction';
import { combineReducers } from 'reducer/combineReducers';
import { reduceReducers } from 'reducer/reduceReducers';
import { Reducer } from 'redux';
import { handleAction } from 'redux-actions';
import { SnackbarAwareState, SnackbarState } from 'store/SnackbarState';

export const snackbarRegisteredReducer = handleAction(
  SNACKBAR_REGISTERED,
  (snackbars, action: SnackbarRegisteredAction) => [...snackbars, action.payload],
  SnackbarState.INITIAL_DOMAIN
);

export const snackbarOpenedReducer = handleAction(
  SNACKBAR_OPENED,
  (snackbars, action: SnackbarOpenedAction) =>
    snackbars.map((snackbar) =>
      snackbar.id === action.payload ? { ...snackbar, isOpen: true } : snackbar
    ),
  SnackbarState.INITIAL_DOMAIN
);

export const snackbarClosedReducer = handleAction(
  SNACKBAR_CLOSED,
  (snackbars, action: SnackbarClosedAction) =>
    snackbars.map((snackbar) =>
      snackbar.id === action.payload ? { ...snackbar, isOpen: false } : snackbar
    ),
  SnackbarState.INITIAL_DOMAIN
);

export const snackbarUnregisteredReducer = handleAction(
  SNACKBAR_UNREGISTERED,
  (snackbars, action: SnackbarUnregisteredAction) =>
    snackbars.filter((snackbar) => snackbar.id !== action.payload),
  SnackbarState.INITIAL_DOMAIN
);

export const snackbarReducer: Reducer<SnackbarAwareState> = combineReducers<SnackbarAwareState>({
  snackbars: reduceReducers(
    snackbarRegisteredReducer,
    snackbarOpenedReducer,
    snackbarClosedReducer,
    snackbarUnregisteredReducer
  ),
});
